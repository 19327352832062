<template>
  <div class="register-page dzs-register">
    <div class="full-page login-page section-image sb-login-page">
      <div class="content">
        <div class="container row">
          <div class="col-lg-6 content-wrapper">
            <div>
              <h2 class="content__headline">Seja <br> bem-vindo(a) <br> à SuitPay!</h2>
              <p class="content__text">Você está a alguns passos de fazer parte <br> do <b>maior ecossistema
                financeiro</b> para <br> negócios digitais do Brasil <3</p>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="card-register">
              <div class="card-register__header">
                <img src="/static/img/login/SuitPayVerde.svg"/>
              </div>
              <form @submit.prevent="submit" class="div-register">
                <div v-show="showFirstFormPart">
                  <fg-input v-model="username"
                            v-validate="validations.username"
                            :error="getError('first.username')" name="username"
                            data-vv-scope="first"
                            placeholder="Nome de @usuário" data-vv-as="Nome de usuário"></fg-input>

                  <fg-input v-model="password"
                            v-validate="validations.password" name="password" ref="password"
                            data-vv-scope="first"
                            :error="getError('first.password')" type="password"
                            placeholder="Senha" data-vv-as="Senha"></fg-input>

                  <fg-input v-model="confirm"
                            v-validate="validations.confirm" name="confirm"
                            data-vv-scope="first"
                            :error="getError('first.confirm')" type="password"
                            placeholder="Confirme a Senha" data-vv-as="Confirme a Senha"></fg-input>

                  <fg-input v-model="email"
                            v-validate="validations.email" name="email"
                            data-vv-scope="first"
                            :error="getError('first.email')"
                            placeholder="E-mail" data-vv-as="E-mail"></fg-input>

                  <fg-input>
                    <el-select
                      v-model="countryCode"
                      data-vv-scope="first"
                      placeholder="Código do País (DDI)" data-vv-as="Código do País (DDI)"
                    >
                      <el-option v-for="item in countryCodeOptions" :key="item.id" :label="'+'+item.ddi + ' - ' + item.formal_name"
                                 :value="item.ddi"></el-option>
                    </el-select>
                  </fg-input>

                  <fg-input v-model="phone"
                            v-validate="countryCode === 55 ? validations.phone : ''" name="phone"
                            data-vv-scope="first"
                            :error="getError('first.phone')"
                            v-mask="countryCode === 55 ? ['(##) ####-####', '(##) #####-####'] : ['###########']"
                            placeholder="Celular" data-vv-as="Celular"></fg-input>

                  <fg-input v-model="document"
                            v-validate="validations.document" name="document"
                            data-vv-scope="first"
                            :error="getError('first.document')"
                            v-mask="['###.###.###-##']"
                            placeholder="CPF" data-vv-as="document"></fg-input>

                  <fg-input v-model="name"
                            v-validate="validations.name" name="name"
                            data-vv-scope="first"
                            :error="getError('first.name')"
                            placeholder="Nome completo" data-vv-as="name"></fg-input>
                </div>
                <div v-show="!showFirstFormPart">
                  <fg-input :error="getError('second.nicheMarket')">
                    <el-select
                      v-model="nicheMarket"
                      v-validate="validations.nicheMarket"
                      data-vv-scope="second"
                      name="nicheMarket"
                      placeholder="Mercado que Atua?"
                      data-vv-as="Mercado que Atua?"
                    >
                      <el-option v-for="item in nicheMarketOptions" :key="item.id" :label="item.value"
                                 :value="item.value"></el-option>
                    </el-select>
                  </fg-input>
                  <fg-input v-if="nicheMarket === 'Outros'" v-model="anotherNicheMarket"
                            v-validate="validations.anotherNicheMarket"
                            data-vv-scope="second"
                            :error="getError('second.anotherNicheMarket')" name="anotherNicheMarket"
                            placeholder="Outro" data-vv-as="Outro"></fg-input>
                  <fg-input v-model="websiteUrl"
                            v-validate="validations.websiteUrl"
                            data-vv-scope="second"
                            :error="getError('second.websiteUrl')" name="websiteUrl"
                            placeholder="URL" data-vv-as="URL"></fg-input>

                  <fg-input :error="getError('second.revenue')">
                    <el-select
                      v-model="revenue"
                      v-validate="validations.revenue"
                      data-vv-scope="second"
                      :error="getError('second.revenue')" name="revenue"
                      placeholder="Faturamento"
                    >
                      <el-option v-for="item in revenueOptions" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </fg-input>
                  <fg-input :error="getError('second.referralSource')">
                    <el-select
                      v-model="referralSource"
                      v-validate="validations.referralSource"
                      data-vv-scope="second"
                      name="referralSource"
                      placeholder="Como nos conheceu?" data-vv-as="Como nos conheceu?"
                    >
                      <el-option v-for="item in referralSourceOptions" :key="item" :label="item"
                                 :value="item"></el-option>
                    </el-select>
                  </fg-input>

                  <fg-input v-if="referralSource === 'Indicação'"
                            v-model="sourceContact"
                            v-validate="validations.sourceContact"
                            data-vv-scope="second"
                            :error="getError('second.sourceContact')" name="sourceContact"
                            placeholder="Quem nos indicou?" data-vv-as="Quem nos indicou?"></fg-input>
                  <br>
                  <div align="center" style="max-width: 85%">
                    <p-checkbox v-model="acceptTerms">
                      <span class="card-register__terms">
                        Eu aceito os <a href="https://web.suitpay.app/static/pdf/TERMOS_E_CONDICOES.pdf" target="_blank"
                                        class="card--highlight">termos e condições</a>, e a <br>
                      <a href="https://web.suitpay.app/static/pdf/POLITICA_DE_PRIVACIDADE_E_PROTECAO_DE_DADOS.pdf"
                         target="_blank" class="card--highlight">política de privacidade e proteção de dados</a>.
                      </span>
                    </p-checkbox>
                  </div>
                </div>
                <div class="card-register__buttons">
                  <p-button v-show="!showFirstFormPart" native-type="button"
                            class="card-register__button card-register__button--danger"
                            @click="showFirstFormPart = true">VOLTAR
                  </p-button>
                  <p-button
                    :disabled="!this.showSubmit"
                    native-type="submit"
                    class="card-register__button card-register__button--primary"
                    style="max-width: 100%;">{{ showFirstFormPart ? 'PRÓXIMO' : 'CADASTRAR' }}
                  </p-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" src="/static/img/login/carousel/background/CADASTRO.png"
             alt="">
      </div>
    </div>
  </div>
</template>
<script>
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import {Button, Card, InfoSection} from 'src/components/UIComponents'
import {Checkbox} from 'element-ui'
import {callWs, postWs, signup} from 'src/ws.service'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import swal from 'sweetalert2'
import PSwitch from 'src/components/UIComponents/Switch.vue'
import {sendEvent} from "@/googleAnalyticsApi";
import {isNullOrEmpty, isValidCpf} from "@/util/core.utils";
import countries from "@/components/Dashboard/Views/User/countries";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    VueReCaptcha,
    PSwitch,
  },
  data() {
    return {
      utmParameters: '',
      facebook:{
        fbc: this.getCookie("_fbc"),
        fbp: this.getCookie("_fbp"),
      },
      codeRecommendation: '',
      username: "",
      password: "",
      confirm: "",
      email: "",
      countryCode: "",
      phone: "",
      document:"",
      name: "",
      nicheMarket: "",
      nicheMarketOptions: [],
      anotherNicheMarket: "",
      websiteUrl: "",
      revenue: "",
      referralSource: "",
      sourceContact: "",
      acceptTerms: false,
      showSubmit: true,
      countryCodeOptions: this.countriesSorted(countries),
      validations: {
        username: {
          min: 3,
          required: true,
          regex: /^[a-z0-9]+(\.?[a-z0-9])+$/,
          ok: false
        },
        password: {
          required: true,
          min: 8,
          ok: false
        },
        confirm: {
          required: true,
          confirmed: 'password',
          ok: false
        },
        email: {
          required: true,
          min: 5,
          email: true,
          ok: false
        },
        phone: {
          required: true,
          min: 14,
          max: 15,
          ok: false
        },
        document:{
          required: true,
          cpfValid: true,
          min: 11,
          ok: false
        },
        name: {
          required: true,
          min: 3,
          max: 70,
          regex: /^([A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s+[A-Za-zÀ-ÖØ-öø-ÿ]+)+)$/,
          ok: false
        },
        nicheMarket: {
          required: true,
        },
        websiteUrl: {
          required: true,
          url: {require_protocol: true}
        },
        revenue: {
          required: true,
        },
        referralSource: {
          required: true,
        },
        anotherNicheMarket: {
          required: true,
        },
        sourceContact: {
          required: true,
        }
      },
      showFirstFormPart: true,
    }
  },
  computed: {
    revenueOptions: () => [
      "Sem faturamento",
      "Abaixo de 100 mil",
      "Entre 100 e 300 mil",
      "Entre 300 e 500 mil",
      "Entre 500 mil e 1 milhão",
      "Entre 1 e 5 milhões",
      "Mais de 5 milhões"
    ],
    referralSourceOptions: () => [
      "Facebook",
      "Instagram",
      "Google",
      "Youtube",
      "Indicação"
    ]
  },
  created() {
    this.$validator.extend('cpfValid', {
      getMessage: field => ('O ' + field + ' é inválido.'),
      validate: (value) => {
        if (isNullOrEmpty(value)) {
          return false
        }
        value = value.replace(/(\.)|(\/)|(-)/g, '')
        return isValidCpf(value)
      }
    })

    this.createData()
  },
  mounted() {
    this.recaptcha().then(this.getNicheMarketOptions)
  },

  methods: {
    async getNicheMarketOptions() {
      const getNicheToken = await this.$recaptcha('getNicheOptions')
      this.doGetNicheMarketOptions(getNicheToken)
    },
    doGetNicheMarketOptions(token) {
      callWs('/niche-market/all', 'GET', null, null,
        {
          recaptchaToken: token
        },
        null,
        (response) => {
          this.nicheMarketOptions = response.data
        },
        () => {
          swal({
            title: 'Aviso',
            text: 'Ocorreu um erro ao buscar os dados de Nichos. Atualize a página e tente novamente.',
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      )
    },
    createData() {
      this.codeRecommendation = this.$route.query.code
    },
    getError(fieldName) {

      if (this.errors.firstRule(fieldName) == "regex") {
        if(fieldName === 'first.name' ){
          return "Informe seu nome completo!"
        }
        return "Formato Inválido! Somente letras minusculas sem acentuação [a-z], numeros [0-9] e ponto [.] são permitidos."
      }
      return this.errors.first(fieldName)
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    submit() {
      if (this.showFirstFormPart) {
        this.$validator.validateAll('first').then(isValid => {
          if (isValid) {
            this.showFirstFormPart = false
          }
        })
        return
      }
      this.$validator.validateAll('second').then(isValid => {
        if (isValid) {
          if (!this.acceptTerms) {
            swal({
              title: "Aviso",
              text: 'Você deve aceitar os Termos e Condições antes de prosseguir.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          this.showSubmit = false
          this.doRecaptcha()
        }
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    async doRecaptcha() {
      const token = await this.$recaptcha('register')
      this.onCaptchaVerified(token)
    },
    onCaptchaVerified(recaptchaToken) {
      this.utmParameters = this.getUtmParameters()
      signup(this.username, this.password, this.email, recaptchaToken, this.successSignup, this.fail, this.codeRecommendation, this.phone, this.name, null, this.document, this.nicheMarket, this.anotherNicheMarket, this.websiteUrl, this.revenue, this.referralSource, this.sourceContact, this.utmParameters.utm_source, this.utmParameters.utm_term, this.utmParameters.utm_campaign, this.utmParameters.utm_content, this.utmParameters.utm_medium)
    },
    async sendPreRegisterPixelEvent() {
      try {
        postWs('/facebook/pixel/pre-cadastro',
          false,
          null,
          {
            name: this.name,
            email: this.email,
            phoneNumber: this.phone,
            fbc: this.facebook.fbc,
            fbp: this.facebook.fbp,
            username: this.username,
          }, function (response){
          }, function (error){
            console.log(error)
          })
      } catch (err) {
        console.error('Error:', err);
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    countriesSorted(countries) {
      countries = countries.sort((a, b) => (a.ddi > b.ddi) ? 1 : -1).filter(function (el) {
        return el.ddi != null;
      })
      countries.push(countries.shift())
      countries.unshift({
        number_iso: "BRA",
        formal_name: "República Federativa do Brasil",
        ddi: 55
      })
      return countries
    },
    getUtmParameters(){
      const utmSource = this.$route.query.utm_source;
      const utmMedium = this.$route.query.utm_medium;
      const utmCampaign = this.$route.query.utm_campaign;
      const utmTerm = this.$route.query.utm_term;
      const utmContent = this.$route.query.utm_content;

      return {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_content: utmContent
      };
    },
    successSignup(response) {
      if (response.data == 'OK') {
        swal({
          title: "Sucesso!",
          text: 'Foi enviado um e-mail de confirmação para você. Acesse seu e-mail e clique no botão \'Confirmar e-mail\'.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.sendPreRegisterPixelEvent()
        sendEvent("realizou_pre_cadastro", this.username, this.email, this.phone)
        this.$router.push('/login')
      } else {
        swal({
          title: "Ops...",
          text: response.data == 'EMAIL_USED' ? 'E-mail já cadastrado!' : 'Usuário em uso, favor escolher outro.',
          buttonsStyling: true,
          timer: 5000,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.showSubmit = true
      }
    },
    fail(error) {
      if (error.response.status == 400) {
        swal({
          title: 'Falha!',
          text: 'Recaptcha inválido.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      }else if (error.response.status === 304) {
        swal({
          title: 'Ops...',
          text: 'CPF já cadastrado!.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      } else {
        swal({
          title: 'Serviço temporariamente indisponível!',
          text: 'Tente novamente mais tarde.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      }

      this.showSubmit = true
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style lang="scss">
.dzs-register {
  min-height: 100vh;
  font-family: ArticulatCF, sans-serif;
  display: flex;
  align-items: center;

  .container {
    max-width: inherit;
  }

  .full-page-background {
    position: fixed !important;
  }

  .form-control,
  .input-group-text,
  .el-input.el-input--suffix > input {
    background-color: #FFFFFF !important;
    border: none !important;
  }

  .el-select {
    width: 100%;

    .el-input__inner:focus,
    .el-input.is-focus .el-input__inner {
      border: 1px solid #33CC33 !important;
    }

    input.el-input__inner::placeholder {
      color: #333333 !important;
      opacity: 1;
    }

    .el-input.el-input--suffix > input {
      background: #E2E2E2 !important;
    }

    .el-select-dropdown .el-popper {
      font-family: ArticulatCF, sans-serif;
    }
  }

  .btn-primary:focus, .btn-primary:active {
    background-color: #00d19a !important;
  }

  .content {
    padding: 0 !important;
    max-width: 120rem;
    margin: 0 auto;
  }

  .content-wrapper {
    padding: 0 6rem 4.2rem 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      animation: .8s in ease-in-out forwards;
    }
  }

  .content__headline {
    color: #FFFFFF;
    font-size: 3.6rem;
    font-weight: bold;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .content__text {
    color: #FFFFFF;
    font-size: 1.35rem;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .card-register {
    font-family: ArticulatCF, sans-serif;
    background: white;
    border-radius: 2rem !important;
    width: 100%;
    max-width: 31.5rem;
    padding: 0 9% 2.25rem 9% !important;

    input {
      background: #E2E2E2 !important;
      border-radius: 2rem;
      color: #333333 !important;
      padding: 1.2rem 1.5rem !important;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .card-register__header {
    height: 4rem;
    display: flex;
    justify-content: center;

    img {
      transform: translateY(-50%);
      margin: 0 auto 1.5rem;
      width: 5rem;
      height: 5rem;
    }
  }

  .card--highlight {
    color: #33CC33;
  }

  .card-register__terms {
    font-family: ArticulatCF, sans-serif;
  }

  .card-register__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }

  .card-register__button {
    text-transform: capitalize;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 2rem;
    width: fit-content;
    padding: .45rem 2.125rem;
  }

  .card-register__button--primary {
    background: #33CC33;
    color: #FFFFFF;
    font-weight: bold;
  }

  .card-register__button--primary:hover,
  .card-register__button--primary:active,
  .card-register__button--primary:focus {
    opacity: .90;
    background: #33CC33 !important;
  }

  .card-register__button--danger {
    background: #CC3333;
    color: #FFFFFF;
  }

  .card-register__button--danger:hover,
  .card-register__button--danger:active,
  .card-register__button--danger:focus {
    opacity: .90;
    background: #CC3333 !important;
  }

  @media (min-width: 1400px) {
    .col-lg-6.justify-content-center {
      justify-content: start !important;
    }
    .card-register {
      margin: 0% 10% 10% 10%;
    }
  }

  @media (min-width: 992px) {
    .card-register {
      float: right;
    }
  }

  @media (max-width: 991px) {
    .content {
      .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 !important;
      }
    }
    .content-wrapper, .content-selector {
      display: none;
    }
    .div-register {
      width: 100%;
    }
    .card-register {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .bg-wrapper {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }
}
</style>
