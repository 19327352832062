<template>
  <div class="dzs-financial-report">
    <div class="row">
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {issued}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{issued.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {paid}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{paid.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {expired}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{expired.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {waiting}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{waiting.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {canceled}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{canceled.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {chargeback}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{chargeback.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {paidPix}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{paidPix.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {paidBoleto}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{paidBoleto.footerText}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {paidCard}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Quantidade: {{paidCard.footerText}}
          </div>
        </stats-card>
      </div>

      <div class="col-md-12 card">
        <div class="row card">
          <div class="card-header">
            <h4 style="margin-top: 0px">Relatório de Vendas Link de Pagamento</h4>
          </div>
          <div class="card-body row" style="padding-left: 25px;">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-sm-2">
              <fg-input label="Exibir">
                <el-select
                  size="large"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [100,500,1000,5000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-2">
              <fg-input label="Status">
                <el-select
                  size="large"
                  v-model="status"
                  placeholder="Status">
                  <el-option
                    class="select-default"
                    v-for="item in statusList"
                    :key="item"
                    :label="statusTranslate(item)"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 25px"
                        @click="find">
                <i slot="label" class="fas fa-search-dollar"></i>
                Buscar
              </p-button>
            </div>
            <div class="col-md-2" v-if="this.requestExcel.enabled">
              <p-button type="success" style="margin-top: 25px"
                        @click="generateExcel">
                <i slot="label" class="fas fa-file-invoice"></i>
                Excel
              </p-button>
            </div>
          </div>
        </div>

        <div class="row card">
          <div class="row col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Total de Links: R$ {{toMoney(issuedSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Total Pagos: R$ {{toMoney(paidSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Total Vencidos: R$ {{toMoney(expiredSearch)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Links em Aberto: R$ {{toMoney(waitingSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Links Cancelados: R$ {{toMoney(canceledSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Chargeback: R$ {{toMoney(chargebackSearch)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pagos Pix: R$ {{toMoney(paidPixSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pagos Boleto: R$ {{toMoney(paidBoletoSearch)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pagos Cartão: R$ {{toMoney(paidCardSearch)}}</p>
            </div>
          </div>
        </div>

        <div class="row card">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="30" label="ID">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.saleId}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Criado Em">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Vencimento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.dueDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data Pagamento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.payDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Status">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: '+statusToColor(props.row.status)"></i>
                      <span> {{statusTranslate(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.amount)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Copiar Link"
                            @click="copyLink(props.row.urlLink)">
                    <i class="fas fa-copy"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="success" size="sm" icon title="Compartilhar Link"
                            @click="openWhatsapp(props.row.urlLink)">
                    <i class="fab fa-whatsapp"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Produtos"
                            @click="loadProducts(props.row.products)">
                    <i class="fa-solid fa-cart-shopping"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="primary" size="sm" icon title="Dados Pagamento e Cliente"
                            @click="loadPaymentData(props.row)" v-show="showSalesButton(props.row.status)">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row" style="padding: 10px 10px 10px 10px">
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal.client"
           footerClasses="justify-content-center"
           type="notice">
      <h6 style="text-align: center"><b>Dados do Pagamento e Cliente</b></h6>
      <p>Nome do Cliente: {{client.name}}</p>
      <p>E-mail: {{client.email}}</p>
      <p>Telefone: {{maskPhone(client.phoneNumber)}}</p>
      <p>Data do Pagamento: {{payment.payDate}}</p>
      <p>Status do Pagamento: {{payment.payStatus}}</p>
    </modal>
    <modal :show.sync="modal.products"
           footerClasses="justify-content-center"
           type="notice">
      <h6 style="text-align: center"><b>Produtos</b></h6>
      <template>
        <div class="row">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="products"
                      style="width: 100%">
              <el-table-column :min-width="130" label="Descrição">
                <template slot-scope="props">
                        <span>
                          <span>{{props.row.description}}</span>
                        </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Qtd.">
                <template slot-scope="props">
                        <span>
                          <span>{{props.row.quantity}}</span>
                        </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Valor">
                <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.value)}}</span>
                        </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Total">
                <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.amount)}}</span>
                        </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </modal>
    <input type="hidden" id="paymentLink-copy">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat} from "../../../../util/core.utils";
  import {DatePicker} from 'element-ui'
  import {daysBetween, formatDate} from "../../../../util/date.utils";
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import download from 'downloadjs'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      StatsCard,
      ChartCard,
      Modal,
    },
    data() {
      return {
        waiting: {
          type: 'success',
          icon: 'fa-solid fa-arrows-rotate',
          title: 'Links em Aberto',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        canceled: {
          type: 'success',
          icon: 'fa-solid fa-link-slash',
          title: 'Links Cancelados',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paidPix: {
          type: 'success',
          icon: 'fa-brands fa-pix',
          title: 'Pagos Pix',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paidCard: {
          type: 'success',
          icon: 'fa-regular fa-credit-card',
          title: 'Pagos Cartão',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paidBoleto: {
          type: 'success',
          icon: 'fa-solid fa-file-circle-check',
          title: 'Pagos Boleto',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        chargeback: {
          type: 'success',
          icon: 'fa-solid fa-rotate-left',
          title: 'Chargeback',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        expired: {
          type: 'success',
          icon: 'fa-solid fa-calendar-xmark',
          title: 'Total Vencidos',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paid: {
          type: 'success',
          icon: 'fa-solid fa-hand-holding-dollar',
          title: 'Total Pagos',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        issued: {
          type: 'success',
          icon: 'fa-solid fa-link',
          title: 'Valor Total de Links',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        startDate: new Date(),
        endDate: new Date(),
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        search: {
          statusTransaction: null,
          bannerCard: null,
          transactionType: null,
          terminalNumber: null,
          nsu: null,
        },
        waitingSearch: 0,
        canceledSearch: 0,
        paidPixSearch: 0,
        paidCardSearch: 0,
        paidBoletoSearch: 0,
        chargebackSearch: 0,
        expiredSearch: 0,
        paidSearch: 0,
        issuedSearch: 0,
        tableData: [],
        statusList: [null],
        status: null,
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        formRequestNotFound: false,
        requestExcel: {
          listTr: [],
          startDate: '',
          endDate: '',
          enabled: false,
        },
        username: '',
        modal: {
          client: false,
          products: false,
        },
        client: {
          street: '',
          number: '',
          complement: '',
          zipCode: '',
          neighborhood: '',
          city: '',
          state: '',
          name: '',
          socialNumber: '',
          personType: '',
          phoneNumber: '',
          email: '',
        },
        payment: {
          payDate: '',
          payStatus: '',
        },
        products: [],
        link: '',
        textWhatsapp: 'https://web.whatsapp.com/send?text=Acesse o link, selecione como pagar e pronto! ',
        whatsapp: '',
      }
    },
    created() {
      this.username = localStorage.getItem("usernameDisplay")
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pagination.currentPage = 1
          this.loadData()
        }
      },
      loadData() {
        postWs("/payment-link/last-requested-sale",
          true, null,
          {
            status: this.status,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        this.formRequestNotFound = true
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.statusList = response.data.statusList
        this.statusList.push(null)
        this.requestExcel.listTr = response.data.list
        if(this.tableData != null && response.data.list.length > 0) {
          this.requestExcel.enabled = true
        } else {
          this.requestExcel.enabled = false
        }
        this.requestExcel.startDate = formatDate(this.startDate, "DD_MM_YYYY")
        this.requestExcel.endDate = formatDate(this.endDate, "DD_MM_YYYY")
        this.waiting.value = response.data.waitingAmount
        this.canceled.value = response.data.canceledAmount
        this.paidPix.value = response.data.paidPixAmount
        this.paidCard.value = response.data.paidCardAmount
        this.paidBoleto.value = response.data.paidBoletoAmount
        this.chargeback.value = response.data.chargebackAmount
        this.expired.value = response.data.expiredAmount
        this.paid.value = response.data.paidAmount
        this.issued.value = response.data.issuedAmount
        this.waiting.footerText = response.data.waitingQuantity
        this.canceled.footerText = response.data.canceledQuantity
        this.paidPix.footerText = response.data.paidPixQuantity
        this.paidCard.footerText = response.data.paidCardQuantity
        this.paidBoleto.footerText = response.data.paidBoletoQuantity
        this.chargeback.footerText = response.data.chargebackQuantity
        this.expired.footerText = response.data.expiredQuantity
        this.paid.footerText = response.data.paidQuantity
        this.issued.footerText = response.data.issuedQuantity
        this.waitingSearch = response.data.waitingAmountSearch
        this.canceledSearch = response.data.canceledAmountSearch
        this.paidPixSearch = response.data.paidPixAmountSearch
        this.paidCardSearch = response.data.paidCardAmountSearch
        this.paidBoletoSearch = response.data.paidBoletoAmountSearch
        this.chargebackSearch = response.data.chargebackAmountSearch
        this.expiredSearch = response.data.expiredAmountSearch
        this.paidSearch = response.data.paidAmountSearch
        this.issuedSearch = response.data.issuedAmountSearch
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      statusTranslate(status){
        let retorno = 'Todos'
        switch (status) {
          case 'WAITING':
            retorno = 'Em Aberto';
            break;
          case 'CANCELED':
            retorno = 'Cancelado';
            break;
          case 'PAID_PIX':
            retorno = 'Pago PIX';
            break;
          case 'PAID_BOLETO':
            retorno = 'Pago Boleto';
            break;
          case 'PAID_CARD':
            retorno = 'Pago Cartão';
            break;
          case 'CHARGEDBACK':
            retorno = 'Chargeback';
            break;
          case 'EXPIRED':
            retorno = 'Vencido';
            break;
        }
        return retorno
      },
      statusToColor(status){
        let retorno = ''
        switch (status) {
          case 'WAITING':
            retorno = '#ffef00';
            break;
          case 'CANCELED':
            retorno = '#ff3c00';
            break;
          case 'PAID_PIX':
            retorno = '#52d019';
            break;
          case 'PAID_BOLETO':
            retorno = '#52d019';
            break;
          case 'PAID_CARD':
            retorno = '#52d019';
            break;
          case 'CHARGEDBACK':
            retorno = '#f40606';
            break;
          case 'EXPIRED':
            retorno = '#ff3c00';
            break;
        }
        return retorno
      },
      generateExcel() {
        callWs("/payment-link/sales-report-excel",
          "POST", null, true, null, this.requestExcel.listTr,
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "vendas_link_de_pagamento_"+this.username+"_"+this.requestExcel.startDate+"_a_"+this.requestExcel.endDate+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o relatório em Excel, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      loadPaymentData(sale) {
        this.client.street = sale.client.street;
        this.client.number = sale.client.number;
        this.client.complement = sale.client.complement;
        this.client.zipCode = sale.client.zipCode;
        this.client.neighborhood = sale.client.neighborhood;
        this.client.city = sale.client.city;
        this.client.state = sale.client.state;
        this.client.name = sale.client.name;
        this.client.socialNumber = sale.client.socialNumber;
        this.client.personType = sale.client.personType;
        this.client.phoneNumber = sale.client.phoneNumber;
        this.client.email = sale.client.email;
        this.payment.payDate = sale.payDate;
        this.payment.payStatus = sale.payStatus;
        this.modal.client = true
      },
      loadProducts(products) {
        this.products = products
        this.modal.products = true
      },
      showSalesButton(status){
        let retorno = false
        switch (status) {
          case 'PAID_PIX':
            retorno = true;
            break;
          case 'PAID_BOLETO':
            retorno = true;
            break;
          case 'PAID_CARD':
            retorno = true;
            break;
        }
        return retorno
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      maskPhone(phone) {
        if(phone.length == 11) {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 5) + '-' + phone.substr(7, 4)
        } else {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4) + '-' + phone.substr(6, 4)
        }
      },
      copyLink(urlLink) {
        let element = document.getElementById('paymentLink-copy');
        element.value = urlLink;
        let paymentLink = document.querySelector('#paymentLink-copy')
        paymentLink.setAttribute('type', 'text')
        paymentLink.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              title: 'Sucesso!',
              text: 'Link de Pagamento copiado com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              title: 'Erro!',
              text: 'Erro ao copiar Link de Pagamento!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            title: 'Erro!',
            text: 'Erro ao copiar Link de Pagamento!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        paymentLink.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      openWhatsapp(urlLink) {
        try {
          this.whatsapp = this.textWhatsapp.concat(urlLink)
          window.open(this.whatsapp, "about:blank");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do boleto, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-financial-report {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn.btn-primary{
      margin-right: 10px;
    }

    .el-input__inner {
      background-color: white !important;
    }
  }
</style>
