<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Cartões por Usuário
          </h4>
          <div class="row">
            <div class="col-md-3">
              <label class="control-label">Usuário</label>
              <fg-input v-model="key"/>
            </div>
            <div class="col-xl-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início Compras</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim Compras</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-xl-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-primary"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-primary"
                    v-for="item in [10, 100, 500, 1000, 5000, 10000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px;" wide>
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12">
              <el-table class="table-striped"
                        :data="dataList"
                        style="width: 100%"
                        :header-cell-style=" getHeaderStyle "
                        :cell-style=" getColColor ">
                <el-table-column :min-width="120" label="Usuário">
                  <template slot-scope="item">
                    <span>
                      @{{item.row.ownerUsername}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Cartões Virtuais">
                  <template slot-scope="item">
                    <span>
                      {{item.row.cardCountVirtual}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Cartões Físicos">
                  <template slot-scope="item">
                    <span>
                      {{item.row.cardCountPhysical}}
                    </span>
                  </template>
                </el-table-column>
<!--                <el-table-column :min-width="120" label="Trans. C. Virtuais">-->
<!--                  <template slot-scope="item">-->
<!--                    <span>-->
<!--                      {{item.row.transactionsCountVirtual}}-->
<!--                    </span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
                <el-table-column :min-width="120" label="Compras C. Virtuais">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.transactionsTotalVirtual)}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Compras C. Físicos">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.transactionsTotalPhysical)}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Total de Compras">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.transactionsAmountTotal)}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Saldo C. Virtuais.">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.availableFundsVirtual)}}
                    </span>
                  </template>
                </el-table-column>
<!--                <el-table-column :min-width="120" label="Trans. C. Físicos">-->
<!--                  <template slot-scope="item">-->
<!--                    <span>-->
<!--                      {{item.row.transactionsCountPhysical}}-->
<!--                    </span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
                <el-table-column :min-width="120" label="Saldo C. Físicos">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.availableFundsPhysical)}}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="120" label="Saldo Disp. Total">
                  <template slot-scope="item">
                    <span>
                      R$ {{toMoney(item.row.availableFundsTotal)}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="55" label="Ações">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p-button type="danger" v-if="isAuthorized('BO_CREDIT_CARD_CANCEL_CARD')" @click="cancelCards(item.row.ownerUsername)"
                                   title="Cancelar Cartões e Devolver Saldo">
                          <i class="fa-solid fa-skull-crossbones"></i>
                        </p-button>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="search">
            </p-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {callWs, failWs, postWs} from "src/ws.service"
  import {formatDate} from "src/util/date.utils"
  import {toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {isAuthorized} from "@/util/role.utils";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      PPagination,
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        key: null,
        dataList: [],
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    created() {
      this.search()
    },
    methods: {
      toMoney,
      isAuthorized,
      getHeaderStyle(){
        return 'font-size:12px';
      },
      getColColor({row, column, rowIndex, columnIndex}) {
        if(columnIndex > 0 && columnIndex < 5){
          if(columnIndex % 2 > 0)
            return 'background-color:#edf9f6'
          else
            return 'background-color:#f4fffc'
        } else if(columnIndex === 5){
          return 'background-color:#fffff4'
        } else if(columnIndex >= 7 && columnIndex < 9){
          return 'background-color:#fffff4'
        }
      },
      search() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }

          callWs("/bo/creditcard/list",
            "POST", null, true, null,
            { key: this.key,
                    startDate: formatDate(this.startDate, "DD/MM/YYYY"),
                    endDate: formatDate(this.endDate, "DD/MM/YYYY"),
                    pageNumber: this.pagination.currentPage - 1,
                    pageSize: this.pagination.perPage
            }, this.loadTable, error =>{
              swal({
                title: 'Ops',
                text: 'Não foi possível obter a lista de cartões. Tente novamente mais tarde.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'error'
              })
            })
        })
      },
      loadTable(response) {
        this.dataList = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        this.statistics = response.data.statistics;
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.dataList.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
        if (this.dataList.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'Não foram encontrados cartões.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      cancelCards(ownerUsername){
        swal({
          title: 'Atenção!',
          text: 'Deseja cancelar os cartões do usuário @' + ownerUsername + ' e devolver o saldo para conta?',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-warning btn-fill',
          showCancelButton: true,
          confirmButtonText: 'Cancelar Cartões',
          cancelButtonText: 'Abortar',
          type: 'warning'
        }).then(result => {
          if(result){
            callWs("/bo/creditcard/cancelCards",
              "POST", null, true, null,
              {key: ownerUsername},
              response => {
                  swal({
                    title: 'Sucesso',
                    text: 'Cartões cancelados com sucesso e o saldo está na conta do usuário',
                    buttonsStyling: true,
                    confirmButtonClass: 'btn btn-warning btn-fill',
                    type: 'success'
                  })
                  this.search()
                },
              error => {
                swal({
                  title: 'Ops',
                  text: 'Um ou mais cartões não foram cancelados com sucesso. Tente novamente mais tarde.',
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'error'
                })
            })
          }
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
