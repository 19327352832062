<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12">
        <card>
          <div class="card-header">
            <h4>Aprovações</h4>
          </div>
          <div class="card-body row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input label="Vendedor" placeholder="Vendedor"
                        name="salePersonUsername" v-model="search.salePersonUsername"
                        class="card-login__input"></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Origem">
                <el-select class="select-default" v-model="search.referralSource" placeholder="Origem">
                  <el-option value="" label="Todos" ></el-option>
                  <el-option v-for="option in referralSourceOptions" :key="option" :value="option" :label="option" ></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [10,25,50,100,250]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 25px;"
                        @click="find">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Buscar
              </p-button>
            </div>
            <div class="col-md-12">
              <div v-show="this.tableData.length > 0">
                <p-button @click.prevent="generateExcel" style="margin-top: 24px">
                  <i class="fa-regular fa-file-excel"></i> Exportar Excel
                </p-button>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-user"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Quantidade de Contas</p>
                      <p class="card-title ">{{ Number(this.qtyAccounts)}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-chart-line"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Valor total Transacionado</p>
                      <p class="card-title ">R$ {{ toMoney(this.totalTransactionAmount) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="tableData.length>0"
           style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="130" label="Usuário">
                  <template slot-scope="item">
                    <span>
                      <b>@{{ item.row.username }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Data de Aprovação">
                  <template slot-scope="item">
                    <span>
                      {{ formatDate(item.row.accountApprovalDate, 'DD/MM/YYYY')}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="Origem">
                  <template slot-scope="item">
                    <span>
                      {{ item.row.referralSource }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Aprovado por">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.accountApprovedBy ? '@' + item.row.accountApprovedBy : '-' }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor Transacionado (R$)">
                  <template slot-scope="item">
                    <span>
                      <span><b>{{ toMoney(item.row.totalTransactionAmount) }}</b></span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Vendedor">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.salePersonUsername ? "@" + item.row.salePersonUsername : '-' }}</b>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {postWs} from "src/ws.service"
import swal from 'sweetalert2'
import Modal from "@/components/UIComponents/Modal.vue";
import checkbox from "@/components/UIComponents/Inputs/Checkbox.vue";
import {DatePicker} from 'element-ui'
import {toMoney} from "@/util/core.utils";
import {formatDate} from "@/util/date.utils";
import download from "downloadjs";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  computed: {
    checkbox() {
      return checkbox
    },
    referralSourceOptions: () => [
      "Facebook",
      "Instagram",
      "Google",
      "Youtube",
      "Indicação"
    ]
  },
  components: {
    Modal,
    PPagination,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      search: {
        salePersonUsername: '',
        referralSource: '',
      },
      tableData: [],
      totalTransactionAmount: 0,
      qtyAccounts: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    toMoney,
    find() {
      this.pagination.currentPage = 1
      this.loadData()
    },
    loadData() {
      postWs("/bo/acc/list-account-approval",
        true, null,
        {
          salePersonUsername: this.search.salePersonUsername,
          referralSource: this.search.referralSource,
          startDate: formatDate(this.startDate, 'DD/MM/YYYY'),
          endDate: formatDate(this.endDate, 'DD/MM/YYYY'),
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        },
        this.successLoadData,
        this.failLoadData)
    },
    successLoadData(response) {
      this.tableData = response.data.list
      this.qtyAccounts = response.data.qtyAccounts
      this.totalTransactionAmount = response.data.totalTransactionAmount
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }

      if (this.tableData.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem resultados.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failLoadData() {
      swal({
        title: 'Atenção',
        text: 'Ocorreu um erro ao listar contas aprovadas! Verifique os dados e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    generateExcel() {
      postWs("/bo/acc/generate-account-approval-excel",
        true, null,
        {
          salePersonUsername: this.search.salePersonUsername,
          startDate: formatDate(this.startDate, 'DD/MM/YYYY'),
          endDate: formatDate(this.endDate, 'DD/MM/YYYY'),
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        },
        this.successGenerateExcel,
        this.failGenerateExcel)
    },
    successGenerateExcel(response) {
      try {
        download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
          "relatorio_contas_aprovadas_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      } catch (ex) {
        swal({
          title: 'Atenção!',
          text: 'Erro ao gerar arquivo Excel. Tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failGenerateExcel() {
      swal({
        title: 'Atenção!',
        text: 'Erro ao gerar arquivo Excel. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    }
  }
}
</script>
<style lang="scss">
.dzs-acc-search {
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .input-group .form-control {
    padding-left: 2px !important;
  }

  .input-group .input-group-prepend .input-group-text {
    padding: 5px 0 5px 10px !important;
    font-size: 14px !important;
  }

  .cell {
    font-size: 12px !important;
  }

  th {
    color: black !important;
  }
}
</style>

<style lang="scss" scoped>
.dzs-acc-search .el-table .td-actions button.btn {
  margin-right: 0px;
}
</style>
