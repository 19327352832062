<template>
  <div class="dzs-acc-edit">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Editar Cliente</h4>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="newClient != null">
              <tabs type="border-card" tab-content-classes="tab-space">
                <tab-pane id="info" key="info" label="Dados do Cliente">
                  <commercial-client-info :username="username"
                                          :expectedTransactionAmount="expectedTransactionAmount"
                                          :salesPoint="salesPoint"
                                          :observations="observations"
                                          @saveInterface="setClientInfoInterface"
                                          @saveFinished="clientDocsInterface.save();"/>
                </tab-pane>
                <tab-pane id="docs" key="docs" label="Documentos Adicionais">
                  <commercial-client-extra-docs :username="username" @saveInterface="setClientDocsInterface"
                                                @saveFinished="clientHistoryInterface.save()"/>
                </tab-pane>
                <tab-pane id="history" key="history" label="Histórico de Atendimentos">
                  <commercial-client-history :username="username" @saveInterface="setClientHistoryInterface"
                                             @saveFinished="finished"/>
                </tab-pane>
                <tab-pane id="approve" key="approve" label="Aprovar Adquirente"
                          v-if="isSomeAuthorization(['BO_GATEWAY_MANAGE']) && showApproveAcquire">
                  <account-gateway-acquirer-edit :username="username"></account-gateway-acquirer-edit>
                </tab-pane>
                <tab-pane id="limits" key="limits" label="Alterar Limites e Taxas"
                          v-if="isSomeAuthorization(['BO_SET_USER_OP_LIMIT','BO_DELETE_USER_OP_LIMIT']) && showAlterLimits">
                  <user-operation-limit></user-operation-limit>
                </tab-pane>
              </tabs>
            </div>
          </div>
          <div v-if="preApproved != null" class="row mt-3">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <div>
                  <span style="font-weight: bolder">Status:</span><span
                  class="text-uppercase">&nbsp; {{
                    preApproved == null ? 'Pendente' : preApproved ? 'Aprovado' : 'Rejeitado'
                  }}</span>
                  <br>
                  <span style="font-weight: bolder">Executivo de Vendas:</span>&nbsp; {{ approvedByName }}
                  <span style="margin-left: 5px" v-if="isSomeAuthorization(['BO_COMMERCIAL_SALES_PERSON'])">
                      <p-button type="primary" size="sm" icon round title="Visualizar Dashboard do Vendedor"
                                @click="openSalePersonDashboard(approvedByUsername)">
                        <i class="fa-regular fa-eye"></i>
                      </p-button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div style="text-align: center">
                <hr>
                <button v-if="!newClient || isSomeAuthorization(['BO_COMMERCIAL_EDIT_CLIENT'])"
                        class="btn btn-success btn-fill btn-wd" @click="save">
                  Salvar Atendimento
                </button>
                <span v-else class="text-danger mb-3" style="font-weight: bold">
                  "Não é possível salvar atendimentos pois o cliente ainda não pertence à carteira de um vendedor."
                  <br><br>
                </span>
                <button v-if="newClient && isSomeAuthorization(['BO_COMMERCIAL_EDIT_CLIENT'])"
                        class="btn btn-warning btn-fill btn-wd" @click="saveClientBasicInfo">
                  Em Atendimento
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-3"
               v-if="(this.showButtonPreAprove || this.preApproved == null) && isSomeAuthorization(['BO_COMMERCIAL_EDIT_CLIENT'])">
            <div class="col-md-12">
              <hr>
              <div class="d-flex justify-content-center">
                <button class="btn btn-info btn-fill btn-wd m-2" @click="preApprove(true)" v-if="showButtonPreAprove">
                  <i class="fa-solid fa-user-plus"></i> Pré Aprovar
                </button>
                <button class="btn btn-danger btn-fill btn-wd m-2" @click="preApprove(false)"
                        v-if="preApproved == null">
                  <i class="fa-solid fa-ban"></i> Rejeitar
                </button>
              </div>
            </div>
          </div>
          <hr>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <button v-if="rejectedByKyc && isSomeAuthorization(['BO_RETURN_CLIENT_KYC'])"
                        class="btn btn-warning btn-fill btn-wd m-2"
                        @click="returnClientKyc">
                  <i class="fa-solid fa-user-plus"></i> Retornar Usuário para validação KYC
                </button>
                <button v-if="rejectedAtPreApproval && isSomeAuthorization(['BO_RETURN_CLIENT'])"
                        class="btn btn-danger btn-fill btn-wd m-2"
                        @click="returnClient">
                  <i class="fa-solid fa-user-plus"></i> Retornar Usuário Rejeitado
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {failWs, getWs, postWs} from 'src/ws.service'
import {TabPane, Tabs} from 'element-ui'
import CommercialClientInfo
  from "@/components/Dashboard/Views/BackOffice/Commercial/ClientDetails/CommercialClientInfo";
import CommercialClientExtraDocs
  from "@/components/Dashboard/Views/BackOffice/Commercial/ClientDetails/CommercialClientExtraDocs";
import CommercialClientHistory
  from "@/components/Dashboard/Views/BackOffice/Commercial/ClientDetails/CommercialClientHistory";
import AccountGatewayAcquirerEdit from "@/components/Dashboard/Views/BackOffice/AccountGatewayAcquirer/Edit"
import UserOperationLimit from "@/components/Dashboard/Views/BackOffice/OperationLimit/UserOperationLimit"
import swal from "sweetalert2";
import {isSomeAuthorization} from "@/util/role.utils";

export default {
  childInterface: {
    save: () => {
    }
  },
  components: {
    CommercialClientExtraDocs,
    CommercialClientInfo,
    CommercialClientHistory,
    AccountGatewayAcquirerEdit,
    UserOperationLimit,
    TabPane,
    Tabs
  },
  data() {
    return {
      clientDetails: {
        username: '',
        salesPersonName: '',
      },
      username: '',
      salesPersonUsername: '',
      newClient: null,
      preApproved: null,
      rejectedByKyc: null,
      rejectedAtPreApproval: null,
      expectedTransactionAmount: 0,
      salesPoint: '',
      observations: '',
      clientInfoInterface: null,
      clientDocsInterface: null,
      clientHistoryInterface: null,
      approvedByUsername: null,
      approvedByName: null,
      showApproveAcquire: false,
      showAlterLimits: false,
      showButtonPreAprove: false
    }
  },
  created() {
    this.username = this.$route.params.username
    this.loadAccount()
    this.getAccountStatus()

    this.$root.$on('docStatusChanged', this.getAccountStatus)

    this.$root.$on('accAcquirerStatusChanged', this.getAccountStatus)
  },
  beforeDestroy() {
    this.$root.$off('docStatusChanged')
    this.$root.$off('accAcquirerStatusChanged')
  },
  methods: {
    isSomeAuthorization,
    loadAccount() {
      getWs("/bo/commercial/dashboard/getClient", true, {username: this.username},
        response => {
          this.expectedTransactionAmount = response.data.expectedTransactionAmount
          this.salesPersonUsername = response.data.salesPersonName
          this.salesPoint = response.data.salesPoint
          this.observations = response.data.observations
          this.newClient = response.data.newClient
          this.preApproved = response.data.preApproved
          this.approvedByUsername = response.data.salePersonUsername
          this.approvedByName = response.data.salePersonName
        },
        error => {
          if ("NOT_ALLOWED" === error.response.data.response) {
            swal({
              title: 'Aviso!',
              text: "Você não é um executivo de vendas!",
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          } else {
            failWs(error)
          }
        })
    },
    getAccountStatus() {
      postWs("/acc/get-account", true,
        {username: this.username}, null,
        ({data}) => {
          this.showApproveAcquire = data.accountDocumentStatus === "APPROVED"
          this.showAlterLimits = data.accountGatewayAcquirer.status === "APPROVED"
          this.rejectedByKyc = data.accountDocumentStatus === "KYC_REJECTED"
          this.showButtonPreAprove = (data.accountDocumentStatus === "KYC_AT_APPROVAL" || data.accountDocumentStatus === "KYC_MANUAL_APPROVAL") && this.preApproved === null;
          this.rejectedAtPreApproval = data.accountDocumentStatus === "REJECTED_AT_PRE_APPROVAL"
        }, this.failStatusUpdate)
    },
    failStatusUpdate(error) {
      swal({
        title: 'Aviso!',
        text: 'Erro ao buscar dados. Atualize a página e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    setClientInfoInterface(ifc) {
      this.clientInfoInterface = ifc;
    },
    setClientDocsInterface(ifc) {
      this.clientDocsInterface = ifc;
    },
    setClientHistoryInterface(ifc) {
      this.clientHistoryInterface = ifc;
    },
    finished() {
      swal({
        title: 'Sucesso!',
        text: 'Os dados do cliente foram salvos com sucesso!',
        confirmButtonClass: 'btn btn-success btn-fill',
        type: "success",
        confirmButtonText: 'Prosseguir'
      })
      this.loadAccount()
    },
    preApprove(approve) {
      let txt = null
      if (approve) {
        txt = 'Ao pré aprovar esta conta, será impossível realizar novos atendimentos ao cliente até que ele seja aprovado pelo compliance! Deseja prosseguir?'
      } else {
        txt = 'Ao rejeitar esta conta não será possível realizar novos atendimentos ao cliente! Deseja prosseguir?'
      }
      swal({
        title: 'Atenção!',
        text: txt,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then(() => {
        postWs("/bo/commercial/dashboard/preApproveClient", true, null,
          {username: this.username, preApproved: approve},
          response => {
            this.loadAccount()
          }, error => {
            {
              if ("NOT_ALLOWED" === error.response.data.response) {
                swal({
                  title: 'Aviso!',
                  text: "Você não é um executivo de vendas!",
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
              } else {
                failWs(error)
              }
            }
          })
      })
    },
    returnClientKyc() {
      postWs("/bo/commercial/dashboard/return-client-kyc", true,
        {username: this.username}, null,
        response => {
          this.loadAccount()
        }, error => {
          {
            swal({
              title: 'Atenção',
              text: 'Aconteceu um erro ao retornar o usuário, favor contactar o suporte!'
            })
          }
        })
    },
    returnClient() {
      postWs("/bo/commercial/dashboard/return-rejected-client", true,
        {username: this.username}, null,
        response => {
          this.loadAccount()
        }, error => {
          {
            swal({
              title: 'Atenção',
              text: 'Aconteceu um erro ao retornar o usuário!'
            })
          }
        })
    },
    save() {
      if (this.newClient && this.preApproved == null) {
        swal({
          title: 'Atenção!',
          text: 'Salvar estes dados irá associar o cliente à sua carteira. Deseja prosseguir?',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          type: "warning",
          showCancelButton: true,
          confirmButtonText: 'Prosseguir',
          cancelButtonText: 'Cancelar',
        }).then(() => {
          this.doSave()
        })
      } else {
        this.doSave();
      }
    },
    doSave() {
      this.clientInfoInterface.save();
    },
    openSalePersonDashboard(username) {
      this.openWindow('/bo/commercial/dashboard/' + username)
    },
    openWindow(url) {
      try {
        window.open(url)
      } catch (ex) {
        swal({
          title: 'Aviso!',
          text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    saveClientBasicInfo() {
      const dto = {
        username: this.username,
        salesPersonUserName: this.salesPersonUsername,
      };
      console.log('DTO being sent:', dto);

      postWs(
        '/bo/commercial/dashboard/saveClientBasicInfo',
        true,
        null,
        dto,
        response => {
          console.log('Success:', response);
          this.finished();
        },
        error => {
          console.error('Error:', error);
          swal({
            title: 'Atenção',
            text: 'Aconteceu um erro ao salvar as informações básicas do cliente!'
          });
        }
      );
    }

  }
}

</script>
<style lang="scss">

</style>
