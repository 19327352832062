<template>
  <div class="dzs-overview">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="row">
          <div :class="'col-lg-3 col-md-12'"
               v-for="stats in {successCard}" v-if="stats.type == 'success' || stats.value > 0">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)"
                        :title-class="getCardTitleClass()">
<!--              <div class="stats" slot="footer" style="cursor: pointer">
                <div @click="stats.action">
                  <i :class="stats.footerIcon"></i>
                  {{stats.footerText}}
                </div>
              </div>-->
            </stats-card>
          </div>
          <div :class="'col-lg-3 col-md-12'"
               v-for="stats in {totalTransactionAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)"
                        :title-class="getCardTitleClass()">
<!--              <div class="stats" slot="footer" style="cursor: pointer">-->
<!--                <div @click="stats.action">-->
<!--                  <i :class="stats.footerIcon"></i>-->
<!--                  {{stats.footerText}}-->
<!--                </div>-->
<!--              </div>-->
            </stats-card>
          </div>
          <div :class="'col-lg-3 col-md-12'"
               v-for="stats in {averageTicket}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)"
                        :title-class="getCardTitleClass()">
<!--              <div class="stats" slot="footer" style="cursor: pointer">-->
<!--                <div @click="stats.action">-->
<!--                  <i :class="stats.footerIcon"></i>-->
<!--                  {{stats.footerText}}-->
<!--                </div>-->
<!--              </div>-->
            </stats-card>
          </div>
          <div :class="'col-lg-3 col-md-12'"
               v-for="stats in {totalTransactions}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="stats.value"
                        :title-class="getCardTitleClass()">
              <!--<div class="stats" slot="footer" style="cursor: pointer">
                <div @click="stats.action">
                  <i :class="stats.footerIcon"></i>
                  {{stats.footerText}}
                </div>
              </div>-->
            </stats-card>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <p slot="title" class="card-category" style="font-size: 16px">Relatório Mês Vigente</p>
              </div>
              <div class="card-body row">
                <div class="col-sm-12 mt-2">
                  <el-table class="table-striped"
                            :data="tableDataReportIn"
                            style="width: 100%"
                            :row-class-name="tableRowClassNameIn">
                    <el-table-column :min-width="120" label="Entradas">
                      <template slot-scope="props">
                      <span>
                        <span>{{getTransactionName(props.row.nameTransaction)}}</span>
                      </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="80" label="Valor">
                      <template slot-scope="props">
                      <span>
                        <span :style="getBluredContentClass()"><b>R$</b> {{toMoney(props.row.value)}}</span>
                      </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <br>
                  <el-table class="table-striped"
                            :data="tableDataReportOut"
                            style="width: 100%"
                            :row-class-name="tableRowClassNameOut">
                    <el-table-column :min-width="120" label="Saídas">
                      <template slot-scope="props">
                    <span>
                      <span>{{getTransactionName(props.row.nameTransaction, props.row.subTypeTransaction)}}</span>
                    </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="80" label="Valor">
                      <template slot-scope="props">
                    <span>
                      <span :style="getBluredContentClass()"><b>R$</b> {{toMoney(props.row.value)}}</span>
                    </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <p class="monthly-balance">Saldo do mês: <span :style="getBluredContentClass()"> R$ {{toMoney(monthlyBalance)}} </span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <chart-card :chart-data="historyBalanceChart"
                        :chart-height="320"
                        chart-title="Histórico de saldo por data">
              <p slot="title" class="card-category" style="font-size: 16px">
                Histórico de saldo por data
              </p>
              <badge slot="title-label" :style="getBluredContentClass()" type="primary">R$ {{toMoney(this.successCard.value)}}</badge>
              <template slot="footer-right">
                <a @click="$router.push('transactions')" href="#" style="color: #252422">Ver mais detalhes</a>
                <p-button @click="$router.push('transactions')"
                          aria-label="add button" style="margin-left: 10px"
                          type="primary" round icon size="sm">
                  <i class="nc-icon nc-simple-add"></i>
                </p-button>
              </template>
            </chart-card>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <card>
          <p class="card-category" style="font-size: 16px; position: absolute">
            Últimas Transações
          </p>
          <time-line v-if="timelineItems.length>0">
            <time-line-item v-for="item in timelineItems"
                            v-bind:data="item"
                            v-bind:key="item.pos"
                            :class="item.in?'timeline-inverted':''"
                            :badgeType="['FEE','SUBSCRIPTION','BOLETO'].includes(item.transactionType) ?
                            'warning':(!item.in?
                            'danger':(['CHARGEBACK'].includes(item.transactionType)?
                            'info':'success'))"
                            :badgeIcon="getTransactionLogo(item.transactionType)">
              <badge slot="header" :type="['FEE','SUBSCRIPTION','BOLETO'].includes(item.transactionType) ?
                            'warning':(!item.in?
                            'danger':(['CHARGEBACK'].includes(item.transactionType)?
                            'info':'success'))">
                <b>{{getTransactionName(item.transactionType, item.transactionSubType)}}</b>
                <p-button v-if="['TED','TEV'].includes(item.transactionType)&&!item.in" @click="printReceipt(item.idTransaction)"
                          class="btn btn-info" title="Ver Comprovante">
                <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>
                </p-button>
                <p-button v-if="['PIX_PAYMENT'].includes(item.transactionType)&&!item.in" @click="printReceiptPix(item.idTransaction)"
                          class="btn btn-info" title="Ver Comprovante">
                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>
                </p-button>
                <p-button v-if="['PIX'].includes(item.transactionType)&&item.in" @click="printReceiptPix(item.idTransaction)"
                          class="btn btn-info" title="Ver Comprovante">
                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>
                </p-button>
                <p-button v-if="['CHARGEBACK'].includes(item.transactionType)&&!item.in&&['PIX'].includes(item.transactionSubType)" @click="printReceiptPix(item.idTransaction)"
                          class="btn btn-info" title="Ver Comprovante">
                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>
                </p-button>
                <p-button v-if="['BOLETO_PAYMENT'].includes(item.transactionType)&&!item.in" @click="printReceiptPayment(item.idTransaction)"
                          class="btn btn-info" title="Ver Comprovante">
                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>
                </p-button>
              </badge>
              <div slot="content">
                <i :class="item.in?'fas fa-arrow-down':'fas fa-arrow-up'"></i><b :style="getBluredContentClass()"> R$ {{toMoney(item.fundsMovement)}}</b>
                <p style="margin-bottom: 0"
                   v-if="['TED','TEV','BONUS', 'PAYMENT_PARTNER_PIX'].includes(item.transactionType) || (!item.in &&'BOLETO'==item.transactionType)">
                  <i class="far fa-user"></i> {{item.in?item.source:item.destination}}
                </p>
                <p style="margin-bottom: 0"
                   v-if="['SPLIT_PIX'].includes(item.transactionType) && item.in">
                  <i class="far fa-user"></i> {{item.in?item.source:''}}
                </p>
                <p v-if="item.transactionType == 'TED'">
                  <i class="fas fa-university" v-if="item.destinationBank!=null"></i> {{item.destinationBank}}
                </p>
              </div>
              <h6 slot="footer">
                <i class="fas fa-clock-o"></i> {{item.transactionDate}}
              </h6>
<!--              <p slot="footer" style="font-size: 13px;padding-top: 3px;color: #6f6f6f;margin-bottom: -1px;">-->
<!--                Saldo: R$ {{toMoney(item.accountBalance)}}-->
<!--              </p>-->
            </time-line-item>
          </time-line>
          <time-line v-if="timelineItems.length<=0">
            <time-line-item class="timeline-inverted" badgeType="info" badgeIcon="fas fa-star fa-2x">
              <badge slot="header" type="info">Olá !</badge>
              <div slot="content">
                <i class="fas fa-star"></i> Bem-vindo a SuitPay
              </div>
              <h6 slot="footer">
                <div></div>
              </h6>
            </time-line-item>
          </time-line>
          <template slot="footer">
            <hr/>
            <div style="float: right !important;">
              <a @click="$router.push('transactions')" href="#" style="color: #252422">Ver mais detalhes</a>
              <p-button @click="$router.push('transactions')"
                        aria-label="add button" style="margin-left: 10px"
                        type="primary" round icon size="sm">
                <i class="nc-icon nc-simple-add"></i>
              </p-button>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
  import {getTransactionName, getTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {callWs, failWs, postWs} from 'src/ws.service'
  import {toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import {router} from "@/main";
  import {isNotAuthorized} from "@/util/role.utils";
  import {isAuthorized} from "@/util/role.utils";
  import Vue from "vue";

  export default {
    components: {
      StatsCard,
      ChartCard,
      TimeLine,
      TimeLineItem,
      Badge
    },
    data() {
      return {
        usernameDisplay: '',
        successCard: {
          type: 'success',
          icon: 'nc-icon nc-money-coins',
          title: 'Saldo Disponível',
          value: 0,
          footerText: 'Atualizar valores',
          action: this.callHistoryWs,
          footerIcon: 'nc-icon nc-refresh-69'
        },
        totalTransactionAmount: {
          type: 'danger',
          icon: 'nc-icon nc-chart-bar-32',
          title: 'Volume Transacionado',
          value: 0,
          footerText: 'Atualizar valores',
          action: this.callHistoryWs,
          footerIcon: 'nc-icon nc-refresh-69'
        },
        averageTicket: {
          type: 'error',
          icon: 'nc-icon nc-sound-wave',
          title: 'Ticket Médio',
          value: 0,
          footerText: 'Atualizar valores',
          action: this.callHistoryWs,
          footerIcon: 'nc-icon nc-refresh-69'
        },
        totalTransactions: {
          type: 'warning',
          icon: 'nc-icon nc-refresh-69',
          title: 'Total de Transações',
          value: 0,
          footerText: 'Atualizar valores',
          action: this.callHistoryWs,
          footerIcon: 'nc-icon nc-refresh-69'
        },
        blockedCard: {
          type: 'danger',
          icon: 'nc-icon nc-lock-circle-open',
          title: 'Saldo Bloqueado',
          value: 0,
          footerText: 'Nenhuma transação bloqueada',
          action: '',
          footerIcon: 'nc-icon nc-bell-55'
        },
        historyBalanceChart: {
          labels: [],
          series: []
        },
        timelineItems: [],
        tableDataReport: [],
        tableDataReportIn: [],
        tableDataReportOut: [],
        totalRowsIn: 0,
        totalRowsOut: 0,
        monthlyBalance: 0,
        blurValues: true
      }
    },
    mounted() {
      Vue.prototype.$bus = new Vue({});
      Vue.prototype.$bus.$on('toggleBlurValuesDashboard', () => {
        this.toggleBlur()
      })

      this.usernameDisplay = localStorage.getItem("usernameDisplay")
      if (window.location.href.includes('suitpay.app')
        && location.protocol != 'https:') {
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
        return
      }
      if(localStorage.getItem("docsOk") == "false") {
        this.$router.push('/user/profile')
      }
      this.callHistoryWs()
      this.getStatusAccount()
      if(localStorage.getItem('firstTimeOpeningOverview') && isAuthorized('SUIT_JOURNEY')) {
        this.getSuitJourneyPopUpsToShow()
      }
      // swal({
      //   title: 'Vem feriado por ai!',
      //   html: "<div><img src=\"/img/notification/PopUp_DiaDoConsumidor.png\"></div>",
      //   buttonsStyling: true,
      //   confirmButtonClass: 'btn btn-warning btn-fill',
      //   type: 'warning',
      //   imageUrl: '/img/notification/popup_overview.png',
      //   width: 800,
      //   showCloseButton: true,
      //   showConfirmButton: false,
      //   background: 'none'
      // })
    },
    methods: {
      isNotAuthorized,
      toMoney,
      getTransactionLogo,
      getTransactionName,
      callHistoryWs() {
        callWs("/transaction/transactions-by-period",
          "POST", null, true, null,
          {pageNumber: 0, pageSize: 20},
          this.loadDashboard,
          failWs)
      },
      loadDashboard(response) {
        let transactions = response.data.list
        if (transactions.length <= 0) {
          return
        }
        this.totalTransactions.value = response.data.totalTransactions
        this.averageTicket.value = response.data.averageTicket
        this.totalTransactionAmount.value = response.data.totalTransactionAmount
        this.timelineItems = transactions
        this.tableDataReport = response.data.monthlyReport.monthlyTransactionsReport
        this.monthlyBalance = response.data.monthlyReport.monthlyBalance
        this.createReport(this.tableDataReport);
        this.createCards(response.data.currentBalance, response.data.accountBlockedBalance, response.data.accountAvailableBalance)
        this.createChart(transactions)
      },
      createReport(transactions) {
        for(let tr in transactions) {
          if(transactions[tr].typeTransaction == 'IN') {
            this.tableDataReportIn.push(transactions[tr])
            this.totalRowsIn++
          }
          if(transactions[tr].typeTransaction == 'OUT') {
            this.tableDataReportOut.push(transactions[tr])
            this.totalRowsOut++
          }
        }
      },
      createCards(accountBalance, accountBlockedBalance, accountAvailableBalance) {
        localStorage.setItem('accountBalance', accountBalance)
        localStorage.setItem('accountAvailableBalance', accountAvailableBalance < 0 ? 0 : accountAvailableBalance)
        this.successCard.value = accountBalance
        this.blockedCard.value = accountBlockedBalance
      },
      createChart(transactions) {
        let labels = []
        let series = []
        let lastBalanceDate = ""
        let lastBalance = 0.00
        for (let transaction in transactions) {
          let position = (parseInt(transaction) + 1)
          let transactionDate = transactions[transactions.length - position].transactionDate.substring(0, 5)
          let accountBalance = transactions[transactions.length - position].accountBalance
          if (transactionDate != lastBalanceDate) {
            labels.push(lastBalanceDate)
            series.push(lastBalance.toFixed(2))
          }
          if (position == transactions.length) {
            labels.push(transactionDate)
            series.push(accountBalance.toFixed(2))
          } else {
            lastBalanceDate = transactionDate
            lastBalance = accountBalance
          }
        }
        this.historyBalanceChart.labels = labels
        this.historyBalanceChart.series = series
      },
      printReceipt(id) {
        postWs("/receipt/get-receipt-transactions",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      printReceiptPix(id) {
        postWs("/pix/get-receipt-pix",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      printReceiptPayment(id) {
        postWs("/boleto/pdf-boleto-payment",
          true,
          {id: id}, null,
          this.successPrintReceiptPayment,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successPrintReceipt(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.pdfBase64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      successPrintReceiptPayment(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      getStatusAccount(){
        callWs("/acc/get-status-account",
          "POST", null, true, null, null,
          this.loadStatusAccount,
          failWs)
      },
      loadStatusAccount(response){
        if(response.data == 'PENDING_DOCUMENT'){
          this.successCard.footerText = 'Status: Conta Pendente de Documentação'
          this.successCard.footerIcon = 'fas fa-exclamation-triangle'
        }else if(response.data == 'PENDING_VALIDATION'){
          this.successCard.footerText = 'Status: Conta em Análise'
          this.successCard.footerIcon = 'fas fa-exclamation-triangle'
        }
      },
      tableRowClassNameIn ({row, rowIndex}) {
        if (rowIndex + 1 === this.totalRowsIn) {
          return 'table-success'
        }
        return ''
      },
      tableRowClassNameOut ({row, rowIndex}) {
        if (rowIndex + 1 === this.totalRowsOut) {
          return 'table-danger'
        }
        return ''
      },
      getSuitJourneyPopUpsToShow(index = 0) {
        const ignoreActivation = [new RegExp('docs')]

        localStorage.removeItem('firstTimeOpeningOverview')

        const suitJourneyItems = JSON.parse(localStorage.getItem('suitJourneyAvailableToRequest'))

        if(!suitJourneyItems || suitJourneyItems.length === 0 || (index > suitJourneyItems.length - 1)) return

        const suitJourneyItem = suitJourneyItems[index]
        const image = new Image()

        if(RegExp('Letter$').test(suitJourneyItem.name)) {
          this.getSuitJourneyPopUpsToShow(index + 1)
          return
        }

        if(suitJourneyItem.actionable) {
          postWs('/suitjourney/activation/activate', true, null,
            {
              activationIdentifier: suitJourneyItem.name
            },
            () => null, () => null
          )
        }

        image.src = `/static/img/suitjourney/popup/${suitJourneyItem.name}.png`
        swal({
          imageUrl: image.src,
          customClass: 'suit-journey-popup',
          showCloseButton: true,
          showConfirmButton: false,
          background: 'none',
          onClose: () => {
            if(index < suitJourneyItems.length - 1) {
              this.getSuitJourneyPopUpsToShow(index + 1)
            }
          }
        }).catch(swal.noop)

        swal.getImage().onclick = () => {
          swal.close()
          router.push('/suitjourney/page?showLetter=1')
        }
      },
      getCardTitleClass(){
        if (!this.blurValues)
          return 'card-title'
        else
          return  'card-title-blur'
      },
      getBluredContentClass(){
        if (!this.blurValues)
          return ''
        else
          return 'color: transparent !important; text-shadow: 0 0 8px #000000 !important;'
      },
      toggleBlur(){
        this.blurValues = !this.blurValues
      }
    }
  }

</script>
<style lang="scss">
  .dzs-overview {
    .banner-wrapper {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 1rem;
      }
    }

    div.card-body, ul.timeline {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }

    div.card-footer {
      padding-top: 0px !important;
    }

    .timeline-panel {
      border: 1px solid #E3E3E3;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .card-body.row {
      padding-top: 0px;
    }

    .el-table.table-striped.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition {
      font-size: 13px;
    }

    .monthly-balance {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 35px 0 15px 0;
      text-align: center;
      color: #0c072d;
    }
  }

  .suit-journey-popup {
    width: 90vw !important;
    max-width: 60rem !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 2rem;
      cursor: pointer;
    }
  }
</style>
