<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Área do Colaborador</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="getUsers">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-12 row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="60" label="Usuário">
                  <template slot-scope="props">
                    <span><b>@{{ props.row.username }}</b></span>
                  </template>
                </el-table-column>
                <el-table-column :width="240" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" icon title="Ver extrato da conta" v-if="props.row.statement"
                              @click="openAccountStament(props.row.username)">
                      <i class="fas fa-money-bill-transfer"></i>
                    </p-button>
                    <p-button type="warning" size="sm" icon title="Ver extrato de cartões" v-if="props.row.creditCard"
                              @click="openCreditCardStament(props.row.username)">
                      <i class="fa-regular fa-credit-card"></i>
                    </p-button>
                    <p-button type="success" size="sm" icon title="Ver extrato de Pix de Entrada"
                              v-if="props.row.pixInGateway"
                              @click="openPixInStatement(props.row.username)">
                      <i class="fa-solid fa-hand-holding-dollar"></i>
                    </p-button>
                    <p-button type="danger" size="sm" icon title="Ver extrato de Pix de Saída"
                              v-if="props.row.pixOutGateway"
                              @click="openPixOutStatement(props.row.username)">
                      <i class="fa-solid fa-money-bill-trend-up"></i>
                    </p-button>
                    <p-button type="muted" size="sm" icon title="Ver extrato de Webhook" v-if="props.row.webhook"
                              @click="openWebhookStatement(props.row.username)">
                      <i class="fa-solid fa-globe"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import {failWs, postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {isSomeAuthorization} from "src/util/role.utils"
import {toMoney} from "@/util/core.utils";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  data() {
    return {
      search: {
        key: '',
      },
      tableData: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    toMoney,
    isSomeAuthorization,
    resetDefaultValues() {
      this.search.key = ''
      this.tableData = [];
      this.getUsers()
    },
    getUsers() {
      postWs("/collaborator/list-users",
        true, null,
        {
          username: this.search.key,
          collaborator: localStorage.getItem("username"),
        }, this.successLoadData,
        failWs)
    },
    successLoadData(response) {
      this.tableData = response.data
    },
    openAccountStament(username) {
      this.openWindow('/transactions/byperiod/' + username)
    },
    openCreditCardStament(username) {
      this.openWindow('/credit-card/transactions/' + username)
    },
    openPixInStatement(username) {
      this.openWindow('/transactions/pix/in/' + username)
    },
    openPixOutStatement(username) {
      this.openWindow('/transactions/pix/out/' + username)
    },
    openWebhookStatement(username) {
      this.openWindow('/transactions/webhook/' + username)
    },
    openWindow(url) {
      try {
        window.open(url)
      } catch (ex) {
        swal({
          title: 'Aviso!',
          text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
  }
}
</script>
<style lang="scss">
.dzs-acc-search {
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .input-group .form-control {
    padding-left: 2px !important;
  }

  .input-group .input-group-prepend .input-group-text {
    padding: 5px 0 5px 10px !important;
    font-size: 14px !important;
  }

  .cell {
    font-size: 12px !important;
  }

  th {
    color: black !important;
  }
}
</style>

<style lang="scss" scoped>
.dzs-acc-search .el-table .td-actions button.btn {
  margin-right: 0px;
}
</style>
