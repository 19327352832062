<template>
<div class="dzs-card row">
  <div class="col-lg-12 col-md-7" v-show="!formSearchPlan">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-body" style="padding-bottom: 30px">
          <form @submit.prevent="updateRequest">
          <div>
            <h4>Aprovar Solicitação</h4>
          </div>
          <div class="row">
            <div class="col-md-5">
              <fg-input type="text"
                        label="Usuário"
                        disabled="true"
                        v-model="request.username">
              </fg-input>
            </div>
            <div class="col-md-7">
              <fg-input type="email"
                        label="E-mail"
                        disabled="true"
                        v-model="request.email">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="tel"
                        label="Celular"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="request.phoneNumber1"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="tel"
                        label="Telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="request.phoneNumber2"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        label="Nome Completo"
                        v-model="request.name"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div class="row" style="margin-top: 0px">
            <div class="col-md-5">
              <fg-input label="Tipo de Pessoa"
                        type="text"
                        v-model="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'Pessoa Física':'Pessoa Jurídica'"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-7">
              <fg-input :label="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'CPF':'CNPJ'"
                        type="tel"
                        v-mask="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?['###.###.###-##']:['##.###.###/####-##']"
                        name="CPF/CNPJ"
                        v-model="request.document"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div v-if="request.personType==='LEGAL_PERSON'">
            <div class="row">
              <div class="col-md-12">
                <fg-input type="text"
                          label="Razão Social"
                          v-model="request.companyName"
                          disabled="true">
                </fg-input>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 30px" >
            <div class="col-md-5">
              <fg-input type="tel"
                        v-mask="['#####-###']"
                        label="CEP"
                        v-model="request.deliveryAddress.zipCode"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-7">
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <fg-input type="text"
                        label="Rua/Av."
                        v-model="request.deliveryAddress.street"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="tel"
                        v-mask="['######']"
                        label="Número"
                        v-model="request.deliveryAddress.number"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        label="Complemento"
                        v-model="request.deliveryAddress.complement"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <fg-input type="text"
                        label="Bairro"
                        v-model="request.deliveryAddress.neighborhood"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-5">
              <fg-input type="text"
                        label="Cidade"
                        v-model="request.deliveryAddress.city"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input type="text"
                        label="Estado"
                        v-model="request.deliveryAddress.state"
                        disabled="true">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p-checkbox class="text-left" v-model="formRequest.correctAdress">
                <b>O endereço de entrega está correto.</b>
              </p-checkbox>
            </div>
          </div>
          <div v-show="!formRequest.correctAdress" style="margin-top: 30px">
            <h4 slot="header" class="card-title">
              Novo Endereço de Entrega
            </h4>
            <div class="row">
              <div class="col-md-5">
                <fg-input type="tel"
                          v-mask="['#####-###']"
                          label="CEP"
                          name="CEP"
                          v-validate="{required: !formRequest.correctAdress, min: 9}"
                          :error="getError('CEP')"
                          v-on:blur="this.searchZipCode"
                          v-model="formRequest.deliveryAddress.zipCode"
                          key="cep">
                </fg-input>
              </div>
              <div class="col-md-7">
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <fg-input type="text"
                          maxlength="40"
                          :disabled="(viacep && viacep.logradouro  != '')"
                          label="Rua/Av."
                          name="Rua/Av."
                          v-validate="{required: !formRequest.correctAdress}"
                          :error="getError('Rua/Av.')"
                          v-model="formRequest.deliveryAddress.street"
                          key="rua">
                </fg-input>
              </div>
              <div class="col-md-4">
                <fg-input type="tel"
                          v-mask="['######']"
                          label="Número"
                          name="Número"
                          v-validate="{required: !formRequest.correctAdress}"
                          :error="getError('Número')"
                          v-model="formRequest.deliveryAddress.number"
                          key="numero">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input type="text"
                          maxlength="40"
                          label="Complemento"
                          v-model="formRequest.deliveryAddress.complement"
                          key="complemento">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <fg-input type="text"
                          maxlength="40"
                          :disabled="(viacep && viacep.bairro != '')"
                          label="Bairro"
                          name="Bairro"
                          v-validate="{required: !formRequest.correctAdress}"
                          :error="getError('Bairro')"
                          v-model="formRequest.deliveryAddress.neighborhood"
                          key="bairro">
                </fg-input>
              </div>
              <div class="col-md-5">
                <fg-input type="text"
                          label="Cidade"
                          disabled="true"
                          v-model="formRequest.deliveryAddress.city"
                          key="cidade">
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input type="text"
                          label="Estado"
                          disabled="true"
                          placeholder=""
                          v-model="formRequest.deliveryAddress.state"
                          key="estado">
                </fg-input>
              </div>
            </div>
          </div>
          <br/>
          <div class="card-body row">
            <div class="col-md-12">
              <h6>Lista de Planos Cadastrados</h6>
            </div>
            <div class="col-md-5">
              <label class="control-label">Nome do Plano</label>
              <label class="control-label" style="float: right">
                <a href="#" @click="loadNewDataPlan">
                  <i slot="label" class="fas fa-history"></i> Consultar Lista de Planos</a>
              </label>
              <fg-input v-model="planName" @keydown.enter.prevent="searchDataPlan"/>
            </div>
            <div class="col-md-3">
              <p-button type="success" style="margin-top: 25px"
                        @click="searchDataPlan">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Incluir Plano
              </p-button>
            </div>
            <div class="col-sm-12">
              <el-table class="table-striped"
                        :data="request.tableDataPlansActive"
                        style="width: 100%">
                <el-table-column :min-width="40" label="ID">
                  <template slot-scope="props">
                      <span>
                        <span><b>#{{props.row.id}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Nome Plano">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.planName}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Bandeira">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.nameCardTerminalBanner}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="60" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" icon title="Excluir"
                              @click="deleteList(props.row.id)">
                      <i class="fas fa-minus"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Máquina Escolhida"
                        v-model="request.terminal.nameModel"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Alterar Máquina">
                <el-select size="large"
                           v-model="formRequest.terminalName">
                  <el-option class="select-info"
                             value="S920"
                             label="S920 / Q92"/>
                  <el-option class="select-info"
                             value="D175"
                             label="D175"/>
                </el-select>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Quantidade de Máquinas"
                        v-model="request.terminal.amount"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text"
                        label="Alterar Quantidade de Máquinas"
                        v-model="formRequest.terminalAmount">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Faturamento"
                        v-model="billingRangeText"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Atualizar Faturamento">
                <el-select size="large"
                           v-model="formRequest.billingRange">
                  <el-option class="select-info"
                             value="RANGE1"
                             label="Até R$50 mil"/>
                  <el-option class="select-info"
                             value="RANGE2"
                             label="De R$50 mil a R$100 mil"/>
                  <el-option class="select-info"
                             value="RANGE3"
                             label="De R$100 mil a R$500 mil"/>
                  <el-option class="select-info"
                             value="RANGE4"
                             label="Acima de R$500 mil"/>
                </el-select>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Parceiro Cadastrado"
                        v-model="request.partner.username"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <label>Overprice Parceiro Cadastrado</label>
              <money class="form-control input-lg"
                     v-model="request.partner.overprice"
                     v-bind="fee"
                     disabled="true">
              </money>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div style="float: left; width: 83%">
                    <label class="control-label">Cadastrar Novo Parceiro</label>
                    <fg-input v-model="partner.searchUsername" style="width: 100%">
                      <template slot="addonLeft">@</template>
                    </fg-input>
                  </div>
                  <p-button type="primary" style="margin-top: 25px; float: left" @click="searchPartner">
                    <i slot="label" class="fas fa-search"></i>
                  </p-button>
                </div>
              </div>
              <card v-if="!isNullOrEmpty(partner.name)">
                <div class="col-md-12" v-if="!isNullOrEmpty(partner.name)" style="text-align: center">
                  <div class="col-sm-12">
                    <label class="control-label">Nome Parceiro</label>
                    <p class="form-control-static">{{partner.name}}</p>
                  </div>
                </div>
                <div class="col-md-12" v-if="!isNullOrEmpty(partner.socialNumber)" style="text-align: center">
                  <div class="col-sm-12">
                    <label class="control-label">CPF/CNPJ Parceiro</label>
                    <p class="form-control-static">{{partner.socialNumber}}</p>
                    <input v-mask="['###.***.***-##', '##.###.###/####-##']" v-model="partner.socialNumber"
                           style="display: none"/>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6" style="padding-top: 20px; padding-bottom: 20px" v-if="!isNullOrEmpty(partner.name)">
              <div class="row">
                <div class="col-sm-3">
                  <label>Débito</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.debitoOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 1x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.creditoOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 2x</label>
                  </fg-input>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito2xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 3x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito3xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <label>Créd. 4x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito4xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 5x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito5xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 6x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito6xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 7x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito7xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <label>Créd. 8x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito8xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 9x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito9xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 10x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito10xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
                <div class="col-sm-3">
                  <label>Créd. 11x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito11xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <label>Créd. 12x</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.credito12xOverpricePartner"
                         v-bind="fee">
                  </money>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Status Atual"
                        v-model="request.requestStatus"
                        disabled="true">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Atualizar Status">
                <el-select size="large"
                           v-model="formRequest.requestStatus">
                  <el-option value="APPROVED"
                             class="select-default"
                             label="Aprovado"/>
                  <el-option value="NOT_APPROVED"
                             class="select-default"
                             label="Não Aprovado"/>
                  <el-option value="CANCELED_BY_CUSTOMER"
                             class="select-default"
                             label="Cancelado Pelo Cliente"/>
                  <el-option value="CANCELED_BY_VENDOR"
                             class="select-default"
                             label="Cancelado Pelo Vendedor"/>
                </el-select>
              </fg-input>
            </div>
          </div>
          <div class="card-footer text-center">
            <button type="submit" class="btn btn-info btn-fill btn-wd" :disabled="disableButton">
              Atualizar Requisição
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 card" v-show="formSearchPlan" style="max-width: 800px; margin: 0 auto">
    <div class="card-header">
      <h4>Selecionar Novo Plano</h4>
    </div>
    <div class="card-body row">
      <div class="col-md-6">
        <label class="control-label">Nome da Plano</label>
        <fg-input v-model="searchPlan.key"/>
      </div>
      <div class="col-sm-3">
        <fg-input label="Exibir">
          <el-select
            class="select-default"
            v-model="paginationPlans.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in [5,10,25,50]"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </fg-input>
      </div>
      <div class="col-md-3">
        <p-button type="success" style="margin-top: 25px"
                  @click="findPlans">
          <i slot="label" class="nc-icon nc-check-2"></i>
          Buscar
        </p-button>
      </div>
      <div class="col-sm-12 mt-2 clickable-rows">
        <el-table class="table-striped"
                  :data="tableDataPlans"
                  @row-click="selectDestinationPlan"
                  style="width: 100%">
          <el-table-column :min-width="60" label="ID">
            <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" label="Nome">
            <template slot-scope="props">
                    <span>
                      <span>{{props.row.planName}}</span>
                    </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="80" label="Status">
            <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">EXIBINDO DO {{paginationPlans.fromNumber}} AO {{paginationPlans.toNumber}}, DE
          {{paginationPlans.totalNumber}} REGISTROS.</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="paginationPlans.currentPage"
                      :per-page="paginationPlans.perPage"
                      :total="paginationPlans.totalNumber"
                      :click="this.loadDataPlans">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
    import {postWs, getWs, failWs, callWs} from 'src/ws.service'
    import swal from 'sweetalert2'
    import {isNullOrEmpty, moneyToFloat} from "../../../../../../util/core.utils";

    export default {
      components: {
      },
      data() {
        return {
          idRequest: '',
          request: {
            username: '',
            name: '',
            email: '',
            document: '',
            phoneNumber1: '',
            phoneNumber2: '',
            companyName: '',
            personType: '',
            deliveryAddress: {
              codIbge: '',
              street: '',
              number: '',
              complement: '',
              zipCode: '',
              neighborhood: '',
              city: '',
              state: ''
            },
            plan: {
              carTerminalPlan: '',
              cardTerminalPlanLabel: '',
            },
            terminal: {
              nameModel: '',
              amount: '',
            },
            billingRange: '',
            partner: {
              username: '',
              debitoOverpricePartner: 0,
              creditoOverpricePartner: 0,
              credito2xOverpricePartner: 0,
              credito3xOverpricePartner: 0,
              credito4xOverpricePartner: 0,
              credito5xOverpricePartner: 0,
              credito6xOverpricePartner: 0,
              credito7xOverpricePartner: 0,
              credito8xOverpricePartner: 0,
              credito9xOverpricePartner: 0,
              credito10xOverpricePartner: 0,
              credito11xOverpricePartner: 0,
              credito12xOverpricePartner: 0,
            },
            requestStatus: '',
            tableDataPlansActive: [],
          },
          formRequest: {
            idRequest: '',
            correctAdress: true,
            deliveryAddress: {
              codIbge: '',
              street: '',
              number: '',
              complement: '',
              zipCode: '',
              neighborhood: '',
              city: '',
              state: ''
            },
            planId: [],
            terminalName: '',
            terminalAmount: 0,
            billingRange: '',
            partner: '',
            debitoOverpricePartner: 0,
            creditoOverpricePartner: 0,
            credito2xOverpricePartner: 0,
            credito3xOverpricePartner: 0,
            credito4xOverpricePartner: 0,
            credito5xOverpricePartner: 0,
            credito6xOverpricePartner: 0,
            credito7xOverpricePartner: 0,
            credito8xOverpricePartner: 0,
            credito9xOverpricePartner: 0,
            credito10xOverpricePartner: 0,
            credito11xOverpricePartner: 0,
            credito12xOverpricePartner: 0,
            requestStatus: 'APPROVED',
          },
          viacep: {
            logradouro: '',
            bairro: '',
            ibge: '',
            localidade: '',
            uf: '',
          },
          searchPlan: {
            key: '',
            active: true,
          },
          tableDataPlans: [],
          formSearchPlan: false,
          planCheck: false,
          planNotFound: false,
          planName: '',
          billingRangeText: '',
          partner: {
            socialNumber: '',
            name: '',
            searchUsername: '',
          },
          paginationPlans: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          },
          fee: {
            decimal: ',',
            thousands: '.',
            prefix: '',
            suffix: ' %',
            precision: 2,
            masked: false
          },
          disableButton: false
        }
      },

      created() {
        this.idRequest = this.$route.params.id
      },

      mounted() {
        this.loadData()
      },

      methods: {
        isNullOrEmpty,
        loadData() {
          getWs('/bo/card-terminal/get-card-terminal-request', true, {idRequest: this.idRequest}, this.loadRequestData, this.failRequestData)
        },
        loadRequestData(response) {
          this.formRequest.idRequest = this.idRequest

          this.request.requestStatus = this.reqStatusToLabel(response.data.requestStatus)
          if(response.data.requestStatus == 'ACTIVE'){
            this.disableButton = true
          }
          this.request.username = response.data.username
          this.request.name = response.data.name
          this.request.email = response.data.email
          this.request.document = response.data.document
          this.request.phoneNumber1 = response.data.phoneNumber1
          this.request.phoneNumber2 = response.data.phoneNumber2
          this.request.companyName = response.data.companyName
          this.request.personType = response.data.personType
          this.request.deliveryAddress.codIbge = response.data.deliveryAddress.codIbge
          this.request.deliveryAddress.street = response.data.deliveryAddress.street
          this.request.deliveryAddress.number = response.data.deliveryAddress.number
          this.request.deliveryAddress.complement = response.data.deliveryAddress.complement
          this.request.deliveryAddress.zipCode = response.data.deliveryAddress.zipCode
          this.request.deliveryAddress.neighborhood = response.data.deliveryAddress.neighborhood
          this.request.deliveryAddress.city = response.data.deliveryAddress.city
          this.request.deliveryAddress.state = response.data.deliveryAddress.state

          this.request.plan.plans = response.data.plans
          this.request.plan.carTerminalPlan = response.data.cardTerminalPlan
          this.request.plan.cardTerminalPlanLabel = response.data.cardTerminalPlanLabel

          this.request.terminal.nameModel = response.data.nameModelRequestedTerminal
          this.request.terminal.amount = response.data.amountRequestedTerminal

          this.request.billingRange = response.data.billingRange
          this.formRequest.billingRange = response.data.billingRange

          if(response.data.usernamePartner != null) {
            this.request.partner.username = '@' + response.data.usernamePartner;
            this.request.partner.debitoOverpricePartner = response.data.debitoOverpricePartner;
            this.request.partner.creditoOverpricePartner = response.data.creditoOverpricePartner;
            this.request.partner.credito2xOverpricePartner = response.data.credito2xOverpricePartner;
            this.request.partner.credito3xOverpricePartner = response.data.credito3xOverpricePartner;
            this.request.partner.credito4xOverpricePartner = response.data.credito4xOverpricePartner;
            this.request.partner.credito5xOverpricePartner = response.data.credito5xOverpricePartner;
            this.request.partner.credito6xOverpricePartner = response.data.credito6xOverpricePartner;
            this.request.partner.credito7xOverpricePartner = response.data.credito7xOverpricePartner;
            this.request.partner.credito8xOverpricePartner = response.data.credito8xOverpricePartner;
            this.request.partner.credito9xOverpricePartner = response.data.credito9xOverpricePartner;
            this.request.partner.credito10xOverpricePartner = response.data.credito10xOverpricePartner;
            this.request.partner.credito11xOverpricePartner = response.data.credito11xOverpricePartner;
            this.request.partner.credito12xOverpricePartner = response.data.credito12xOverpricePartner;
          }

          if(response.data.planActive != null) {
            this.request.tableDataPlansActive = response.data.planActive
          }

          this.returnBillingRange()
        },
        failRequestData(){
          swal('Erro', 'Erro ao carregar os dados da solicitação!', 'success')
        },
        getError(fieldName) {
          return this.errors.first(fieldName)
        },
        updateRequest() {
          if (this.formRequest.partner != '' && this.formRequest.debitoOverpricePartner <= 0) {
            swal({
              title: 'Aviso!',
              text: 'O Overprice do Parceiro tem que ser maior que ZERO!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model)
            this.formRequest.planId = []
            this.request.tableDataPlansActive.forEach(value => {
              this.formRequest.planId.push(value.id)
            })
            callWs("/bo/card-terminal/edit-card-terminal-request",
              "POST", null, true, null,
              this.formRequest, () => {
                swal({
                  title: 'Sucesso!',
                  text: 'Dados salvos com sucesso.',
                  buttonsStyling: true,
                  timer: 10000,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'success'
                }), this.loadData()},
              (error) => {
                swal({
                  title: 'Erro!',
                  text: 'Erro, verifique os dados e tente novamente!',
                  buttonsStyling: true,
                  timer: 10000,
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  type: 'error'
                })
              })
          })
        },
        successEdit() {
          swal({
            title: 'Sucesso!',
            text: 'Sucesso ao salvar os dados!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'success'
          })
        },
        failEdit() {
          swal({
            title: 'Erro!',
            text: 'Erro ao salvar os dados!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'error'
          })
        },
        findPlans() {
          this.paginationPlans.currentPage = 1
          this.loadDataPlans()
        },
        loadNewDataPlan(){
          this.planName = ''
          this.searchPlan.key = ''
          this.loadDataPlans()
        },
        loadDataPlans() {
          postWs("/bo/card-terminal/list-card-terminal-plans",
            true, null,
            {
              enabled: null,
              planBannerCard: null,
              key: this.searchPlan.key,
              pageNumber: this.paginationPlans.currentPage - 1,
              pageSize: this.paginationPlans.perPage
            }, this.successLoadDataPlans,
            failWs)
          return this.paginationPlans.currentPage
        },
        successLoadDataPlans(response) {
          this.formEditShow = false
          this.formSearchPlan = true
          this.tableDataPlans = response.data.list
          this.paginationPlans.totalNumber = response.data.totalNumber
          this.paginationPlans.pageMaxNumber = response.data.pageMaxNumber
          if (this.paginationPlans.totalNumber > 0) {
            this.paginationPlans.fromNumber = ((this.paginationPlans.perPage * (this.paginationPlans.currentPage - 1)) + 1)
            this.paginationPlans.toNumber = ((this.paginationPlans.fromNumber + this.tableDataPlans.length) - 1)
          } else {
            this.paginationPlans.fromNumber = 0
            this.paginationPlans.toNumber = 0
          }
        },
        searchDataPlan() {
          this.searchPlan.active = true
          this.request.tableDataPlansActive.forEach(value => {
            if(value.planName == this.planName) {
              swal('Aviso', 'Plano já foi incluído na lista!', 'warning')
              this.searchPlan.active = false
            }
          })
          if(this.searchPlan.active) {
            postWs("/bo/card-terminal/search-card-terminal-plan",
              true, {name: this.planName},
              null, this.successSearchPlan,
              () => {
                swal({
                  title: 'Aviso!',
                  text: 'Nenhum plano encontrado com esse nome!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                this.planNotFound = true
                this.planCheck = false
              })
          }
        },
        successSearchPlan(response) {
          this.request.tableDataPlansActive.push(response.data)
          this.tableDataPlan = response.data
          this.formRequest.planId = this.tableDataPlan.id
          this.planNotFound = false
          this.planCheck = true
        },
        selectDestinationPlan(destination) {
          this.formRequest.planId = destination.id
          this.planName = destination.planName
          this.formEditShow = true
          this.formSearchPlan = false
          this.planNotFound = false
          this.planCheck = true
        },
        deleteList(id){
          let index = 0
          this.request.tableDataPlansActive.forEach(value => {
            if(value.id == id) {
              this.request.tableDataPlansActive.splice(index, 1);
            }
            index++
          })
        },
        returnBillingRange() {
          if(this.request.billingRange == 'RANGE1') {
            this.billingRangeText = 'Até R$50 mil'
          }
          if(this.request.billingRange == 'RANGE2') {
            this.billingRangeText = 'De R$50 mil a R$100 mil'
          }
          if(this.request.billingRange == 'RANGE3') {
            this.billingRangeText = 'De R$100 mil a R$500 mil'
          }
          if(this.request.billingRange == 'RANGE4') {
            this.billingRangeText = 'Acima de R$500 mil'
          }
        },
        searchPartner() {
          this.partner.name = ''
          this.partner.socialNumber = ''
          this.formRequest.partner = ''
          postWs("/acc/find",
            true, {username: this.partner.searchUsername}, null,
            (response) => {
              this.formRequest.partner = this.partner.searchUsername
              this.partner.name = response.data.destinationName
              this.partner.socialNumber = response.data.destinationSocialNumber
            }, (error) => {
              this.partner.name = ''
              this.partner.socialNumber = ''
              this.formRequest.partner = ''
              this.partner.searchUsername = ''
              if (error.response.status == 404) {
                swal({
                  title: 'Aviso!',
                  text: 'Conta destino não existe ou está bloqueada para receber transações.',
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
              }
            })
        },
        searchZipCode() {
          this.viacep = null
          this.formRequest.deliveryAddress.codIbge = ""
          this.formRequest.deliveryAddress.city = ""
          this.formRequest.deliveryAddress.state = ""
          getWs("/cep/get-cep",
            true, {cep: this.formRequest.deliveryAddress.zipCode},
            (response) => {
              this.viacep = response.data
              this.formRequest.deliveryAddress.codIbge = this.viacep.ibge
              if (this.viacep.logradouro.length > 40) {
                this.formRequest.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
              } else {
                this.formRequest.deliveryAddress.street = this.viacep.logradouro
              }
              if(this.viacep.bairro.length > 40) {
                this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
              } else {
                this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro
              }
              this.formRequest.deliveryAddress.city = this.viacep.localidade
              this.formRequest.deliveryAddress.state = this.viacep.uf
            },
            (e) => {
              swal({
                title: 'Aviso!',
                text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'warning'
              })
              return
            })
        },
        reqStatusToLabel(reqStatus) {
          if (reqStatus == 'WAITING_APPROVAL') {
            return 'Aguardando Aprovação'
          } else if (reqStatus == 'APPROVED') {
            return 'Aprovado'
          } else if (reqStatus == 'PREPARING_SHIPMENT') {
            return 'Aguardando Envio'
          } else if (reqStatus == 'SHIPPED') {
            return 'Enviado'
          } else if (reqStatus == 'PARTIAL_SHIPPING') {
            return 'Envio Parcial'
          } else if (reqStatus == 'NOT_APPROVED') {
            return 'Não Aprovado'
          } else if (reqStatus == 'CANCELED_BY_CUSTOMER') {
            return 'Cancelado Pelo Cliente'
          } else if (reqStatus == 'CANCELED_BY_VENDOR') {
            return 'Cancelado Pelo Vendedor'
          } else if (reqStatus == 'ACTIVE') {
            return 'Ativo'
          }
          return '??'
        },
      }
    }
</script>

<style lang="scss">
  .dzs-card {
    .card-title {
      margin-top: 0px;
    }
  }

</style>
