<template>
  <div class="dzs-compliance">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Devoluções MED
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input type="text"
                        label="Usuário"
                        v-model="username">
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Banco Emissor" class="has-danger">
                <el-select
                  class="select-default"
                  id="issuingBank"
                  name="issuingBank"
                  v-model="issuingBankId"
                >
                  <el-option class="select-default" value="" label="Todos"></el-option>
                  <el-option class="select-default" v-for="bank in issuingBanks" :key="bank.id" :value="bank.id"
                             :label="bank.name"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Ordernar Por" class="has-danger">
                <el-select
                  class="select-default"
                  id="orderBy"
                  name="orderBy"
                  v-model="orderBy"
                >
                  <el-option class="select-default" value="qty_med_desc" label="Qtd. MEDs - Decrescente"></el-option>
                  <el-option class="select-default" value="qty_med_asc" label="Qtd. MEDs - Crescente"></el-option>
                  <el-option class="select-default" value="alphabetical" label="Ordem Alfabética"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="searchMedRequests" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-user"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Qtd. de Usuários</p>
                      <p class="card-title ">{{ this.medRequestsSummary.userAmount }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-clipboard-list"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Qtd. de Solicitações</p>
                      <p class="card-title ">{{ this.medRequestsSummary.medAmount }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-chart-line"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Valor total das solicitações</p>
                      <p class="card-title ">R$ {{ toMoney(this.medRequestsSummary.requestsValue) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-undo-alt"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Valor Devolvido</p>
                      <p class="card-title ">R$ {{ toMoney(this.medRequestsSummary.returnedValue) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-exchange-alt"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Valor a Devolver</p>
                      <p class="card-title ">R$ {{ toMoney(this.medRequestsSummary.valueToBeReturned) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="medRequests.length>0" style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="medRequests"
                        style="width: 100%">
                <el-table-column :min-width="90" label="username">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.username }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Conta criada em">
                  <template slot-scope="item">
                    <span>
                      <b>{{ formatDate(item.row.accountCreatedDate, 'DD/MM/YYYY') }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Saldo da Conta">
                  <template slot-scope="item">
                    <span>R$ {{ toMoney(item.row.balance) }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Qtd. Solicitações">
                  <template slot-scope="item">
                    <span>
                      <span>{{ item.row.requestsAmount }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor das Solicitações">
                  <template slot-scope="item">
                    <span>
                      R$ {{ toMoney(item.row.requestsValue) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor Devolvido">
                  <template slot-scope="item">
                    <span>
                      R$ {{ toMoney(item.row.returnedValue) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor a ser Devolvido">
                  <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.valueToBeReturned) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="">
                  <template slot-scope="item"item.row.username>
                    <span>
                      <span>
                        <router-link :to="getUserMedRequestsRoute(item.row.username)" target="_blank">
                          <p-button type="success" size="sm" icon title="Solicitações do Usuário">
                              <i class="fas fa-list"/>
                          </p-button>
                        </router-link>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.searchMedRequests">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs} from "src/ws.service"
import {daysBetween, formatDate} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import PPagination from 'src/components/UIComponents/Pagination.vue'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      medRequests: [],
      medRequestsSummary: {
        userAmount: 0,
        medAmount: 0,
        requestsValue: 0,
        returnedValue: 0,
        valueToBeReturned: 0
      },
      username: null,
      issuingBanks: [],
      issuingBankId: '',
      orderBy: 'qty_med_desc',
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      pagination: {
        perPage: 100,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  mounted() {
    this.getIssuingBanks()
    this.searchMedRequests()
  },
  methods: {
    formatDate,
    toMoney,
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    searchMedRequests() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs('/bo/compliance/med-requests', 'POST', null, true, null,
        {
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
          startDate: formatDate(this.startDate, "DD/MM/YYYY"),
          endDate: formatDate(this.endDate, "DD/MM/YYYY"),
          username: this.username,
          issuingBankId: this.issuingBankId,
          orderBy: this.orderBy,
        },
        this.successSearchMedRequests,
        this.failSearchMedRequests)
    },
    successSearchMedRequests(response) {
      this.medRequests = response.data.list
      this.medRequestsSummary.userAmount = response.data.userAmount
      this.medRequestsSummary.medAmount = response.data.medAmount
      this.medRequestsSummary.requestsValue = response.data.requestsValue
      this.medRequestsSummary.returnedValue = response.data.returnedValue
      this.medRequestsSummary.valueToBeReturned = response.data.valueToBeReturned
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.medRequests.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
      if (this.medRequests.length <= 0) {
        swal({
          title: 'Atenção',
          text: 'Não foram encontradas solicitações neste período.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failSearchMedRequests() {
      swal({
        title: 'Atenção',
        text: 'Ocorreu um erro inesperado ao buscar os dados. Verifique os filtros e tente novamente.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    getUserMedRequestsRoute(username) {
      const {href} = this.$router.resolve({
        name: 'BoComplianceMedUser',
        params: {username},
        query: {
          startDate: formatDate(this.startDate, "YYYY-MM-DD"),
          endDate: formatDate(this.endDate, "YYYY-MM-DD"),
          issuingBankId: this.issuingBankId
        }
      })
      return href
    },
    getIssuingBanks() {
      callWs('/bo/compliance/get-issuing-banks', null, null, true, null, null,
        this.successGetIssuingBanks,
        () => null
      )
    },
    successGetIssuingBanks(response) {
      this.issuingBanks = response.data
    }
  }
}
</script>
<style lang="scss">
.dzs-compliance {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .btn.btn-default{
    background-color: #17a2b8 !important;
  }
  .btn.btn-default:hover{
    background-color: #0a7282 !important;
  }

  .file-container {
    color: #9a9a9a !important;
    display: grid;
    gap: .25rem;
    margin-bottom: 1rem;

    p {
      margin: 0 !important;
      color: inherit;
    }

    input {
      cursor: pointer;
    }
  }
}
</style>
