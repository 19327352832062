<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Cadastros da Adquirente</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.username"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.status">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="WAITING_SENT_BANK_DETAILS"
                           class="select-default"
                           label="Ag. Envio de Dados Bancários"/>
                <el-option value="WAITING_SENT"
                           class="select-default"
                           label="Ag. Envio de Dados Adquirente"/>
                <el-option value="WAITING_APPROVAL"
                           class="select-default"
                           label="Ag. Aprovação"/>
                <el-option value="APPROVED"
                           class="select-default"
                           label="Aprovado"/>
                <el-option value="DISAPPROVED"
                           class="select-default"
                           label="Reprovado"/>
                <el-option value="INACTIVE"
                           class="select-default"
                           label="Inativo"/>
                <el-option value="INACTIVE_WITH_CHECKOUT"
                           class="select-default"
                           label="Inativo com Acesso ao Checkout"/>
                <el-option value="FAIL"
                           class="select-default"
                           label="Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [25,50,100]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="E-mail">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{docStatusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="70" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Editar"
                            @click="edit(props.row.username)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          username: '',
          status: 'WAITING_SENT_BANK_DETAILS'
        },
        tableData: [],
        pagination: {
          perPage: 25,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      resetDefaultValues(){
        this.search.username = ''
        this.search.documentStatus = 'WAITING_SENT_BANK_DETAILS',
        this.pagination.currentPage = 1
        this.pagination.perPage = 25
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/acc/list-account-gateway-acquirer",
          true, null,
          {
            username: this.search.username,
            status: this.search.status,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao buscar dados. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.accounts
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(username) {
        this.openWindow('/bo/accountgatewayacquirer/edit/' + username)
      },
      removeDevice(account) {
        if (!account.hasValidDevice) {
          swal({
            title: 'Alerta!',
            text: 'Esta conta não possui dispositivo autorizado.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        swal({
          title: 'Remover Dispositivo',
          text: "Você deseja realmente remover todos dispositivos dessa conta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callRemoveDeviceWs(account)
        })
      },
      callRemoveDeviceWs(account) {
        postWs("/bo/device/remove-device", true,
          {username: account.username}, null,
          () => {
            account.hasValidDevice = false
          }, failWs)
      },
      enableOrDisable(account) {
        swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar a conta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ativar',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'DESATIVAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callEnabledWs(account, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callEnabledWs(account, false)
          }
        })
      },
      callEnabledWs(account, status) {
        postWs("/bo/auth/update-enabled",
          true, null,
          {username: account.username, status: status},
          function () {
            account.enabled = status
          }, failWs)
      },
      lockOrUnlock(account) {
        swal({
          title: 'Bloquear / Desbloquear',
          text: 'Você deseja bloquear ou desbloquear a conta?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'desbloquear',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'BLOQUEAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callNonlockedWs(account, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callNonlockedWs(account, false)
          }
        })
      },
      callNonlockedWs(account, status) {
        postWs("/bo/auth/update-nonlocked",
          true, null,
          {username: account.username, status: status},
          function () {
            account.nonlocked = status
          }, failWs)
      },
      setUserOperationLimits(username) {
        this.openWindow('/bo/user-operation-limit/edit/' + username)
      },
      docStatusToLabel(documentStatus) {
        if (documentStatus == 'WAITING_SENT') {
          return 'Ag. Envio de Dados Adquirente'
        } else if (documentStatus == 'WAITING_SENT_BANK_DETAILS') {
          return 'Ag. Envio de Dados Bancários'
        } else if (documentStatus == 'WAITING_APPROVAL') {
          return 'Ag. Aprovação'
        } else if (documentStatus == 'DISAPPROVED') {
          return 'Reprovado'
        } else if (documentStatus == 'APPROVED') {
          return 'Aprovado'
        } else if (documentStatus == 'INACTIVE') {
          return 'Inativo'
        } else if (documentStatus == 'INACTIVE_WITH_CHECKOUT') {
          return 'Inativo com Acesso ao Checkout'
        } else if (documentStatus == 'FAIL') {
          return 'Erro ao Processar Dados'
        }
        return '??'
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      hasAuthorization(role){
        return isAuthorized(role);
      },
      hasAnyAuthorization(roles){
        return isSomeAuthorization(roles);
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
