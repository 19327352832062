const dddList={estadoPorDdd:{11:"SP",12:"SP",13:"SP",14:"SP",15:"SP",16:"SP",17:"SP",18:"SP",19:"SP",21:"RJ",22:"RJ",24:"RJ",27:"ES",28:"ES",31:"MG",32:"MG",33:"MG",34:"MG",35:"MG",37:"MG",38:"MG",41:"PR",42:"PR",43:"PR",44:"PR",45:"PR",46:"PR",47:"SC",48:"SC",49:"SC",51:"RS",53:"RS",54:"RS",55:"RS",61:"DF",62:"GO",63:"TO",64:"GO",65:"MT",66:"MT",67:"MS",68:"AC",69:"RO",71:"BA",73:"BA",74:"BA",75:"BA",77:"BA",79:"SE",81:"PE",82:"AL",83:"PB",84:"RN",85:"CE",86:"PI",87:"PE",88:"CE",89:"PI",91:"PA",92:"AM",93:"PA",94:"PA",95:"RR",96:"AP",97:"AM",98:"MA",99:"MA"},dddsPorEstado:{AC:["68"],AL:["82"],AM:["92","97"],AP:["96"],BA:["71","73","74","75","77"],CE:["85","88"],DF:["61"],ES:["27","28"],GO:["62","64"],MA:["98","99"],MG:["31","32","33","34","35","37","38"],MS:["67"],MT:["65","66"],PA:["91","93","94"],PB:["83"],PE:["81","87"],PI:["86","89"],PR:["41","42","43","44","45","46"],RJ:["21","22","24"],RN:["84"],RO:["69"],RR:["95"],RS:["51","53","54","55"],SC:["47","48","49"],SE:["79"],SP:["11","12","13","14","15","16","17","18","19"],TO:["63"]}};

const localPhoneMask10 = '(##) ####-####'
const localPhoneMask11 = '(##) #####-####'
const localPhoneMask12 = '+## (##) ####-####'
const localPhoneMask13 = '+## (##) #####-####'
const foreignPhoneMask = '+##############'

export function phoneNumberMask(phone) {
  return isLocalPhoneNumber(phone) ? localNumberMask() : [foreignPhoneMask]
}

export function isLocalPhoneNumber(phone) {
  if (phone !== null) {
    return getDDDorDDI(phone) === '55' && validDDD(getDDDorDDI(phone))
  }
  return false
}

function localNumberMask() {
  return [localPhoneMask10, localPhoneMask11, localPhoneMask12, localPhoneMask13]
}

function validDDD(ddd) {
  if (ddd !== null) {
    return Object.keys(dddList.estadoPorDdd).includes(ddd.toString())
  }
  return false
}

export function cleanNumber(phone) {
  let p = phone.match(/\d+/g)
  return p !== null ? p.toString() : ''
}

function getDDDorDDI(phone) {
  return cleanNumber(phone).substring(0, 2)
}
