<template>
  <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="http://www.suitpay.app">
        <img src="/static/img/vue-logo-2.png" width="150px"/>
      </a>
    </div>
    <template slot="navbar-menu">
      <div>
          <p><i class="fa-solid fa-check"></i> Checkout Transparente</p>
      </div>
    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'

  export default {
    name: 'main-navbar',
    components: {
      Navbar
    },
    data() {
      return {
        showMenu: false
      }
    },
    methods: {
      showDashboardBtn() {
        let path = this.$router.currentRoute.path;
        return !path.includes('login') && !path.includes('register') && !path.includes('forgot');
      }
    }
  }
</script>

<style scoped>

</style>
