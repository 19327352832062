<template>
  <div class="login-page dzs-recovery">
    <div class="content-selector">
      <button @click="onClickChangeContent(-1)">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <button @click="onClickChangeContent(1)">
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
    <div class="full-page login-page section-image sb-login-page">
      <div class="content">
        <div class="container row">
          <div class="col-lg-6 content-wrapper">
            <component :is="carousel.currentContent"></component>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="card-recovery">
              <div class="card-recovery__header">
                <img src="/static/img/login/SuitPayVerde.svg"/>
                <div>
                  <span>Digite a sua nova senha!</span>
                </div>
              </div>
              <form class="div-recovery">
                <fg-input type="password"
                          placeholder="Nova Senha"
                          name="Nova Senha" ref="password"
                          v-validate="{required: true, min: 8}"
                          :error="getError('Nova Senha')"
                          v-model="password">
                </fg-input>
                <fg-input type="password"
                          placeholder="Confirme a Senha"
                          name="Confirme a Senha"
                          v-validate="{required: true, confirmed: 'password'}"
                          :error="getError('Confirme a Senha')"
                          v-model="confirmPassword">
                </fg-input>
                <br/>
                <p-button native-type="submit" slot="footer" name="submit"
                          type="success" class="card-recovery__button card-recovery__button--primary"
                          @click.prevent="recovery">SALVAR NOVA SENHA
                </p-button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img @load="onLoadBackground" class="bg-image" :src="currentBg"
             alt="">
      </div>
    </div>
  </div>
</template>
<script>
  import {Button, Card, Checkbox} from 'src/components/UIComponents'
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import SuitAcademy from '@/components/UIComponents/Carousel/SuitAcademyContent'
  import SuitJourney from '@/components/UIComponents/Carousel/SuitJourneyContent'
  import Banking from '@/components/UIComponents/Carousel/BankingContent'
  import swal from 'sweetalert2'
  import {failWs, postWs} from 'src/ws.service'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      SuitAcademy,
      SuitJourney,
      Banking
    },
    data() {
      return {
        key: '',
        password: '',
        confirmPassword: '',
        carousel: {
          timeInterval: null,
          current: 0,
          currentContent: 'SuitAcademy',
          availableOptions: ['SuitAcademy', 'SuitJourney', 'Banking'],
          options: {
            'SuitAcademy': {
              content: 'SuitAcademy',
              fileName: 'SUITACADEMY.webp',
              bg: null
            },
            'SuitJourney': {
              content: 'SuitJourney',
              fileName: 'SUITJOURNEY.webp',
              bg: null
            },
            'Banking': {
              content: 'Banking',
              fileName: 'CADASTRO.webp',
              bg: null
            }
          }
        }
      }
    },
    created() {
      this.key = this.$route.params.key
    },
    beforeMount() {
      this.preloadImages()
    },
    mounted() {
      this.carousel.timeInterval = setInterval(() => {
        this.changeCurrentIndex(1)
      }, 8000)
    },
    methods: {
      recovery() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            postWs("/auth/password-recovery", false, null,
              {hash: this.key, newPassword: this.password},
              this.successRecovery, this.failRecovery)
          }
        })
      },
      successRecovery() {
        swal({
          title: "Senha Alterada",
          text: "Você já pode acessar sua conta com a nova senha.",
          buttonsStyling: true,
          closeOnClickModal: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: "success"
        })
        this.$router.push('/login')
      },
      failRecovery(error) {
        if (error.response.status == 404) {
          swal({
            title: "O prazo expirou!",
            text: "O tempo para recuperação de senha usando este link expirou. Favor re-solicitar a recuperação de senha.",
            buttonsStyling: true,
            closeOnClickModal: false,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: "warning"
          })
          this.$router.push('/forgot')
          return
        }
        failWs(error)
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      onClickChangeContent(value) {
        this.changeCurrentIndex(value)
        clearInterval(this.carousel.timeInterval);
        this.carousel.timeInterval = setInterval(() => this.changeCurrentIndex(1), 8000);
      },
      changeCurrentIndex(value) {
        const length = this.carousel.availableOptions.length
        this.carousel.current += value
        this.carousel.current = Math.abs((this.carousel.current + length) % length)
      },
      changeContent() {
        this.carousel.currentContent = this.carousel.options[this.currentCarouselOptionName].content
      },
      onLoadBackground() {
        this.changeContent()
      },
      preloadImages() {
        const availableOptions = this.carousel.availableOptions
        const options = this.carousel.options

        availableOptions.forEach(optionName => {
          options[optionName].bg = (new Image()).src = '/static/img/login/carousel/background/' + options[optionName].fileName
        })
      }
    },
    computed: {
      currentCarouselOptionName() {
        return this.carousel.availableOptions[this.carousel.current]
      },
      currentBg() {
        return this.carousel.options[this.currentCarouselOptionName].bg
      },
    },
    beforeDestroy() {
      clearInterval(this.carousel.timeInterval)
    }
  }
</script>
<style lang="scss">
  .dzs-recovery {
    min-height: 100vh;
    font-family: ArticulatCF, sans-serif;
    display: flex;
    align-items: center;

    .form-control,
    .input-group-text {
      background-color: #d9d9d9 !important;
      border: none !important;
    }

    .container {
      max-width: inherit !important;
      margin-top: 0 !important;
    }

    .content-selector {
      z-index: 100;
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: fit-content;
      height: fit-content;
      display: flex;
      gap: .5rem;

      button {
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        background: #FFFFFF;
        outline: 0;
        border: none;
        color: #333333;
        cursor: pointer;
      }
    }

    .content {
      padding: 0 !important;
      max-width: 120rem;
      margin: 0 auto;
    }

    .content-wrapper {
      display: flex;
      justify-content: flex-end;
      > div {
        padding-top: 2rem;
        animation: .8s in ease-in-out forwards;
      }
    }

    .content__logo {
      width: 6.075rem;
      margin-bottom: 3.375rem;
    }

    .content__headline {
      color: #FFFFFF;
      font-size: 1.5rem;
      font-weight: bold;
      text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
    }

    .content__text {
      color: #FFFFFF;
      font-size: 1.35rem;
      margin-bottom: 3.375rem;
      text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
    }

    .content__cta {
      width: fit-content;
      min-width: 12rem;
      text-align: center;
      padding: .45rem 1.35rem;
      text-transform: uppercase;
      background: #33CC33;
      font-size: .9rem;
      font-weight: bold;
      color: #FFFFFF;
      border-radius: 2rem;
    }

    .card-recovery {
      background: white;
      border-radius: 2rem !important;
      width: 100%;
      max-width: 31.5rem;
      padding: 2rem 9% 2.25rem 9% !important;
      text-align: center;

      input {
        background: #e2e2e2 !important;
        border-radius: 2rem;
        color: #333333 !important;
        padding: 1.2rem 1.5rem !important;
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    .card-recovery__header {
      color: #333333;
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center;

      img {
        width: 4.5rem;
        margin: 0 auto 1.5rem;
      }
    }

    .card--highlight {
      color: #33CC33;
    }

    .card-recovery__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .card-recovery__button {
      display: block;
      text-transform: capitalize;
      font-family: inherit;
      font-size: 1rem;
      border-radius: 2rem;
      width: 100%;
      padding: .45rem 1.35rem;
    }

    .card-recovery__button--primary {
      background: #33CC33;
      color: #FFFFFF;
      font-weight: bold;
    }

    .card-recovery__button--primary:hover,
    .card-recovery__button--primary:active,
    .card-recovery__button--primary:focus {
      opacity: .90;
      background: #33CC33 !important;
    }

    .full-page-background {
      position: fixed !important;
    }

    @media (min-width: 1400px) {
      .col-lg-6.justify-content-center {
        justify-content: start !important;
      }
      .card-recovery {
        margin: 0% 10% 10% 10%;
      }
    }

    @media (min-width: 992px) {
      .card-recovery {
        float: right;
      }
    }

    @media (max-width: 991px) {
      .content {
        .row {
          margin-left: 0 !important;
          margin-right: 0 !important;
          padding: 0 !important;
        }
      }
      .content-wrapper, .content-selector {
        display: none;
      }
      .div-recovery {
        width: 100%;
      }
      .card-recovery {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .bg-wrapper {
      width: 100vw;
      min-height: 100vh;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }

    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 !important;
    }

    @keyframes in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

</style>
