<template>
  <form class="card card-register-plate" @submit.prevent="onFormSubmit">
    <div class="card-header">
      <h4>
        Cadastrar Placa
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <label for="name">Nome</label>
          <fg-input type="text"
                    id="name"
                    maxlength="30"
                    v-model="plate.name"
                    data-vv-as="Nome"
                    name="name"
                    v-validate="validations.name"
                    :error="getError('name')"
          />
        </div>
        <div class="col-sm-12">
          <label for="revenue">Faturamento</label>
          <fg-input id="revenue"
                    v-money="'money'"
                    v-model="plate.revenue"
                    class="input-revenue"
                    data-vv-as="Faturamento"
                    name="revenue"
                    v-validate="'revenueNotEmpty'"
                    :error="getError('revenue')"
          >
            <template slot="addonLeft">R$</template>
          </fg-input>
        </div>
        <div class="col-sm-12">
          <label for="platePhoto">Imagem da placa</label>
          <label for="platePhoto" class="btn plate-photo-label">
            <i class="fa-regular fa-image"></i> {{ platePhotoFileName }}
          </label>
          <input id="platePhoto" ref="platePhotoIpt" type="file" name="platePhoto" hidden
                 @input="handleFilePlateChange" data-vv-as="Imagem da placa" v-validate="validations.platePhoto"/>
          <fg-input type="file" :error="getError('platePhoto')"></fg-input>
        </div>
        <div class="col-sm-12">
          <label for="quantity">Quantidade em Estoque</label>
          <fg-input type="number"
                    id="quantity"
                    v-model="plate.quantity"
                    data-vv-as="Quantidade"
                    name="quantity"
                    v-validate="validations.quantity"
                    :error="getError('quantity')"
          />
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <p-button type="success" native-type="submit">Salvar</p-button>
    </div>
  </form>
</template>
<script>
import {moneyToFloat} from "@/util/core.utils";
import swal from "sweetalert2";
import {callWs} from "@/ws.service";

export default {
  name: "insert-new-plate-form",
  data() {
    return {
      plate: {
        name: '',
        revenue: '',
        quantity: 0
      },
      fileMaxSize: 5,
      platePhotoFileName: 'Escolha uma imagem para a placa',
      validations: {
        name: {
          required: true
        },
        revenue: {
          required: true,
          min_value: 0.1
        },
        platePhoto: {
          required: true
        },
        quantity: {
          required: true,
          min_value: 0
        }
      }
    }
  },
  created() {
    this.$validator.extend('revenueNotEmpty', {
      getMessage: field => 'O campo ' + field + ' deve ser maior que R$ 0,00',
      validate: (value) => {
        const number = moneyToFloat(value)
        return number > 0
      }
    })
  },
  methods: {
    onFormSubmit() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          const file = this.$refs.platePhotoIpt.files[0]
          if (file.size > this.fileMaxSize * 1024 * 1024) {
            swal({
              title: 'Aviso!',
              text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }

          const formData = new FormData()
          formData.set('platePhoto', file)

          callWs('/bo/suitjourney/register-plate', 'POST', {'Content-Type': 'multipart/form-data'}, true,
            {
              name: this.plate.name,
              revenue: moneyToFloat(this.plate.revenue),
              quantity: this.plate.quantity
            },
            formData,
            this.successSave,
            this.failSave
          )
        }
      })
    },
    successSave() {
      swal({
        title: 'Sucesso!',
        text: 'Placa registrada com sucesso!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
    },
    failSave() {
      swal({
        title: 'Falha!',
        text: 'Falha ao salvar a placa, verifique os dados e tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'error'
      })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    handleFilePlateChange(event) {
      this.platePhotoFileName = event.target.files[0].name
    }
  }
}
</script>

<style lang="scss">
.card-register-plate {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  .form-group .input-group-prepend {
    padding: 0 !important;
  }

  .input-group-text {
    padding: .375rem .75rem !important;
  }

  .input-revenue .form-control {
    padding: 0 !important;
  }

  .plate-photo-label {
    color: #FFFFFF;
    display: block;
    width: fit-content;
    margin-top: 0px;
  }
}
</style>
