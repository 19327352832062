<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Movimentação nos últimos 6 meses  <span v-if="isBackoffice">(<span v-if="salePersonUsername != null" style="font-weight: bolder"> @{{salePersonUsername}}</span> )</span></h4>
        </div>
        <div class="row">
          <div class="col-md-12 row">
            <div class="row is-align-bottom col-md-10">
              <div class="col-md-6">
                <label class="control-label">Buscar por Usuário</label>
                <fg-input
                  class="two-fifths-width"
                  v-model="searchName"
                />
              </div>
              <div class="ml-1 col-md-4" style="margin-top: 12px">
                <p-button type="success" @click.prevent="searchByUsername">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>
              </div>
            </div>
            <div class="" style="margin-top: 12px">
              <p-button type="success" @click.prevent="generateCsv">
                <i slot="label" class="nc-icon nc-cloud-download-93"></i>
                Baixar CSV
              </p-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-2">
            <el-table :data="filteredClients" class="centered-table">
              <el-table-column
                label="Usuário"
                class-name="centered-column">
                <template v-slot="props">
                  <span>@{{props.row.username}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="Nome"
                class-name="centered-column name-column">
              </el-table-column>
              <el-table-column
                :label="monthLabels[0]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes7)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[1]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes6)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[2]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes5)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[3]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes4)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[4]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes3)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[5]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes2)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="monthLabels[6]"
                class-name="centered-column">
                <template v-slot="props">
                  <span>${{toMoney(props.row.mes1)}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.searchByUsername">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getWs} from '@/ws.service.js'
import download from 'downloadjs'
import {isNullOrEmpty, toMoney} from "@/util/core.utils";
import PPagination from "@/components/UIComponents/Pagination.vue";

export default {
  components: {PPagination},
  data() {
    return {
      salesPersonClients: [],
      filteredClients: [],
      searchName: '',
      monthLabels: [],
      salePersonUsername: '',
      isBackoffice: false,
      pagination: {
        perPage: 100,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  mounted() {
    this.loadInfos()
    this.setMonthLabels()
    this.getBackOffice()
  },
  methods: {
    toMoney,
    loadInfos() {
      const salePersonUsername = this.salePersonUsername

      getWs(
        '/bo/commercial/salesperson/clients-last-months-transactions-by-salesperson',
        true,
        {
          salePersonUsername: salePersonUsername,
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        },
        response => {
          this.loadPagination(response)
          this.salesPersonClients = response.data.content
          this.filteredClients = response.data.content
        },
        error => {
          console.error(error)
        }
      )
    },
    getBackOffice(){
      if(isNullOrEmpty(this.$route.params.salePerson)) {
        this.isBackoffice = false
        this.salePersonUsername = localStorage.getItem("usernameDisplay")
      } else {
        this.isBackoffice = true
        this.salePersonUsername = this.$route.params.salePerson
      }
    },
    loadPagination(response){
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.timelineItems.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    setMonthLabels() {
      const monthNames = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
      const currentMonth = new Date().getMonth()
      this.monthLabels = []
      for (let i = 0; i < 7; i++) {
        const monthIndex = (currentMonth - i + 12) % 12
        this.monthLabels.push(monthNames[monthIndex])
      }
    },
    searchByUsername() {
      getWs(
        '/bo/commercial/salesperson/clients-last-months-transactions-by-salesperson',
        true,
        {
          salePersonUsername: this.salePersonUsername,
          clientUsername: this.searchName,
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        },
        response => {
          this.loadPagination(response)
          this.filteredClients = response.data.content
        },
        error => {
          console.error(error)
        }
      )
    },
    generateCsv() {
      const headers = [
        'Username',
        'Nome',
        'Email',
        ...this.monthLabels
      ];
      const rows = this.filteredClients.map(client => [
        client.username,
        client.name,
        client.email,
        client.mes7 || '',
        client.mes6 || '',
        client.mes5 || '',
        client.mes4 || '',
        client.mes3 || '',
        client.mes2 || '',
        client.mes1 || ''
      ]);

      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');

      download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent), 'clients.csv', 'text/csv');
    }
  }
}
</script>
