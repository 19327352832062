<template>
  <div class="dzs-btc">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Wallet
          </h4>
          <div class="row">
            <div class="col-md-6" v-if="!isNullOrEmpty(qrCode) || viewWallet">
              <card>
                <p style="color: #667eea">Olá {{usernameDisplay}}!</p>
                <p>A <b style="color: black">Bank<span style="color: #667eea">4</span>u</b> oferta a você uma cateira digital para que possa transacionar seus criptoativos de forma segura e confiável.</p>
                <p>Após ter criado a sua conta e validado, foi gerado automaticamente uma Wallet vinculada ao seu usuário. Para acesso e ativação da Wallet, siga os passos informados.</p>
                <p>Informações importantes:</p>
                <ul>
                  <p><i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Sua carteira é única para todas as transações. Portanto sempre confira a sua carteira antes de efetuar uma operação.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Após a ativação aparecerá duas opções: Receber e Enviar.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Ao clicar em receber será disponibilizado o QrCode e o código da sua carteira, envie uma das opções para receber seus ativos.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Ao clicar em enviar, será disponibilizado um campo para que seja informado o código da carteira para qual deseja enviar os ativos. Você deverá escolher o valor a ser enviado em Bitcoins ou em Reais, basta preencher uma das opções.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Verifique atenciosamente as informações de destino antes de realizar qualquer tipo de transação. Uma vez que o ativo é enviado, não haverá possibilidade de estornar os valores.</p>
                </ul>
                <p>Em breve estaremos disponibilizando o menu de Exchange para que possa realizar as suas operações de compra e venda dentro da plataforma.</p>
              </card>
            </div>
            <div class="col-md-6" v-if="viewWallet">
              <card style="text-align: center">
                <h5>A sua carteira foi aberta em uma nova aba!</h5>
                <p-button @click="()=>{backView()}"
                          class="btn btn-primary" style="margin: 0 auto; margin-top: 14px">
                  <i class="fas fa-undo"/> Voltar
                </p-button>
              </card>
            </div>
            <div class="col-md-6" v-if="approvedAccount == 'false'">
              <card style="text-align: center">
                <h5>Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no botão abaixo:</h5>
                <p-button @click="$router.push('/user/profile')" href="#"
                          class="btn btn-primary" style="margin: 0 auto; margin-top: 14px">
                  <i class="fas fa-cogs"/> Verificar Conta
                </p-button>
              </card>
            </div>
            <div class="col-md-6" v-if="!isNullOrEmpty(qrCode) && approvedAccount == 'true'">
              <div class="row" v-if="isNullOrEmpty(totpQrCodeUri)">
                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Baixe e instale o aplicativo <span
                    style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-md-6">
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                         target="_blank">
                        <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                      </a>
                    </div>
                    <div class="col-md-6">
                      <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                        <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                      </a>
                    </div>
                  </div>
                  <p><b>2</b> - No aplicativo, leia o QR Code ao lado ou informe a chave manualmente</p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo</p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <qrcode :value="totpQrCodeUri" :options="{ width: 200, margin:0 }"></qrcode>
                  <p><b>{{this.totpManualCode}}</b></p>
                </div>
                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span
                    style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <p><b>2</b> - Procure por Bank<b style="color: #667eea !important;">4</b>u e seu nome de usuário no
                    aplicativo </p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center">
                  Código: <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode"
                                 size="6"/>
                </div>
              </div>
              <div class="row" v-if="!codeValid">
                <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
              </div>
              <div class="row">
                <p-button @click="()=>{validateCode()}"
                          class="btn btn-primary" style="margin: 0 auto; margin-top: 14px"
                          :disabled="totpConfirmBtnDisabled"
                >
                  <i class="fas fa-chevron-right"/> Acessar Carteira
                </p-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {postWs} from "../../../../ws.service";
  import {isNullOrEmpty} from "src/util/core.utils";
  import swal from 'sweetalert2'

  export default {
    components: {},
    data() {
      return {
        validationCode: '',
        totpQrCodeUri: '',
        totpManualCode: '',
        totpConfirmBtnDisabled: false,
        qrCode: '',
        codeValid: true,
        viewWallet: false,
        tokenWallet: '',
        urlWallet: '',
        usernameDisplay: '',
        approvedAccount: false,
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.approvedAccount = localStorage.getItem("approvedAccount")
      this.getWalletAccessCode()
    },
    methods: {
      isNullOrEmpty,
      getWalletAccessCode() {
        postWs("/bitcoin/new-wallet-access-code",
          true, null, null,
          (response) => {
            this.qrCode = response.data.qrCode
            this.totpQrCodeUri = response.data.totpQrCodeUri
            this.totpManualCode = response.data.totpManualCode
            return
          }, (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao acessar a sua conta de Bitcoin. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        )
      },
      validateCode() {
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.successConfirmCode,
          this.failConfirmCode
        )
      },
      successConfirmCode() {
        this.totpConfirmBtnDisabled = false
        this.getTokenWallet()
      },
      failConfirmCode() {
        this.totpConfirmBtnDisabled = false
        this.validationCode = ''
        swal({
          title: 'Erro!',
          text: 'Erro ao acessar a sua conta de Bitcoin. Tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.getWalletAccessCode()
      },
      getTokenWallet(){
        postWs("/bitcoin/get-auth-token-bewallet",
          true, null, null,
          (response) => {
            this.success(response.data)
          },
          (error) => {
            this.validationCode = ''
            swal({
              title: 'Erro!',
              text: 'Erro ao acessar a sua conta de Bitcoin. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.getWalletAccessCode()
          }
        )
      },
      success(tokenWallet){
        this.viewWallet = true
        this.tokenWallet = tokenWallet
        this.urlWallet = 'https://web.bewallet.app/login?token='+tokenWallet
        this.qrCode = ''
        this.openWindow(this.urlWallet)
        return
      },
      backView(){
        this.viewWallet = false
        this.tokenWallet = ''
        this.validationCode = ''
        this.getWalletAccessCode()
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .dzs-btc {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }
  }

</style>
