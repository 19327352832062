<template>
   <div class="suit-academy-content">
    <img class="content__logo" src="/static/img/login/carousel/logos/SUIT_ACADEMY.png" />
    <h2 class="content__headline">Potencialize os lucros do seu negócio por <br> menos de <span class="card--highlight">R$ 0,33 centavos por dia!</span></h2>
    <p class="content__text">Uma plataforma educacional completa que <br>pode transformar os resultados do seu
      <br> negócio digital em pouquíssimo tempo. </p>
    <div class="content__cta">
      <span>EM BREVE</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "suit-academy-content"
}
</script>
<style scoped lang="scss">
.suit-academy-content {
  margin: 0% 10% 10% 10%;
}
</style>
