<template>
  <div class="dzs-card row">
    <div class="col-lg-12 col-md-7">
      <div class="row" v-show="formRequestShow">
        <div class="col-lg-12 col-md-7">
          <card>
            <h4 slot="header" class="card-title">
              Solicitar Máquina de Cartão
            </h4>
            <div class="row">
              <div class="card-body">
                <form @submit.prevent="requestCard">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6">
                          <fg-input label="Faturamento Mensal">
                            <el-select size="large"
                                       v-on:change="updatePlan"
                                       v-model="formRequest.billingRange">
                              <el-option class="select-info"
                                         value="RANGE1"
                                         label="Até R$50 mil"/>
                              <el-option class="select-info"
                                         value="RANGE2"
                                         label="De R$50 mil a R$100 mil"/>
                              <el-option class="select-info"
                                         value="RANGE3"
                                         label="De R$100 mil a R$500 mil"/>
                              <el-option class="select-info"
                                         value="RANGE4"
                                         label="Acima de R$500 mil"/>
                            </el-select>
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input label="Quantidade de Máquinas">
                            <el-select size="large"
                                       v-model="formRequest.amountCardTerminal">
                              <el-option class="select-info"
                                         value="1"
                                         label="1"/>
                              <el-option class="select-info"
                                         value="2"
                                         label="2"/>
                              <el-option class="select-info"
                                         value="3"
                                         label="3"/>
                              <el-option class="select-info"
                                         value="4"
                                         label="4"/>
                              <el-option class="select-info"
                                         value="5"
                                         label="5"/>
                              <el-option class="select-info"
                                         value="6"
                                         label="6"/>
                              <el-option class="select-info"
                                         value="7"
                                         label="7"/>
                              <el-option class="select-info"
                                         value="8"
                                         label="8"/>
                              <el-option class="select-info"
                                         value="9"
                                         label="9"/>
                              <el-option class="select-info"
                                         value="10"
                                         label="10"/>
                            </el-select>
                          </fg-input>
                        </div>
                        <div class="col-md-12" style="padding-top: 20px">
                          <div class="card box2" style="text-align: center" v-show="this.viewFee">
                            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px; text-align: center"><b>Taxas</b></p>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-top: -16px; margin-left: initial; margin-right: initial;">
                              <div class="col-md-12 col-12" style="padding-top: 15px"><p>Débito: <b>{{toMoney(activePlan.debito)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 1x: <b>{{toMoney(activePlan.credito)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 2x: <b>{{toMoney(activePlan.credito2x)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 3x: <b>{{toMoney(activePlan.credito3x)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 4x: <b>{{toMoney(activePlan.credito4x)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 5x: <b>{{toMoney(activePlan.credito5x)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 6x: <b>{{toMoney(activePlan.credito6x)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 7x: <b>{{toMoney(activePlan.credito7x)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 8x: <b>{{toMoney(activePlan.credito8x)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 9x: <b>{{toMoney(activePlan.credito9x)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 10x: <b>{{toMoney(activePlan.credito10x)}} %</b></p></div>
                            </div>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                              <div class="col-md-6 col-12 topics"><p>Crédito 11x: <b>{{toMoney(activePlan.credito11x)}} %</b></p></div>
                              <div class="col-md-1 vl"></div>
                              <div class="col-md-5 col-12 topics-text"><p>Crédito 12x: <b>{{toMoney(activePlan.credito12x)}} %</b></p></div>
                            </div>
                          </div>
                          <div class="card box2" style="text-align: center" v-show="!this.viewFee">
                            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px; text-align: center"><b>Taxas</b></p>
                            <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-top: -16px; margin-left: initial; margin-right: initial;">
                              <div class="col-md-12 col-12" style="height: 350px; padding: 15% 15% 0 15%;font-size: 20px;">
                                <p><b>Após a finalização da sua solicitação, nossos consultores entrarão em contato com as melhores taxas.</b></p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="text-align: center">
                      <card type="plain" class="card-subcategories">
                        <button type="button" :class="{'btn-tab-active':formRequest.nameModelCardTerminal == 'S920'}" class="btn btn-info btn-fill btn-wd btn-tab" @click="formRequest.nameModelCardTerminal = 'S920'" >
                          S920 / Q92
                        </button>
                        <button type="button" :class="{'btn-tab-active':formRequest.nameModelCardTerminal == 'D175'}" class="btn btn-info btn-fill btn-wd btn-tab" @click="formRequest.nameModelCardTerminal = 'D175'">
                          D175
                        </button><br/>
                        <img src="/static/img/cardterminal/S920.png" alt="..." v-if="formRequest.nameModelCardTerminal == 'S920'" style="height: 450px">
                        <img src="/static/img/cardterminal/D175.png" alt="..." v-if="formRequest.nameModelCardTerminal == 'D175'" style="height: 450px">
                      </card>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <fg-input type="text"
                                label="Usuário"
                                disabled="true"
                                v-model="usernameDisplay">
                      </fg-input>
                    </div>
                    <div class="col-md-7">
                      <fg-input type="email"
                                label="E-mail"
                                disabled="true"
                                v-model="email">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <fg-input type="tel"
                                label="Celular"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                v-model="account.phoneNumber1"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-6">
                      <fg-input type="tel"
                                label="Telefone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                v-model="account.phoneNumber2"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="Nome Completo"
                                v-model="account.name"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 0px">
                    <div class="col-md-5">
                      <fg-input label="Tipo de Pessoa"
                                type="text"
                                v-model="account.personType==='NATURAL_PERSON' || account.personType==='NATURAL_PERSON_E' ?'Pessoa Física':'Pessoa Jurídica'"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-7">
                      <fg-input :label="account.personType==='NATURAL_PERSON' || account.personType==='NATURAL_PERSON_E' ?'CPF':'CNPJ'"
                                type="tel"
                                v-mask="account.personType==='NATURAL_PERSON' || account.personType==='NATURAL_PERSON_E' ?['###.###.###-##']:['##.###.###/####-##']"
                                name="CPF/CNPJ"
                                v-model="account.socialNumber"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <div v-if="account.personType==='LEGAL_PERSON'">
                    <div class="row">
                      <div class="col-md-12">
                        <fg-input type="text"
                                  label="Razão Social"
                                  v-model="account.legalPerson.companyName"
                                  disabled="true">
                        </fg-input>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 30px" >
                    <div class="col-md-5">
                      <fg-input type="tel"
                                v-mask="['#####-###']"
                                label="CEP"
                                v-model="account.address.zipCode"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-7">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <fg-input type="text"
                                label="Rua/Av."
                                v-model="account.address.street"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-4">
                      <fg-input type="tel"
                                v-mask="['######']"
                                label="Número"
                                v-model="account.address.number"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="Complemento"
                                v-model="account.address.complement"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <fg-input type="text"
                                label="Bairro"
                                v-model="account.address.neighborhood"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-5">
                      <fg-input type="text"
                                label="Cidade"
                                v-model="account.address.city"
                                disabled="true">
                      </fg-input>
                    </div>
                    <div class="col-md-2">
                      <fg-input type="text"
                                label="Estado"
                                v-model="account.address.state"
                                disabled="true">
                      </fg-input>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-12">
                      <p-checkbox class="text-left" v-model="formRequest.mainAddressSameShippingAddress">
                        <b>Utilizar o mesmo endereço como endereço de entrega.</b>
                      </p-checkbox>
                    </div>
                  </div>
                  <div v-show="!formRequest.mainAddressSameShippingAddress" style="margin-top: 30px">
                    <h4 slot="header" class="card-title">
                      Endereço de Entrega
                    </h4>
                    <div class="row">
                      <div class="col-md-5">
                        <fg-input type="tel"
                                  v-mask="['#####-###']"
                                  label="CEP"
                                  name="CEP"
                                  v-validate="{required: !formRequest.mainAddressSameShippingAddress, min: 9}"
                                  :error="getError('CEP')"
                                  v-on:blur="this.searchZipCode"
                                  v-model="formRequest.deliveryAddress.zipCode"
                                  key="cep">
                        </fg-input>
                      </div>
                      <div class="col-md-7">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <fg-input type="text"
                                  maxlength="40"
                                  :disabled="(viacep && viacep.logradouro  != '')"
                                  label="Rua/Av."
                                  name="Rua/Av."
                                  v-validate="{required: !formRequest.mainAddressSameShippingAddress}"
                                  :error="getError('Rua/Av.')"
                                  v-model="formRequest.deliveryAddress.street"
                                  key="rua">
                        </fg-input>
                      </div>
                      <div class="col-md-4">
                        <fg-input type="tel"
                                  v-mask="['######']"
                                  label="Número"
                                  name="Número"
                                  v-validate="{required: !formRequest.mainAddressSameShippingAddress}"
                                  :error="getError('Número')"
                                  v-model="formRequest.deliveryAddress.number"
                                  key="numero">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <fg-input type="text"
                                  maxlength="40"
                                  label="Complemento"
                                  v-model="formRequest.deliveryAddress.complement"
                                  key="complemento">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <fg-input type="text"
                                  maxlength="40"
                                  :disabled="(viacep && viacep.bairro != '')"
                                  label="Bairro"
                                  name="Bairro"
                                  v-validate="{required: !formRequest.mainAddressSameShippingAddress}"
                                  :error="getError('Bairro')"
                                  v-model="formRequest.deliveryAddress.neighborhood"
                                  key="bairro">
                        </fg-input>
                      </div>
                      <div class="col-md-5">
                        <fg-input type="text"
                                  label="Cidade"
                                  disabled="true"
                                  v-model="formRequest.deliveryAddress.city"
                                  key="cidade">
                        </fg-input>
                      </div>
                      <div class="col-md-2">
                        <fg-input type="text"
                                  label="Estado"
                                  disabled="true"
                                  placeholder=""
                                  v-model="formRequest.deliveryAddress.state"
                                  key="estado">
                        </fg-input>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-8">
                      <p-checkbox class="text-left" v-model="confirmButton">
                        <b>Confirmo que todos os dados acima estão corretos.</b>
                      </p-checkbox>
                    </div>
                  </div>
                  <br>
                  <div class="text-center">
                    <button type="submit" class="btn btn-info btn-fill btn-wd" :disabled="buttonDisabled">
                      Solicitar Máquina
                    </button>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row" v-show="formApprovalShow">
        <div class="col-lg-6">
          <card align="center" style="height: 100%; padding: 5%">
            <h4 slot="header" class="card-title">
              Aguardando Máquinas!
            </h4>
            <span style="font-size: 15px;">Parabéns, você já realizou a solicitação da sua máquina de cartão <b style="color: #000000 !important;">SuitPay</b>,
                  em breve um de nossos consultores irá entrar em contato com mais informações para finalizar o seu pedido.<br><br>
                  Para consultar o status do seu pedido <a @click="$router.push('/card-terminal/consultplan')" href="#">clique aqui</a>.<br><br>
                  Obrigado por escolher a <b style="color: #000000 !important;">SuitPay</b></span><br><br>
            <span style="font-size: 15px;">Lembrando que se se ainda não baixou nosso app, ele já está disponivel em todas as plataformas.
          Basta clicar nos botões de acesso abaixo para ter toda sua plataforma na palma da sua mão.</span><br><br>
            <div class="row" style="padding: 5% 10% 5% 10%">
              <div class="col-md-6">
                <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                  <img src="/static/img/mobile/apple_store.png" alt="..."  >

                </a>
              </div>
              <div class="col-md-6">
                <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                  <img src="/static/img/mobile/google_play.png" alt="..."  >
                </a>
              </div>
            </div>
          </card>
        </div>

        <div class="col-lg-6">
          <card align="center" style="background-color: #00d19a; height: 100%; padding: 5%">
            <img style="max-width: 70%" src="/static/img/cardterminal/S920.png" alt="...">
          </card>
        </div>
      </div>

      <div class="row" v-show="formInactiveUserShow">
        <div class="col-lg-6">
          <card align="center" style="height: 100%; padding: 5%">
            <h4 slot="header" class="card-title">
              Conta em Análise!
            </h4>
            <span style="font-size: 15px;">A sua conta está em análise de documentos ou ainda não foi finalizado o seu cadastro, <a @click="$router.push('/user/profile')" href="#">clique
                  aqui</a> para verificar os seus dados cadastrais ou entre em contato com o suporte através no nosso chat.<br><br>
                  Após a aprovação da conta, será permitido a solicitação da sua máquina de cartão.</p><br>
                  Obrigado por escolher o <b style="color: #000000 !important;">SuitPay</b></span><br><br>
            <span style="font-size: 15px;">Lembrando que se se ainda não baixou nosso app, ele já está disponivel em todas as plataformas.
          Basta clicar nos botões de acesso abaixo para ter toda sua plataforma na palma da sua mão.</span><br><br>
            <div class="row" style="padding: 5% 10% 5% 10%">
              <div class="col-md-6">
                <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                  <img src="/static/img/mobile/apple_store.png" alt="..."  >

                </a>
              </div>
              <div class="col-md-6">
                <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                  <img src="/static/img/mobile/google_play.png" alt="..."  >
                </a>
              </div>
            </div>
          </card>
        </div>

        <div class="col-lg-6">
          <card align="center" style="background-color: #00d19a; height: 100%; padding: 5%">
            <img style="max-width: 70%" src="/static/img/cardterminal/S920.png" alt="...">
          </card>
        </div>
      </div>

      <div class="row" v-show="formPlanActive">
        <div class="col-lg-6">
          <card align="center" style="height: 100%; padding: 5%">
            <h4 slot="header" class="card-title">
              Máquina de Cartão Ativa!
            </h4>
            <span style="font-size: 15px;">Parabéns, você já está usando a sua máquina de cartão <b style="color: #000000 !important;">SuitPay</b></span><br><br>
            <span style="font-size: 15px;">Para dúvidas, entre em contato com o nosso suporte através do chat localizado no canto inferior da página.<br><br>
                  Para consultar as suas taxas <a @click="$router.push('/card-terminal/consultplan')" href="#">clique aqui</a>.<br><br>
                  Obrigado por escolher a <b style="color: #000000 !important;">SuitPay</b></span><br><br>
            <span style="font-size: 15px;">Lembrando que se se ainda não baixou nosso app, ele já está disponivel em todas as plataformas.
          Basta clicar nos botões de acesso abaixo para ter toda sua plataforma na palma da sua mão.</span><br><br>
            <div class="row" style="padding: 5% 10% 5% 10%">
              <div class="col-md-6">
                <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                  <img src="/static/img/mobile/apple_store.png" alt="..."  >

                </a>
              </div>
              <div class="col-md-6">
                <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                  <img src="/static/img/mobile/google_play.png" alt="..."  >
                </a>
              </div>
            </div>
          </card>
        </div>

        <div class="col-lg-6">
          <card align="center" style="background-color: #00d19a; height: 100%; padding: 5%">
            <img style="max-width: 70%" src="/static/img/cardterminal/S920.png" alt="...">
          </card>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import swal from 'sweetalert2'
  import {postWs, failWs, getWs} from 'src/ws.service'
  import {isNullOrEmpty, moneyToFloat, toMoney} from 'src/util/core.utils'
  import {Collapse, CollapseItem, Tabs, TabPane, Card} from 'src/components/UIComponents'


  export default {
    components: {
      TabPane,
      Tabs,
      CollapseItem,
      Collapse,
      Card
    },
    data() {
      return {
        formRequestShow: false,
        formApprovalShow: false,
        formInactiveUserShow: false,
        formPlanActive: false,
        confirmButton: false,
        usernameDisplay: '',
        email: '',
        viacep: {
          logradouro: '',
          bairro: '',
          ibge: '',
          localidade: '',
          uf: '',
        },
        buttonDisabled: false,
        account: [],
        activePlan: {
          id: '',
          planName: '',
          debito: 0,
          credito: 0,
          credito2x: 0,
          credito3x: 0,
          credito4x: 0,
          credito5x: 0,
          credito6x: 0,
          credito7x: 0,
          credito8x: 0,
          credito9x: 0,
          credito10x: 0,
          credito11x: 0,
          credito12x: 0,
          viewOnScreen: false,
          planBannerCard: false,
          enabled: false,
          minimumBilling: 0,
          maximumBilling: 0,
        },
        planRange1: '',
        planRange2: '',
        planRange3: '',
        viewFee: true,
        username: '',
        formRequest: {
          idCardTerminalPlan: '',
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          shipmentValue: 0,
          cardTerminalPlanLabel: '',
          shipmentStatus: null,
          itemsValue: 0,
          billingRange: 'RANGE1',
          nameModelCardTerminal: 'S920',
          amountCardTerminal: 1,
          mainAddressSameShippingAddress: true
        }
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.account.name = localStorage.getItem("name")
      this.email = localStorage.getItem("email")

      postWs("/acc/get-account", true, null, null,
        this.loadAccount, function () {
        });

      getWs("/card-terminal/list-card-terminal-plans-view",
        true,
        null,
        this.loadPlans,
        function (error) {
        })
    },
    methods: {
      toMoney,
      tabClicked (selectedTab) {
        this.formRequest.nameModelCardTerminal = selectedTab.tab.key;
      },
      loadPlans(response){
        this.planRange1 = response.data.range1
        this.planRange2 = response.data.range2
        this.planRange3 = response.data.range3
        if(this.formRequest.billingRange == "RANGE1") {
          this.activePlan = response.data.range1
        }
        if(this.formRequest.billingRange == "RANGE2") {
          this.activePlan = response.data.range2
        }
        if(this.formRequest.billingRange == "RANGE4") {
          this.activePlan = response.data.range3
        }
      },
      updatePlan(){
        if(this.formRequest.billingRange == 'RANGE1') {
          this.activePlan = this.planRange1
          this.viewFee = true
        }
        if(this.formRequest.billingRange == 'RANGE2') {
          this.activePlan = this.planRange2
          this.viewFee = true
        }
        if(this.formRequest.billingRange == 'RANGE3') {
          this.activePlan = this.planRange3
          this.viewFee = true
        }
        if(this.formRequest.billingRange == 'RANGE4') {
          this.viewFee = false
        }
      },
      loadForms(response){
        if(response.status == 200){
          if(response.data == 'WAITING_APPROVAL' || response.data == 'APPROVED' || response.data == 'PREPARING_SHIPMENT'
            || response.data == 'SHIPPED' || response.data == 'PARTIAL_SHIPPING') {
            this.formRequestShow = false
            this.formApprovalShow = true
          } else if(response.data == 'ACTIVE'){
            this.formRequestShow = false
            this.formPlanActive = true
          } else {
            this.formRequestShow = true
            this.formApprovalShow = false
          }
        }else if(response.response.status == 404){
          this.formRequestShow = true
          this.formApprovalShow = false
        }else{
          failWs()
        }
        return true;
      },
      loadAccount(response) {
        this.account = response.data
        this.username = localStorage.getItem("usernameDisplay")
        localStorage.setItem('name', this.account.name)
        if (this.account.accountDocumentStatus != 'APPROVED') {
          this.formRequestShow = false
          this.formApprovalShow = false
          this.formInactiveUserShow = true
        } else {
          postWs("/card-terminal/get-latest-card-terminal-request-status", true, {username: localStorage.getItem("usernameDisplay")}, null,
            this.loadForms, this.loadForms)
        }
        if (!isNullOrEmpty(this.formRequest.deliveryAddress.codIbge)) {
          this.viacep.logradouro = this.formRequest.deliveryAddress.street
          this.viacep.bairro = this.formRequest.deliveryAddress.neighborhood
        }
      },
      searchZipCode() {
        this.viacep = null
        this.formRequest.deliveryAddress.codIbge = ""
        this.formRequest.deliveryAddress.city = ""
        this.formRequest.deliveryAddress.state = ""
        getWs("/cep/get-cep",
          true, {cep: this.formRequest.deliveryAddress.zipCode},
          (response) => {
            this.viacep = response.data
            this.formRequest.deliveryAddress.codIbge = this.viacep.ibge
            if (this.viacep.logradouro.length > 40) {
              this.formRequest.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
            } else {
              this.formRequest.deliveryAddress.street = this.viacep.logradouro
            }
            if(this.viacep.bairro.length > 40) {
              this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
            } else {
              this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro
            }
            this.formRequest.deliveryAddress.city = this.viacep.localidade
            this.formRequest.deliveryAddress.state = this.viacep.uf
          },
          (e) => {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
            return
          })
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      requestCard(){
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          if (!this.confirmButton) {
            swal({
              title: "Aviso",
              text: 'Você deve confirmar que todos os dados estão corretos antes de continuar.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          this.buttonDisabled = true
          this.formRequest.idCardTerminalPlan = this.activePlan.id
          postWs("/card-terminal/request", true, null, this.formRequest,
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Solicitação realizada com sucesso. Nossos consultores vão analisar a sua solicitação e entrar em contato para finalizar o pedido.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.formApprovalShow = true
              this.formRequestShow = false
            }, (error) => {
              this.buttonDisabled = false
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Erro ao solicitar máquina de cartão, já existe uma solicitação em análise.',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Falha!',
                text: 'Falha ao salvar solicitação, verifique os dados e tente novamente.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
    }
  }
</script>
<style scoped lang="scss">
  .dzs-card {
    .el-input__inner[disabled] {
      background-color: #a8a8a8 !important;
    }

    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }
    ::placeholder {
      color: #000000;
    }

    .input {
      background-color: #FFFFFF;
    }

    .input:hover {
      color: #000000;
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
    }

    .topics-number {
      background: #17a2b8;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6em;
      margin-right: 5px;
      text-align: center;
      width: 1.6em;
    }

    .vl {
      border-left: 2px solid rgb(221, 221, 221);
    }

    .topics {
      padding: 15px 0 0 6%;
    }

    .topics-text {
      margin-top: 15px;
      margin-left: -27px;
    }

    .tab-content.tab-space {
      padding-bottom: 0px !important;
    }

    .btn-tab {
      background: #FFF !important;;
      color: #00d19a !important;;
      border: 2px solid #00d19a !important;;
    }

    .btn-tab:hover {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab:focus {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab:active {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab:active:focus {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab-active {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    @media (max-width: 768px) {
      .box2 {
        width: 90%;
      }
      .vl {
        display: none;
      }
      .topics {
        padding: 15px 0 0 0;
        text-align: center;
      }
      .topics-text {
        margin-top: 15px;
        text-align: center;
        margin-left: 0px;
      }
    }
  }
</style>
