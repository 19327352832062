<template>
  <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="http://www.suitpay.app">
        <img src="/static/img/vue-logo-2.png" width="150px"/>
      </a>
    </div>
    <template slot="navbar-menu">
      <router-link to="/" class="nav-item" tag="li" v-if="showDashboardBtn()">
        <a class="nav-link">
          <i class="nc-icon nc-layout-11"></i> Inicio
        </a>
      </router-link>
      <router-link to="/register" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-book-bookmark"></i> Cadastrar
        </a>
      </router-link>
      <router-link to="/login" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-tap-01"></i> Entrar
        </a>
      </router-link>
    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'

  export default {
    name: 'main-navbar',
    components: {
      Navbar
    },
    data() {
      return {
        showMenu: false
      }
    },
    methods: {
      showDashboardBtn() {
        let path = this.$router.currentRoute.path;
        return !path.includes('login') && !path.includes('register') && !path.includes('forgot');
      }
    }
  }
</script>

<style scoped>

</style>
