<template>
  <div class="suit-journey-content">
    <img class="content__logo" src="/static/img/login/carousel/logos/SUIT_JOURNEY.png" />
    <h2 class="content__headline">A jornada para seu <br> faturamento fora da realidade <br> está prestes a começar.</h2>
    <p class="content__text">Está chegando a hora de desbloquear níveis <br> de <b>faturamento inimagináveis</b> e receber
      <br> premiações jamais vistas antes.</p>
    <div class="content__cta">
      <span>EM BREVE NA SUITPAY</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "suit-journey-content"
}
</script>
<style scoped lang="scss">
.suit-journey-content {
  margin: 0% 10% 10% 10%;
}
</style>
