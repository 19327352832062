<template>
  <div class="login-page dzs-forgot">
    <div class="content-selector">
      <button @click="onClickChangeContent(-1)">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <button @click="onClickChangeContent(1)">
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
    <div class="full-page login-page section-image sb-login-page">
      <div class="content">
        <div class="container row">
          <div class="col-lg-6 content-wrapper">
            <component :is="carousel.currentContent"></component>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="card-forgot">
              <div class="card-forgot__header">
                <img src="/static/img/login/SuitPayVerde.svg"/>
                <div>
                  <span>Esqueceu sua senha?</span><br>
                  <span>Informe seu usuário e vamos lhe ajudar!</span>
                </div>
              </div>
              <form class="div-forgot">
                <fg-input placeholder="Usuário ou E-mail" data-vv-as="Usuário"
                          name="username" v-model="form.username"
                          v-validate="validations.username"
                          :error="getError('username')"></fg-input>
                <div class="card-forgot__buttons">
                  <p-button native-type="submit" slot="footer" name="submit"
                            round block class="card-forgot__button card-forgot__button--primary"
                            :disabled="!this.showSubmit"
                            @click.prevent="recover">Recuperar Senha
                  </p-button>
                  <p><a href="/login" style="font-size: 1rem; text-align: center;" class="card--highlight">Fazer Login</a></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" @load="onLoadBackground" :src="currentBg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {Button, Card, Checkbox} from 'src/components/UIComponents'
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import SuitAcademy from '@/components/UIComponents/Carousel/SuitAcademyContent'
import SuitJourney from '@/components/UIComponents/Carousel/SuitJourneyContent'
import Banking from '@/components/UIComponents/Carousel/BankingContent'
import swal from 'sweetalert2'
import {failWs, postWs} from 'src/ws.service'
import {VueReCaptcha} from 'vue-recaptcha-v3'

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    VueReCaptcha,
    SuitAcademy,
    SuitJourney,
    Banking
  },
  data() {
    return {
      showSubmit: true,
      form: {
        username: ''
      },
      validations: {
        username: {
          required: true,
          min: 3,
          ok: false
        }
      },
      carousel: {
        timeInterval: null,
        current: 0,
        currentContent: 'SuitAcademy',
        availableOptions: ['SuitAcademy', 'SuitJourney', 'Banking'],
        options: {
          'SuitAcademy': {
            content: 'SuitAcademy',
            fileName: 'SUITACADEMY.webp',
            bg: null
          },
          'SuitJourney': {
            content: 'SuitJourney',
            fileName: 'SUITJOURNEY.webp',
            bg: null
          },
          'Banking': {
            content: 'Banking',
            fileName: 'CADASTRO.webp',
            bg: null
          }
        }
      }
    }
  },
  beforeMount() {
    this.preloadImages()
  },
  mounted() {
    this.recaptcha()
    this.carousel.timeInterval = setInterval(() => {
      this.changeCurrentIndex(1)
    }, 8000)
  },
  methods: {
    recover() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.showSubmit = false
          this.doRecaptcha()
        }
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    async doRecaptcha() {
      const token = await this.$recaptcha('forgot')
      this.onCaptchaVerified(token)
    },
    onCaptchaVerified(recaptchaToken) {
      postWs("/auth/forgot-password", false, null, {key: this.form.username, recaptchaToken: recaptchaToken},
        this.successRecover, this.failRecover)
    },
    successRecover() {
      swal({
        title: "Enviado",
        text: "Foi enviado para seu e-mail o procedimento para recuperação de senha. Obrigado",
        buttonsStyling: true,
        closeOnClickModal: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: "success"
      })
      this.$router.push('/login')
    },
    failRecover(error) {
      if (error.response.status == 404) {
        this.successRecover()
      } else {
        failWs(error)
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    onClickChangeContent(value) {
      this.changeCurrentIndex(value)
      clearInterval(this.carousel.timeInterval);
      this.carousel.timeInterval = setInterval(() => this.changeCurrentIndex(1), 8000);
    },
    changeCurrentIndex(value) {
      const length = this.carousel.availableOptions.length
      this.carousel.current += value
      this.carousel.current = Math.abs((this.carousel.current + length) % length)
    },
    changeContent() {
      this.carousel.currentContent = this.carousel.options[this.currentCarouselOptionName].content
    },
    onLoadBackground() {
      this.changeContent()
    },
    preloadImages() {
      const availableOptions = this.carousel.availableOptions
      const options = this.carousel.options

      availableOptions.forEach(optionName => {
        options[optionName].bg = (new Image()).src = '/static/img/login/carousel/background/' + options[optionName].fileName
      })
    }
  },
  computed: {
    currentCarouselOptionName() {
      return this.carousel.availableOptions[this.carousel.current]
    },
    currentBg() {
      return this.carousel.options[this.currentCarouselOptionName].bg
    },
  },
  beforeDestroy() {
    clearInterval(this.carousel.timeInterval)
  }
}
</script>
<style lang="scss">
.dzs-forgot {
  min-height: 100vh;
  font-family: ArticulatCF, sans-serif;
  display: flex;
  align-items: center;

  .form-control,
  .input-group-text {
    background-color: #FFFFFF !important;
    border: none !important;
  }

  .container {
    max-width: inherit !important;
    margin-top: 0 !important;
  }

  .content-selector {
    z-index: 100;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: fit-content;
    height: fit-content;
    display: flex;
    gap: .5rem;

    button {
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      background: #FFFFFF;
      outline: 0;
      border: none;
      color: #333333;
      cursor: pointer;
    }
  }

  .content {
    padding: 0 !important;
    max-width: 120rem;
    margin: 0 auto;
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-end;
    > div {
      padding-top: 2rem;
      animation: .8s in ease-in-out forwards;
    }
  }

  .content__logo {
    width: 6.075rem;
    margin-bottom: 3.375rem;
  }

  .content__headline {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .content__text {
    color: #FFFFFF;
    font-size: 1.35rem;
    margin-bottom: 3.375rem;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .content__cta {
    width: fit-content;
    min-width: 12rem;
    text-align: center;
    padding: .45rem 1.35rem;
    text-transform: uppercase;
    background: #33CC33;
    font-size: .9rem;
    font-weight: bold;
    color: #FFFFFF;
    border-radius: 2rem;
  }

  .card-forgot {
    background: white;
    border-radius: 2rem !important;
    width: 100%;
    max-width: 31.5rem;
    padding: 2rem 9% 2.25rem 9% !important;
    text-align: center;

    input {
      width: 100%;
      background: #e2e2e2 !important;
      border-radius: 2rem;
      color: #333333 !important;
      padding: 1.2rem 1.5rem !important;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .card-forgot__header {
    color: #333333;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;

    img {
      width: 4.5rem;
      margin: 0 auto 1.5rem;
    }
  }

  .card--highlight {
    color: #33CC33;
  }

  .card-forgot__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .card-forgot__button {
    display: block;
    text-transform: capitalize;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 2rem;
    width: 100%;
    padding: .45rem 1.35rem;
  }

  .card-forgot__button--primary {
    background: #33CC33;
    color: #FFFFFF;
    font-weight: bold;
  }

  .card-forgot__button--primary:hover,
  .card-forgot__button--primary:active,
  .card-forgot__button--primary:focus {
    opacity: .90;
    background: #33CC33 !important;
  }

  .card-forgot__button--outlined {
    background: transparent;
    color: #333333;
    border: 1px solid #333333;

    a:hover {
      text-decoration: none !important;
    }
  }

  .card-forgot__button--outlined:hover {
    background: #333333;
    color: #FFFFFF;
  }

  .full-page-background {
    position: fixed !important;
  }

  @media (min-width: 1400px) {
    .col-lg-6.justify-content-center {
      justify-content: start !important;
    }
    .card-forgot {
      margin: 0% 10% 10% 10%;
    }
  }

  @media (min-width: 992px) {
    .card-forgot {
      float: right;
    }
  }

  @media (max-width: 991px) {
    .content {
      .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 !important;
      }
    }
    .content-wrapper, .content-selector {
      display: none;
    }
    .div-forgot {
      width: 100%;
    }
    .card-forgot {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .bg-wrapper {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }

  @keyframes in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

</style>
