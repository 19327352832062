<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12">
        <card>
          <div class="card-header">
            <h4>Solicitações de Placas</h4>
          </div>
          <div class="card-body row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-2">
                  <label class="control-label">ID da Solicitação</label>
                  <fg-input v-mask="'#########'" v-model="search.id"/>
                </div>
                <div class="col-lg-2">
                  <label class="control-label">Usuário</label>
                  <fg-input v-model="search.username"/>
                </div>
                <div class="col-lg-2">
                  <div class="row" style="margin-left: 0px;">
                    <label class="control-label">Data da solicitação</label>
                  </div>
                  <div class="row" style="margin-left: 0px;">
                    <el-date-picker v-model="search.requestedAt" type="date" placeholder="Escolha uma data"
                                    :picker-options="dateOpts" format="dd/MM/yyyy"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <fg-input label="Ordenar por">
                    <el-select class="select-default" v-model="search.orderByDirection" placeholder="Ordenar por">
                      <el-option class="select-default" v-for="item in orderByOptions" :key="item.value"
                                 :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-lg-2">
                  <fg-input label="Status">
                    <el-select class="select-default" v-model="search.status" placeholder="Status">
                      <el-option class="select-default" v-for="item in statusOptions" :key="item.value"
                                 :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-lg-2">
                  <fg-input label="Faturamento">
                    <el-select class="select-default" v-model="search.plateRevenue" placeholder="Faturamento">
                      <el-option class="select-default" v-for="item in plateRevenueOptions" :key="item"
                                 :label="`R$ ${toMoney(item)}`" :value="item"></el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-lg-1">
                  <fg-input label="Por página">
                    <el-select class="select-default" v-model="pagination.perPage" placeholder="Por página">
                      <el-option class="select-default" v-for="item in [5, 25, 50, 100]" :key="item" :label="item"
                                 :value="item"></el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-lg-2">
                  <p-button type="success" style="margin-top: 25px;"
                            @click="find">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Buscar
                  </p-button>
                </div>
              </div>
              <div class="row" v-if="search.status === 'TAGGED' && tableData.length > 0">
                <div class="col-lg-2">
                  <p-button @click="generateExcel">EXPORTAR EXCEL (.xlsx)</p-button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-3">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-file-circle-plus"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Placas em Estoque</p>
                      <p class="card-title ">{{ this.numberInStock }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-file-circle-plus"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Placas Solicitadas</p>
                      <p class="card-title ">{{ this.numberRequested }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-truck"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Placas Enviadas</p>
                      <p class="card-title ">{{ this.numberSent }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-success">
                      <i class="fa-solid fa-circle-check"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Placas Entregues</p>
                      <p class="card-title ">{{ this.numberDelivered }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <card>
          <div class="card-body row" v-if="tableData.length > 0">
            <div class="col-md-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="20" label="ID">
                  <template slot-scope="props">
                    <span>
                      <span><b>{{ props.row.id }}</b></span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Usuário">
                  <template slot-scope="props">
                    <span>
                      <span>{{ props.row.username }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Nome">
                  <template slot-scope="props">
                    <span>
                      <span>{{ props.row.nameUser }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="CEP">
                  <template slot-scope="props">
                    <span>
                      <span>{{ props.row.zipCode }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Data da Solicitação">
                  <template slot-scope="props">
                    <span>
                      <span>{{ formatDate(props.row.requestedAt, 'DD/MM/YYYY - HH:mm') }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Faturamento do Usuário">
                  <template slot-scope="props">
                    <span>
                      <span>R$ {{ toMoney(props.row.userRevenue) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Tipo de Placa">
                  <template slot-scope="props">
                    <span>
                      <span>{{ props.row.name }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Status">
                  <template slot-scope="props">
                    <span>
                      <span>{{ statusOptions[props.row.status].label }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Ações" :min-width="70" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <p-button type="warning" size="sm" icon title="Separar" v-if="props.row.status === 'REQUESTED'"
                              @click="separate(props.row)">
                      <i class="fa-solid fa-arrows-alt-h"></i>
                    </p-button>
                    <p-button type="danger" size="sm" icon title="Gerar Etiqueta" v-if="['SEPARATED', 'TAGGED'].includes(props.row.status)"
                              @click="tagPlate(props.row)">
                      <i class="fa-solid fa-tag"></i>
                    </p-button>
                    <p-button type="success" size="sm" icon title="Confirmar Etiquetamento" v-if="['TAGGED'].includes(props.row.status)"
                              @click="confirmTagging(props.row)">
                      <i class="fa-solid fa-check-circle"></i>
                    </p-button>
                    <p-button type="success" size="sm" icon title="Enviar" v-if="props.row.status === 'AWAITING_SHIPMENT'"
                              @click="shipPlate(props.row, false)">
                      <i class="fa-solid fa-truck"></i>
                    </p-button>
                    <p-button type="warning" size="sm" icon title="Editar dados" v-if="props.row.status === 'CANCELLED'"
                              @click="updateShipmentInfo(props.row, true)">
                      <i class="fa-solid fa-truck"></i>
                    </p-button>
                    <p-button type="success" size="sm" icon title="Confirmar entrega" v-if="props.row.status === 'SHIPPED'"
                              @click="confirmDelivery(props.row)">
                      <i class="fa-solid fa-check"></i>
                    </p-button>
                    <p-button type="warning" size="sm" icon title="Cancelar envio" v-if="props.row.status === 'SHIPPED'"
                              @click="cancelShipment(props.row)">
                      <i class="fa-solid fa-x"></i>
                    </p-button>
                    <p-button type="info" size="sm" icon title="Mais Info."
                              @click="showMoreInfo(props.row)">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <component
              v-if="selectedRequest"
              :plateRequest="selectedRequest"
              :is="currentActiveModal"
              @save="loadData"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {getWs, postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {toMoney} from "@/util/core.utils";
import {formatDate} from "@/util/date.utils";
import ShipPlateForm from "@/components/Dashboard/Views/BackOffice/SuitJourney/Forms/ShipPlateForm.vue";
import EditPlateShipmentInfoForm from "@/components/Dashboard/Views/BackOffice/SuitJourney/Forms/EditPlateShipmentInfoForm.vue";
import download from "downloadjs";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  components: {
    PPagination,
    [DatePicker.name]: DatePicker,
    ShipPlateForm,
    EditPlateShipmentInfoForm
  },
  data() {
    return {
      search: {
        id: '',
        username: '',
        requestedAt: '',
        status: '',
        plateRevenue: '',
        orderByDirection: 'DESCENDING'
      },
      tableData: [],
      numberInStock: 0,
      numberRequested: 0,
      numberSent: 0,
      numberDelivered: 0,
      pagination: {
        perPage: 50,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      selectedRequest: null,
      currentActiveModal: null,
      dateOpts: {
        disabledDate: this.disabledDate
      },
      statusOptions: {
        'ALL': {
          label: 'Todos',
          value: ''
        },
        'REQUESTED': {
          label: 'Solicitado',
          value: 'REQUESTED'
        },
        'SEPARATED': {
          label: 'Separado',
          value: 'SEPARATED'
        },
        'TAGGED': {
          label: 'Etiquetado',
          value: 'TAGGED'
        },
        'AWAITING_SHIPMENT': {
          label: 'Aguardando Envio',
          value: 'AWAITING_SHIPMENT'
        },
        'SHIPPED': {
          label: 'Enviado',
          value: 'SHIPPED'
        },
        'DELIVERED': {
          label: 'Entregue',
          value: 'DELIVERED'
        },
        'CANCELLED': {
          label: 'Cancelado',
          value: 'CANCELLED'
        },
      },
      orderByOptions: {
        'DEFAULT': {
          label: 'Mais recente',
          value: 'DESCENDING'
        },
        'OLDER': {
          label: 'Mais antigo',
          value: 'ASCENDING'
        }
      },
      plateRevenueOptions: []
    }
  },
  mounted() {
    this.loadData()
    this.getPlateRevenueOptions()
  },
  methods: {
    formatDate,
    toMoney,
    find() {
      this.loadData()
    },
    loadData() {
      postWs('/bo/suitjourney/search-plate-request',
        true, null,
        {
          id: this.search.id,
          username: this.search.username,
          requestedAt: this.search.requestedAt,
          status: this.search.status,
          plateRevenue: this.search.plateRevenue,
          orderByDirection: this.search.orderByDirection,
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage
        },
        this.successLoadData,
        this.failLoadData)
      this.selectedRequest = null
    },
    successLoadData(response) {
      this.tableData = response.data.list
      this.numberInStock = response.data.numberInStock
      this.numberRequested = response.data.numberRequested
      this.numberSent = response.data.numberSent
      this.numberDelivered = response.data.numberDelivered
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
      if (this.tableData.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem solicitações.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
    },
    failLoadData() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar pelas Placas. Tente novamente!',
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    disabledDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    separate(plateRequest) {
      postWs('/bo/suitjourney/separate-plate',
        true,
        {
          plateRequestId: plateRequest.id
        },
        null,
        this.successSeparatePlate,
        this.failSeparatePlate)
    },
    successSeparatePlate() {
      swal({
        title: 'Succeso!',
        text: 'Placa separada com sucesso!',
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)
      this.loadData()
    },
    failSeparatePlate() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao separar a placa.',
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'success'
      }).catch(swal.noop)
    },
    tagPlate(plateRequest) {
      postWs('/bo/suitjourney/tag-plate',
        true,
        {
          plateRequestId: plateRequest.id
        },
        null,
        this.successTagPlate,
        this.failTagPlate)
    },
    successTagPlate(response) {
      try {
        let pdfWindow = window.open("about:blank");
        pdfWindow.document.write("<html<head><title>Etiqueta Suit Journey</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
      } catch (ex) {
        swal({
          title: 'Aviso!',
          text: 'Para a exibição da etiqueta, favor permitir janela pop-up no navegador. Após permitido, gere a etiqueta novamente.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
      this.loadData()
    },
    failTagPlate() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao gerar etiqueta. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    confirmTagging(plateRequest) {
      postWs('/bo/suitjourney/confirm-plate-tagging',
        true,
        {
          plateRequestId: plateRequest.id
        },
        null,
        this.successConfirmTagging,
        this.failConfirmTagging)
    },
    successConfirmTagging() {
      swal({
        title: 'Sucesso!',
        text: 'Etiquetamento confirmado! Status alterado para "Aguardando Envio".',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.loadData()
    },
    failConfirmTagging() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao confirmar Etiquetamento. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
      this.loadData()
    },
    shipPlate(plateRequest) {
      this.selectedRequest = plateRequest
      this.currentActiveModal = 'ship-plate-form'
    },
    updateShipmentInfo(plateRequest) {
      this.selectedRequest = plateRequest
      this.currentActiveModal = 'edit-plate-shipment-info-form'
    },
    confirmDelivery(plateRequest) {
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo confirmar a entrega da placa "${plateRequest.name}" para o usuário @${plateRequest.username}?`,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then(() => {
        this.doConfirmDelivery(plateRequest)
      })
    },
    doConfirmDelivery(plateRequest) {
      postWs('/bo/suitjourney/confirm-plate-delivery',
        true,
        {
          plateRequestId: plateRequest.id
        },
        null,
        this.successConfirmDelivery,
        this.failConfirmDelivery)
    },
    successConfirmDelivery() {
      swal({
        title: 'Sucesso!',
        text: 'Entrega confirmada com sucesso!',
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.loadData()
    },
    failConfirmDelivery() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao confirmar a entrega desta placa. Tente novamente!',
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    cancelShipment(plateRequest) {
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo cancelar o envio da placa "${plateRequest.name}" para o usuário @${plateRequest.username}?`,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then(() => {
        this.doCancelShipment(plateRequest)
      })
    },
    doCancelShipment(plateRequest) {
      postWs('/bo/suitjourney/cancel-plate-shipment',
        true,
        {
          plateRequestId: plateRequest.id
        },
        null,
        this.successCancelShipment,
        this.failCancelShipment)
    },
    successCancelShipment() {
      swal({
        title: 'Sucesso!',
        text: 'Envio cancelado com sucesso!',
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.loadData()
    },
    failCancelShipment() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao cancelar o envio desta placa.',
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    showMoreInfo(plateRequest) {
      swal({
        title: 'Informações da Solicitação',
        html: `
        <ul class="request-more-data">
            <p><b>CEP: </b>${plateRequest.deliveryAddress.zipCode}</p>
            <p><b>Cidade: </b>${plateRequest.deliveryAddress.city}</p>
            <p><b>Estado: </b>${plateRequest.deliveryAddress.state}</p>
            <p><b>Bairro: </b>${plateRequest.deliveryAddress.neighborhood}</p>
            <p><b>Rua: </b>${plateRequest.deliveryAddress.street}</p>
            <p><b>Número: </b>${plateRequest.deliveryAddress.number}</p>
            <p><b>Complemento: </b>${plateRequest.deliveryAddress.complement}</p>
            <p><b>Whatsapp: </b>${plateRequest.phoneNumber}</p>
            <hr>
            <li><b>Código de Rastreio: </b> ${plateRequest.trackingCode ? plateRequest.trackingCode : '-'}</li>
            <li><b>Transportadora: </b> ${plateRequest.shipmentName ? plateRequest.shipmentName : '-'}</li>
            <li><b>Envio</b></li>
            <ul>
                <li><b>Data/Hora:</b> ${plateRequest.sentAt ? formatDate(plateRequest.sentAt, "DD/MM/YYYY - HH:mm") : '-'}</li>
            </ul>
            <li><b>Entrega</b></li>
            <ul>
                <li><b>Data/Hora:</b> ${plateRequest.deliveredAt ? formatDate(plateRequest.deliveredAt, "DD/MM/YYYY - HH:mm") : '-'}</li>
            </ul>
            <hr>
            <small>Editado pela última vez por <em>@${plateRequest.lastModifiedBy}</em> em ${formatDate(plateRequest.lastModifiedDate, 'DD/MM/YYYY - HH:SS')}</small>
        </ul>
        `,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-info btn-fill',
        width: '40rem'
      }).catch(swal.noop)
    },
    getPlateRevenueOptions() {
      getWs('/bo/suitjourney/plate-revenue-options',
        true, null,
        this.successGetPlateRevenueOptions,
        this.failGetPlateRevenueOptions)
    },
    successGetPlateRevenueOptions(response) {
      this.plateRevenueOptions = response.data
    },
    failGetPlateRevenueOptions() {},
    generateExcel() {
      postWs('/bo/suitjourney/generate-spreadsheet', true, null, {list: this.tableData}, this.successGenerateExcel, () => null)
    },
    successGenerateExcel(response) {
      try {
        download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
          "solicitacoes_suit_journey.xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      } catch (ex) {
        swal({
          title: 'Erro!',
          text: "Erro ao gerar planilha Excel, favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    }
  }
}
</script>
<style lang="scss">
.request-more-data {
  text-align: left;
  line-height: 1.75;
  list-style: none;
  line-break: anywhere;
  padding: 0 !important;

  > li:first-child {
    margin-top: 1.5rem;
  }

  ul {
    list-style: none;
  }

  > li {
    margin-top: 1rem;
  }

  b {
    font-weight: bold;
  }
}
</style>

