<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <div class="credits ml-auto">
          <div class="copyright" style="margin-right: 70px">
            &copy; 2020, <b style="color: #000000 !important;">SuitPay</b>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer'
  }
</script>
<style>
</style>
