export default[
  {
    id: 1,
    name: "Afeganistão",
    number_iso: "AFG",
    formal_name: "República Islâmica do Afeganistão",
    ddi: 93
  },
  {
    id: 2,
    name: "Áfricado Sul",
    number_iso: "ZAF",
    formal_name: "República da África do Sul",
    ddi: 27
  },
  {
    id: 3,
    name: "Albânia",
    number_iso: "ALB",
    formal_name: "República da Albânia",
    ddi: 355
  },
  {
    id: 4,
    name: "Alemanha",
    number_iso: "DEU",
    formal_name: "República Federal da Alemanha",
    ddi: 49
  },
  {
    id: 5,
    name: "Andorra",
    number_iso: "AND",
    formal_name: "Principado Andorra",
    ddi: 376
  },
  {
    id: 6,
    name: "Angola",
    number_iso: "AGO",
    formal_name: "República de Angola",
    ddi: 244
  },
  {
    id: 7,
    name: "Antigae Barbuda",
    number_iso: "ATG",
    formal_name: "Antigua e Barbuda",
    ddi: 1268
  },
  {
    id: 8,
    name: "Arábia Saudita",
    number_iso: "SAU",
    formal_name: "Reino da Arábia Saudita",
    ddi: 966
  },
  {
    id: 9,
    name: "Argélia",
    number_iso: "DZA",
    formal_name: "República Democrática Popular da Algéria",
    ddi: 213
  },
  {
    id: 10,
    name: "Argentina",
    number_iso: "ARG",
    formal_name: "República Argentina",
    ddi: 54
  },
  {
    id: 11,
    name: "Arménia",
    number_iso: "ARM",
    formal_name: "República da Armênia",
    ddi: 374
  },
  {
    id: 12,
    name: "Austrália",
    number_iso: "AUS",
    formal_name: "Comunidade da Austrália",
    ddi: 61
  },
  {
    id: 13,
    name: "Áustria",
    number_iso: "AUT",
    formal_name: "República da Áustria",
    ddi: 43
  },
  {
    id: 14,
    name: "Azerbaijão",
    number_iso: "AZE",
    formal_name: "República do Azerbaijão",
    ddi: 994
  },
  {
    id: 15,
    name: "Bahamas",
    number_iso: "BHS",
    formal_name: "Comunidade de Bahamas",
    ddi: 1242
  },
  {
    id: 16,
    name: "Bangladexe",
    number_iso: "BGD",
    formal_name: "República Popular de Bangladesh",
    ddi: 880
  },
  {
    id: 17,
    name: "Barbados",
    number_iso: "BRB",
    formal_name: "Barbados",
    ddi: 246
  },
  {
    id: 18,
    name: "Barém",
    number_iso: "BHR",
    formal_name: "Reino do Bahrein",
    ddi: 973
  },
  {
    id: 19,
    name: "Bélgica",
    number_iso: "BEL",
    formal_name: "Reino da Bélgica",
    ddi: 32
  },
  {
    id: 20,
    name: "Belize",
    number_iso: "BLZ",
    formal_name: "Belize",
    ddi: 501
  },
  {
    id: 21,
    name: "Benim",
    number_iso: "BEN",
    formal_name: "República do Benin",
    ddi: 229
  },
  {
    id: 22,
    name: "Bielorrússia",
    number_iso: "BLR",
    formal_name: "República da Bielorrússia",
    ddi: 375
  },
  {
    id: 23,
    name: "Bolívia",
    number_iso: "BOL",
    formal_name: "Estado Plurinacional da Bolívia",
    ddi: 591
  },
  {
    id: 24,
    name: "Bósniae Herzegovina",
    number_iso: "BIH",
    formal_name: "Bósnia e Herzegovina",
    ddi: 387
  },
  {
    id: 25,
    name: "Botsuana",
    number_iso: "BWA",
    formal_name: "República da Botswana",
    ddi: 267
  },
  {
    id: 26,
    name: "Brasil",
    number_iso: "BRA",
    formal_name: "República Federativa do Brasil",
    ddi: 55
  },
  {
    id: 27,
    name: "Brunei",
    number_iso: "BRN",
    formal_name: "Estado do Brunei Darussalam",
    ddi: 673
  },
  {
    id: 28,
    name: "Bulgária",
    number_iso: "BGR",
    formal_name: "República da Bulgária",
    ddi: 359
  },
  {
    id: 29,
    name: "Burquina Faso",
    number_iso: "BFA",
    formal_name: "Burkina Faso",
    ddi: 226
  },
  {
    id: 30,
    name: "Burúndi",
    number_iso: "BDI",
    formal_name: "República do Burundi",
    ddi: 257
  },
  {
    id: 31,
    name: "Butão",
    number_iso: "BTN",
    formal_name: "Reino do Butão",
    ddi: 975
  },
  {
    id: 32,
    name: "Cabo Verde",
    number_iso: "CPV",
    formal_name: "República do Cabo Verde",
    ddi: 238
  },
  {
    id: 33,
    name: "Camarões",
    number_iso: "CMR",
    formal_name: "República de Camarões",
    ddi: 237
  },
  {
    id: 34,
    name: "Camboja",
    number_iso: "KHM",
    formal_name: "Reino do Camboja",
    ddi: 855
  },
  {
    id: 35,
    name: "Canadá",
    number_iso: "CAN",
    formal_name: "Canadá",
    ddi: 1
  },
  {
    id: 36,
    name: "Catar",
    number_iso: "QAT",
    formal_name: "Estado do Catar",
    ddi: 974
  },
  {
    id: 37,
    name: "Cazaquistão",
    number_iso: "KAZ",
    formal_name: "República do Cazaquistão",
    ddi: 7
  },
  {
    id: 38,
    name: "Chade",
    number_iso: "TCD",
    formal_name: "República do Chade",
    ddi: 235
  },
  {
    id: 39,
    name: "Chile",
    number_iso: "CHL",
    formal_name: "República do Chile",
    ddi: 56
  },
  {
    id: 40,
    name: "China",
    number_iso: "CHN",
    formal_name: "República Popular da China",
    ddi: 86
  },
  {
    id: 41,
    name: "Chipre",
    number_iso: "CYP",
    formal_name: "República do Chipre",
    ddi: 357
  },
  {
    id: 42,
    name: "Colômbia",
    number_iso: "COL",
    formal_name: "República da Colômbia",
    ddi: 57
  },
  {
    id: 43,
    name: "Comores",
    number_iso: "COM",
    formal_name: "União das Comores",
    ddi: 269
  },
  {
    id: 44,
    name: "Congo- Brazzaville",
    number_iso: "COG",
    formal_name: "República do Congo",
    ddi: 242
  },
  {
    id: 45,
    name: "Coreiado Norte",
    number_iso: "PRK",
    formal_name: "República Democrática Popular da Coreia",
    ddi: 850
  },
  {
    id: 46,
    name: "Coreiado Sul",
    number_iso: "KOR",
    formal_name: "República da Coreia",
    ddi: 82
  },
  {
    id: 48,
    name: "Costado Marfim",
    number_iso: "CIV",
    formal_name: "República da Costa do Marfim",
    ddi: 225
  },
  {
    id: 49,
    name: "Costa Rica",
    number_iso: "CRI",
    formal_name: "República da Costa Rica",
    ddi: 506
  },
  {
    id: 50,
    name: "Croácia",
    number_iso: "HRV",
    formal_name: "República da Croácia",
    ddi: 385
  },
  {
    id: 51,
    name: "Cuaite",
    number_iso: "KWT",
    formal_name: "Estado do Kuwait",
    ddi: 965
  },
  {
    id: 52,
    name: "Cuba",
    number_iso: "CUB",
    formal_name: "República de Cuba",
    ddi: 53
  },
  {
    id: 53,
    name: "Dinamarca",
    number_iso: "DNK",
    formal_name: "Reino da Dinamarca",
    ddi: 45
  },
  {
    id: 54,
    name: "Dominica",
    number_iso: "DMA",
    formal_name: "Comunidade da Dominica",
    ddi: 1767
  },
  {
    id: 55,
    name: "Egito",
    number_iso: "EGY",
    formal_name: "República Árabe do Egito",
    ddi: 20
  },
  {
    id: 56,
    name: "EmiradosÁrabes Unidos",
    number_iso: "ARE",
    formal_name: "Emirados Árabes Unidos",
    ddi: 971
  },
  {
    id: 57,
    name: "Equador",
    number_iso: "ECU",
    formal_name: "República do Equador",
    ddi: 593
  },
  {
    id: 58,
    name: "Eritreia",
    number_iso: "ERI",
    formal_name: "Estado da Eritreia",
    ddi: 291
  },
  {
    id: 59,
    name: "Eslováquia",
    number_iso: "SVK",
    formal_name: "República Eslovaca",
    ddi: 421
  },
  {
    id: 60,
    name: "Eslovénia",
    number_iso: "SVN",
    formal_name: "República da Eslovênia",
    ddi: 386
  },
  {
    id: 61,
    name: "Espanha",
    number_iso: "ESP",
    formal_name: "Reino da Espanha",
    ddi: 34
  },
  {
    id: 62,
    name: "Essuatíni",
    number_iso: "SWZ",
    formal_name: "Reino da Suazilândia",
    ddi: 268
  },
  {
    id: 63,
    name: "Estadoda Palestina",
    number_iso: "PSE",
    formal_name: "Estado da Palestina",
    ddi: 970
  },
  {
    id: 64,
    name: "Estados Unidos",
    number_iso: "USA",
    formal_name: "Estados Unidos da América",
    ddi: 1
  },
  {
    id: 65,
    name: "Estónia",
    number_iso: "EST",
    formal_name: "República da Estônia",
    ddi: 372
  },
  {
    id: 66,
    name: "Etiópia",
    number_iso: "ETH",
    formal_name: "República Democrática Federal da Etiópia",
    ddi: 251
  },
  {
    id: 67,
    name: "Fiji",
    number_iso: "FJI",
    formal_name: "República do Fiji",
    ddi: 679
  },
  {
    id: 68,
    name: "Filipinas",
    number_iso: "PHL",
    formal_name: "República das Filipinas",
    ddi: 63
  },
  {
    id: 69,
    name: "Finlândia",
    number_iso: "FIN",
    formal_name: "República da Finlândia",
    ddi: 358
  },
  {
    id: 70,
    name: "França",
    number_iso: "FRA",
    formal_name: "República Francesa",
    ddi: 33
  },
  {
    id: 71,
    name: "Gabão",
    number_iso: "GAB",
    formal_name: "República Gabonesa",
    ddi: 241
  },
  {
    id: 72,
    name: "Gâmbia",
    number_iso: "GMB",
    formal_name: "República da Gâmbia",
    ddi: 220
  },
  {
    id: 73,
    name: "Gana",
    number_iso: "GHA",
    formal_name: "Repúblia de Gana",
    ddi: 233
  },
  {
    id: 74,
    name: "Geórgia",
    number_iso: "GEO",
    formal_name: "Geórgia",
    ddi: 995
  },
  {
    id: 75,
    name: "Granada",
    number_iso: "GRD",
    formal_name: "Granada",
    ddi: 1473
  },
  {
    id: 76,
    name: "Grécia",
    number_iso: "GRC",
    formal_name: "República Helênica",
    ddi: 30
  },
  {
    id: 77,
    name: "Guatemala",
    number_iso: "GTM",
    formal_name: "República da Guatemala",
    ddi: 502
  },
  {
    id: 78,
    name: "Guiana",
    number_iso: "GUY",
    formal_name: "República Cooperativa da Guiana",
    ddi: 592
  },
  {
    id: 79,
    name: "Guiné",
    number_iso: "GIN",
    formal_name: "República do Guiné",
    ddi: 224
  },
  {
    id: 80,
    name: "Guiné Equatorial",
    number_iso: "GNQ",
    formal_name: "República do Guiné Equatorial",
    ddi: 240
  },
  {
    id: 81,
    name: "Guiné- Bissau",
    number_iso: "GNB",
    formal_name: "República da Guiné-Bissau",
    ddi: 245
  },
  {
    id: 82,
    name: "Haiti",
    number_iso: "HTI",
    formal_name: "República do Haiti",
    ddi: 509
  },
  {
    id: 83,
    name: "Honduras",
    number_iso: "HND",
    formal_name: "República de Honduras",
    ddi: 504
  },
  {
    id: 84,
    name: "Hungria",
    number_iso: "HUN",
    formal_name: "Hungria",
    ddi: 36
  },
  {
    id: 85,
    name: "Iémen",
    number_iso: "YEM",
    formal_name: "República do Iêmen",
    ddi: 967
  },
  {
    id: 86,
    name: "Ilhas Marechal",
    number_iso: "MHL",
    formal_name: "República das Ilhas Marshall",
    ddi: 692
  },
  {
    id: 87,
    name: "Índia",
    number_iso: "IND",
    formal_name: "República da Índia",
    ddi: 91
  },
  {
    id: 88,
    name: "Indonésia",
    number_iso: "IDN",
    formal_name: "República da Indonésia",
    ddi: 62
  },
  {
    id: 89,
    name: "Irão",
    number_iso: "IRN",
    formal_name: "República Islâmica do Iran",
    ddi: 98
  },
  {
    id: 90,
    name: "Iraque",
    number_iso: "IRQ",
    formal_name: "República do Iraque",
    ddi: 964
  },
  {
    id: 91,
    name: "Irlanda",
    number_iso: "IRL",
    formal_name: "Irlanda",
    ddi: 353
  },
  {
    id: 92,
    name: "Islândia",
    number_iso: "ISL",
    formal_name: "Islândia",
    ddi: 354
  },
  {
    id: 93,
    name: "Israel",
    number_iso: "ISR",
    formal_name: "Estado de Israel",
    ddi: 972
  },
  {
    id: 94,
    name: "Itália",
    number_iso: "ITA",
    formal_name: "República Italiana",
    ddi: 39
  },
  {
    id: 95,
    name: "Jamaica",
    number_iso: "JAM",
    formal_name: "Jamaica",
    ddi: 1876
  },
  {
    id: 96,
    name: "Japão",
    number_iso: "JPN",
    formal_name: "Japão",
    ddi: 81
  },
  {
    id: 97,
    name: "Jibuti",
    number_iso: "DJI",
    formal_name: "República do Djibuti",
    ddi: 253
  },
  {
    id: 98,
    name: "Jordânia",
    number_iso: "JOR",
    formal_name: "Reino Hachemita da Jordânia",
    ddi: 962
  },
  {
    id: 99,
    name: "Laus",
    number_iso: "LAO",
    formal_name: "República Democrática Popular Lau",
    ddi: 856
  },
  {
    id: 100,
    name: "Lesoto",
    number_iso: "LSO",
    formal_name: "Reino do Lesoto",
    ddi: 266
  },
  {
    id: 101,
    name: "Letónia",
    number_iso: "LVA",
    formal_name: "República da Letônia",
    ddi: 371
  },
  {
    id: 102,
    name: "Líbano",
    number_iso: "LBN",
    formal_name: "República Libanesa",
    ddi: 961
  },
  {
    id: 103,
    name: "Libéria",
    number_iso: "LBR",
    formal_name: "República da Libéria",
    ddi: 231
  },
  {
    id: 104,
    name: "Líbia",
    number_iso: "LBY",
    formal_name: "Líbia",
    ddi: 218
  },
  {
    id: 105,
    name: "Listenstaine",
    number_iso: "LIE",
    formal_name: "Principado de Liechtenstein",
    ddi: 423
  },
  {
    id: 106,
    name: "Lituânia",
    number_iso: "LTU",
    formal_name: "República da Lituânia",
    ddi: 370
  },
  {
    id: 107,
    name: "Luxemburgo",
    number_iso: "LUX",
    formal_name: "Grão-Ducado do Luxemburgo",
    ddi: 352
  },
  {
    id: 108,
    name: "Macedóniado Norte",
    number_iso: "MKD",
    formal_name: "República da Macedônia",
    ddi: 389
  },
  {
    id: 109,
    name: "Madagáscar",
    number_iso: "MDG",
    formal_name: "República de Madagascar",
    ddi: 261
  },
  {
    id: 110,
    name: "Malásia",
    number_iso: "MYS",
    formal_name: "Malásia",
    ddi: 60
  },
  {
    id: 111,
    name: "Maláui",
    number_iso: "MWI",
    formal_name: "República de Malawi",
    ddi: 265
  },
  {
    id: 112,
    name: "Maldivas",
    number_iso: "MDV",
    formal_name: "Reública de Maldivas",
    ddi: 960
  },
  {
    id: 113,
    name: "Mali",
    number_iso: "MLI",
    formal_name: "República do Mali",
    ddi: 223
  },
  {
    id: 114,
    name: "Malta",
    number_iso: "MLT",
    formal_name: "República de Malta",
    ddi: 356
  },
  {
    id: 115,
    name: "Marrocos",
    number_iso: "MAR",
    formal_name: "Reino de Marrocos",
    ddi: 212
  },
  {
    id: 116,
    name: "Maurícia",
    number_iso: "MUS",
    formal_name: "República de Maurício",
    ddi: 230
  },
  {
    id: 117,
    name: "Mauritânia",
    number_iso: "MRT",
    formal_name: "República Islâmica da Mauritânia",
    ddi: 222
  },
  {
    id: 118,
    name: "México",
    number_iso: "MEX",
    formal_name: "Estados Unidos Mexicanos",
    ddi: 52
  },
  {
    id: 119,
    name: "Mianmar",
    number_iso: "MMR",
    formal_name: "República da União de Myanmar",
    ddi: 95
  },
  {
    id: 120,
    name: "Micronésia",
    number_iso: "FSM",
    formal_name: "Estados Federados da Micronesia",
    ddi: 691
  },
  {
    id: 121,
    name: "Moçambique",
    number_iso: "MOZ",
    formal_name: "República de Moçambique",
    ddi: 258
  },
  {
    id: 122,
    name: "Moldávia",
    number_iso: "MDA",
    formal_name: "República de Moldova",
    ddi: 373
  },
  {
    id: 123,
    name: "Mónaco",
    number_iso: "MCO",
    formal_name: "Principado de Mônaco",
    ddi: 377
  },
  {
    id: 124,
    name: "Mongólia",
    number_iso: "MNG",
    formal_name: "Mongólia",
    ddi: 976
  },
  {
    id: 125,
    name: "Montenegro",
    number_iso: "MNE",
    formal_name: null,
    ddi: null
  },
  {
    id: 126,
    name: "Namíbia",
    number_iso: "NAM",
    formal_name: "República da Namíbia",
    ddi: 264
  },
  {
    id: 127,
    name: "Nauru",
    number_iso: "NRU",
    formal_name: "República de Nauru",
    ddi: 674
  },
  {
    id: 128,
    name: "Nepal",
    number_iso: "NPL",
    formal_name: "República Democrática Federativa do Nepal",
    ddi: 977
  },
  {
    id: 129,
    name: "Nicarágua",
    number_iso: "NIC",
    formal_name: "República da Nicarágua",
    ddi: 505
  },
  {
    id: 130,
    name: "Níger",
    number_iso: "NER",
    formal_name: "República do Niger",
    ddi: 227
  },
  {
    id: 131,
    name: "Nigéria",
    number_iso: "NGA",
    formal_name: "República Federativa da Nigéria",
    ddi: 234
  },
  {
    id: 132,
    name: "Noruega",
    number_iso: "NOR",
    formal_name: "Reino da Noruega",
    ddi: 47
  },
  {
    id: 133,
    name: "Nova Zelândia",
    number_iso: "NZL",
    formal_name: "Nova Zelândia",
    ddi: 64
  },
  {
    id: 134,
    name: "Omã",
    number_iso: "OMN",
    formal_name: "Sultanato de Omã",
    ddi: 968
  },
  {
    id: 135,
    name: "Países Baixos",
    number_iso: "NLD",
    formal_name: "Holanda",
    ddi: 31
  },
  {
    id: 136,
    name: "Palau",
    number_iso: "PLW",
    formal_name: "República de Palau",
    ddi: 680
  },
  {
    id: 137,
    name: "Panamá",
    number_iso: "PAN",
    formal_name: "República do Panamá",
    ddi: 507
  },
  {
    id: 138,
    name: "Papua Nova Guiné",
    number_iso: "PNG",
    formal_name: "Estado Independente da Papua-Nova Guiné",
    ddi: 675
  },
  {
    id: 139,
    name: "Paquistão",
    number_iso: "PAK",
    formal_name: "República Islâmica do Paquistão",
    ddi: 92
  },
  {
    id: 140,
    name: "Paraguai",
    number_iso: "PRY",
    formal_name: "República do Paraguai",
    ddi: 595
  },
  {
    id: 141,
    name: "Peru",
    number_iso: "PER",
    formal_name: "República do Peru",
    ddi: 51
  },
  {
    id: 142,
    name: "Polónia",
    number_iso: "POL",
    formal_name: "República da Polônia",
    ddi: 48
  },
  {
    id: 143,
    name: "Portugal",
    number_iso: "PRT",
    formal_name: "República Portuguesa",
    ddi: 351
  },
  {
    id: 144,
    name: "Quénia",
    number_iso: "KEN",
    formal_name: "República do Quênia",
    ddi: 254
  },
  {
    id: 145,
    name: "Quirguistão",
    number_iso: "KGZ",
    formal_name: "República Quirguiz",
    ddi: 996
  },
  {
    id: 146,
    name: "Quiribáti",
    number_iso: "KIR",
    formal_name: "República do Kiribati",
    ddi: 686
  },
  {
    id: 147,
    name: "Reino Unido",
    number_iso: "GBR",
    formal_name: "Reino Unido da Grã-Bretanha e Irlanda do Norte",
    ddi: 44
  },
  {
    id: 148,
    name: "República Centro- Africana",
    number_iso: "CAF",
    formal_name: "República Centro-Africana",
    ddi: 236
  },
  {
    id: 149,
    name: "República Checa",
    number_iso: "CZE",
    formal_name: "República Tcheca",
    ddi: 420
  },
  {
    id: 150,
    name: "República Democráticado Congo",
    number_iso: "COD",
    formal_name: "República Democrática do Congo",
    ddi: 242
  },
  {
    id: 151,
    name: "República Dominicana",
    number_iso: "DOM",
    formal_name: "República Dominicana",
    ddi: 1809
  },
  {
    id: 152,
    name: "Roménia",
    number_iso: "ROU",
    formal_name: null,
    ddi: null
  },
  {
    id: 153,
    name: "Ruanda",
    number_iso: "RWA",
    formal_name: "República da Ruanda",
    ddi: 250
  },
  {
    id: 154,
    name: "Rússia",
    number_iso: "RUS",
    formal_name: "Federação Russa",
    ddi: 70
  },
  {
    id: 155,
    name: "Salomão",
    number_iso: "SLB",
    formal_name: "Ilhas Salomão",
    ddi: 677
  },
  {
    id: 156,
    name: "Salvador",
    number_iso: "SLV",
    formal_name: "República El Salvador",
    ddi: 503
  },
  {
    id: 157,
    name: "Samoa",
    number_iso: "WSM",
    formal_name: "Estado Independente de Samoa",
    ddi: 684
  },
  {
    id: 158,
    name: "Santa Lúcia",
    number_iso: "LCA",
    formal_name: "Santa Lúcia",
    ddi: 1758
  },
  {
    id: 159,
    name: "São Cristóvãoe Neves",
    number_iso: "KNA",
    formal_name: "São Cristóvão",
    ddi: 1869
  },
  {
    id: 160,
    name: "São Marinho",
    number_iso: "SMR",
    formal_name: "República de São Marino",
    ddi: 378
  },
  {
    id: 161,
    name: "São Tomée Príncipe",
    number_iso: "STP",
    formal_name: "República Democrática de Sao Tomé e Príncipe",
    ddi: 239
  },
  {
    id: 162,
    name: "São Vicentee Granadinas",
    number_iso: "VCT",
    formal_name: "São Vicente e Granadinas",
    ddi: 1784
  },
  {
    id: 163,
    name: "Seicheles",
    number_iso: "SYC",
    formal_name: "República das Seicheles",
    ddi: 248
  },
  {
    id: 164,
    name: "Senegal",
    number_iso: "SEN",
    formal_name: "República do Senegal",
    ddi: 221
  },
  {
    id: 165,
    name: "Serra Leoa",
    number_iso: "SLE",
    formal_name: "República da Serra Leoa",
    ddi: 232
  },
  {
    id: 166,
    name: "Sérvia",
    number_iso: "SRB",
    formal_name: "União Estatal de Sérvia e Montenegro",
    ddi: 381
  },
  {
    id: 167,
    name: "Singapura",
    number_iso: "SGP",
    formal_name: "República da Singapura",
    ddi: 65
  },
  {
    id: 168,
    name: "Síria",
    number_iso: "SYR",
    formal_name: "República Árabe Síria",
    ddi: 963
  },
  {
    id: 169,
    name: "Somália",
    number_iso: "SOM",
    formal_name: "República da Somália",
    ddi: 252
  },
  {
    id: 170,
    name: "Sri Lanca",
    number_iso: "LKA",
    formal_name: "República Democrática Socialista do Sri Lanka",
    ddi: 94
  },
  {
    id: 171,
    name: "Sudão",
    number_iso: "SDN",
    formal_name: "República do Sudão",
    ddi: 249
  },
  {
    id: 172,
    name: "Sudãodo Sul",
    number_iso: "SSD",
    formal_name: null,
    ddi: null
  },
  {
    id: 173,
    name: "Suécia",
    number_iso: "SWE",
    formal_name: "Reino da Suécia",
    ddi: 46
  },
  {
    id: 174,
    name: "Suíça",
    number_iso: "CHE",
    formal_name: "Confederação Suiça",
    ddi: 41
  },
  {
    id: 175,
    name: "Suriname",
    number_iso: "SUR",
    formal_name: "República do Suriname",
    ddi: 597
  },
  {
    id: 176,
    name: "Tailândia",
    number_iso: "THA",
    formal_name: "Reino da Tailândia",
    ddi: 66
  },
  {
    id: 177,
    name: "Taiuã",
    number_iso: "TWN",
    formal_name: "Taiwan",
    ddi: 886
  },
  {
    id: 178,
    name: "Tajiquistão",
    number_iso: "TJK",
    formal_name: "República do Tajiquistão",
    ddi: 992
  },
  {
    id: 179,
    name: "Tanzânia",
    number_iso: "TZA",
    formal_name: "República Unida da Tanzânia",
    ddi: 255
  },
  {
    id: 180,
    name: "Timor- Leste",
    number_iso: "TLS",
    formal_name: "República Democrática de Timor-Leste",
    ddi: 670
  },
  {
    id: 181,
    name: "Togo",
    number_iso: "TGO",
    formal_name: "República Togolesa",
    ddi: 228
  },
  {
    id: 182,
    name: "Tonga",
    number_iso: "TON",
    formal_name: "Reino de Tonga",
    ddi: 676
  },
  {
    id: 183,
    name: "Trindadee Tobago",
    number_iso: "TTO",
    formal_name: "República da Trinidad e Tobago",
    ddi: 1868
  },
  {
    id: 184,
    name: "Tunísia",
    number_iso: "TUN",
    formal_name: "República da Tunísia",
    ddi: 216
  },
  {
    id: 185,
    name: "Turcomenistão",
    number_iso: "TKM",
    formal_name: "Turcomenistão",
    ddi: 7370
  },
  {
    id: 186,
    name: "Turquia",
    number_iso: "TUR",
    formal_name: "República da Turquia",
    ddi: 90
  },
  {
    id: 187,
    name: "Tuvalu",
    number_iso: "TUV",
    formal_name: "Tuvalu",
    ddi: 688
  },
  {
    id: 188,
    name: "Ucrânia",
    number_iso: "UKR",
    formal_name: "Ucrânia",
    ddi: 380
  },
  {
    id: 189,
    name: "Uganda",
    number_iso: "UGA",
    formal_name: "República de Uganda",
    ddi: 256
  },
  {
    id: 190,
    name: "Uruguai",
    number_iso: "URY",
    formal_name: "República Oriental do Uruguai",
    ddi: 598
  },
  {
    id: 191,
    name: "Usbequistão",
    number_iso: "UZB",
    formal_name: "República do Uzbequistão",
    ddi: 998
  },
  {
    id: 192,
    name: "Vanuatu",
    number_iso: "VUT",
    formal_name: "República de Vanuatu",
    ddi: 678
  },
  {
    id: 193,
    name: "Vaticano",
    number_iso: "VAT",
    formal_name: "Estado da Cidade do Vaticano",
    ddi: 39
  },
  {
    id: 194,
    name: "Venezuela",
    number_iso: "VEN",
    formal_name: "República Bolivariana da Venezuela",
    ddi: 58
  },
  {
    id: 195,
    name: "Vietname",
    number_iso: "VNM",
    formal_name: "República Socialista do Vietnam",
    ddi: 84
  },
  {
    id: 196,
    name: "Zâmbia",
    number_iso: "ZMB",
    formal_name: "República do Zâmbia",
    ddi: 260
  },
  {
    id: 197,
    name: "Zimbábue",
    number_iso: "ZWE",
    formal_name: "República do Zimbábue",
    ddi: 263
  },
  {
    id: 198,
    name: "Outros",
    number_iso: "ZZZ",
    formal_name: "Outros",
    ddi: 0
  }
]
