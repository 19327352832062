<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12 card">
        <div class="row">
          <div class="col-md-12" style="margin-bottom: 15px; text-align: left">
            <h4 slot="header" class="card-title">
              Relatório de Parceiros Pix
            </h4>
            <div class="row">
              <div class="col-md-3">
                <div class="row" style="margin-left: 0px">
                  <label>Data Início</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsStart" @input="validatePeriod"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row" style="margin-left: 0px">
                  <label>Data Fim</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsEnd"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <fg-input label="Status">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="status">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value="UNPAID"
                               class="select-default"
                               label="Não pago"/>
                    <el-option value="PAID"
                               class="select-default"
                               label="Pago"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-3">
                <p-button @click.prevent="search" style="margin-top: 24px">
                  <i class="fas fa-search-dollar"></i> BUSCAR
                </p-button>
              </div>
            </div>
            <div class="row card">
              <div class="row col-md-12" style="margin-bottom: 15px;">
                <div class="col-md-12" style="text-align: left; margin: auto">
                  <p>
                    <br><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor de Cashback: R$
                    {{ toMoney(summary.amountPartner) }}<br>
                    <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor de Cashback Pago: R$
                    {{ toMoney(summary.amountPaid) }}<br>
                    <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor de Cashback a Receber: R$
                    {{ toMoney(summary.amountUnpaid) }}<br>
                    <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Número de Usuários:
                    {{ summary.numberUsers }}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-12 card" v-if="timelineItems.length>0">
        <div class="row">
          <div class="col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="card-body row">
              <div class="col-sm-12">
                <el-table class="table-striped"
                          :data="timelineItems"
                          style="width: 100%">
                  <el-table-column :min-width="100" label="Usuário" :sortable="true" prop="username">
                    <template slot-scope="item">
                    <span>
                      <span><b>@{{ item.row.username }}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Valor Cashback" :sortable="true" prop="gatewaySale">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.valuePartner) }}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Valor Pago" :sortable="true" prop="linkSale">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.paidValue) }}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Valor a Receber" :sortable="true" prop="cardValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.unpaidValue) }}</span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {callWs} from "src/ws.service"
import {daysBetween, formatDate} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import {isSomeAuthorization} from "src/util/role.utils"

export default {
  components: {
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      status: null,
      timelineItems: [],
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      summary: {
        amountPaid: '',
        amountPartner: '',
        amountTransaction: '',
        amountUnpaid: '',
        numberUsers: 0,
        numbersPix: 0,
      }
    }
  },
  methods: {
    toMoney,
    search() {
      if(daysBetween(this.startDate, this.endDate) > 31){
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }

      this.timelineItems = []
      callWs("/pix-partner/report",
        "POST", null, true, null,
        {
          startDate: formatDate(this.startDate, "DD/MM/YYYY"),
          endDate: formatDate(this.endDate, "DD/MM/YYYY"),
          status: this.status
        },
        this.loadTimeline,
        (error) => {
          swal({
            title: 'Aviso!',
            text: 'Dados não encontrados para o período selecionado!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
        })
    },
    loadTimeline(response) {
      this.timelineItems = response.data.list
      this.summary.amountPaid = response.data.amountPaid
      this.summary.amountPartner = response.data.amountPartner
      this.summary.amountTransaction = response.data.amountTransaction
      this.summary.amountUnpaid = response.data.amountUnpaid
      this.summary.numberUsers = response.data.numberUsers
      this.summary.numbersPix = response.data.numbersPix

      if (this.timelineItems.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem transações.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    hasAnyAuthorization(roles) {
      return isSomeAuthorization(roles);
    },
  }
}
</script>
<style lang="scss">
.dzs-transactions {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .el-table__cell {
    font-size: 12px !important;
  }
}
</style>
