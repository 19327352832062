<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Realizar Distribuição dos Valores de Máquina de Cartão
          </h4>
          <div class="row">
            <div class="col-md-3">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <fg-input label="Adquirente">
                <el-select size="large"
                           placeholder="Selecione"
                           v-model="acquirerName">
                  <el-option v-for="option in acquirerList"
                             class="select-default"
                             :value="option"
                             :label="option"
                             :key="option">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-3">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR PAGAMENTOS
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="listPayments.length>0">
        <card>
          <div class="card row">
            <div class="col-sm-12">
              <h5 slot="header" class="card-title">
                Pagamentos
              </h5>
              <el-table class="table-striped"
                        :data="listPayments"
                        :summary-method="getTotal"
                        show-summary
                        style="width: 100%">
                <el-table-column :min-width="80" label="Id Pagamento">
                  <template slot-scope="props">
                          <span>
                            <span><b>#{{props.row.id_pagamento}}</b></span>
                          </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Data">
                  <template slot-scope="props">
                          <span>
                            <span>{{props.row.data_hora}}</span>
                          </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Dia">
                  <template slot-scope="props">
                          <span>
                            <span>{{props.row.dia_semana}}</span>
                          </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Status">
                  <template slot-scope="props">
                          <span>
                            <span>{{props.row.status}}</span>
                          </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Banco">
                  <template slot-scope="props">
                          <span>
                            <span>{{props.row.nome_banco}}</span>
                          </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Valor">
                  <template slot-scope="props">
                          <span>
                            <span>R$ {{toMoney(props.row.valor)}}</span>
                          </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card row">
            <div class="card-footer text-center">
              <p-button @click.prevent="requestTransactions" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> ATUALIZAR TRANSAÇÕES
              </p-button>
            </div>
            <div class="col-sm-12" v-show="showTransactions">
              <p>Número total de transações: {{listTransactionsSumary.numberTransactions}}</p>
              <p>Número de transações pagas: {{listTransactionsSumary.numberPaidTransactions}}</p>
              <p>Número de transações não pagas: {{listTransactionsSumary.numberUnpaidTransactions}}</p>
              <p>Valor total de transações: R$ {{toMoney(listTransactionsSumary.netValueTransactions)}}</p>
              <p>Valor de transações pagas: R$ {{toMoney(listTransactionsSumary.netValuePaidTransactions)}}</p>
              <p>Valor de transações não pagas: R$ {{toMoney(listTransactionsSumary.netValueUnpaidTransactions)}}</p>
            </div>
            <div class="card-footer text-center" v-show="showTransactions">
              <p-button @click.prevent="distributeValues" style="margin-top: 24px">
                <i class="fas fa-arrow-up"></i> DISTRIBUIR VALORES
              </p-button>
            </div>
            <div class="col-sm-12" v-show="showDistributeValues">
              <p>Número total de transações: {{distributeValuesSumary.numberOfTransactios}}</p>
              <p>Valor lucro: R$ {{toMoney(distributeValuesSumary.valueProfit)}}</p>
              <p>Valor custo transações: R$ {{toMoney(distributeValuesSumary.valueCost)}}</p>
              <p>Valor distribuído usuários: R$ {{toMoney(distributeValuesSumary.valueUser)}}</p>
              <p>Valor distribuído parceiros: R$ {{toMoney(distributeValuesSumary.valuePartner)}}</p>
              <p>Valor total distribuído: R$ {{toMoney(distributeValuesSumary.amount)}}</p>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
  import {getTransactionName, getTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {callWs, failWs, postWs} from "src/ws.service"
  import {formatDate} from "src/util/date.utils"
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      TimeLine,
      TimeLineItem,
      Badge
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        listPayments: [],
        acquirerList: [],
        acquirerName: '',
        listTransactionsSumary: {
          numberTransactions: 0,
          numberPaidTransactions: 0,
          numberUnpaidTransactions: 0,
          numberPaidPreviousTransactions: 0,
          netValueTransactions: 0,
          netValuePaidTransactions: 0,
          netValueUnpaidTransactions: 0,
          netValuePaidPreviousTransactions: 0,
        },
        distributeValuesSumary: {
          valueProfit: 0,
          valueUser: 0,
          valuePartner: 0,
          numberOfTransactios: 0,
          amount: 0,
        },
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        showTransactions: false,
        showDistributeValues: false,
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      toMoney,
      loadData() {
        callWs("/bo/card-terminal/get-acquirers",
          "GET", null, true, null,null,
          this.loadDataSuccess,
          (error) => {
            swal({
              title: 'Falha!',
              text: 'Falha ao buscar adquirentes!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      },
      loadDataSuccess(response) {
        this.acquirerList = response.data
      },
      search() {
        if(isNullOrEmpty(this.acquirerName)){
          swal({
            title: 'Aviso!',
            text: 'Favor escolher uma Adquirente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/bo-card-terminal/request-payment-acquirer",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            acquirerName: this.acquirerName
          },
          this.loadListPayments,
          this.failListPayments)
      },
      loadListPayments(response) {
        this.listPayments = response.data.list
        if (this.listPayments.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'No período selecionado não existem pagamentos.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      failListPayments(error) {
        let text = 'Erro ao carregar dados de pagamentos!'
        if(error.response.status == '412') {
          text = 'Erro de comunicação com a adquirente!'
        }
        swal({
          title: 'Erro',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'error'
        })
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      getTotal(param) {
        const { columns } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total'
          } else if (index < columns.length - 1) {
            sums[index] = ''
          } else {
            let sum = 0
            this.listPayments.forEach((obj) => {
              sum += obj.valor
            })
            sums[index] = `R$ ${sum}`
          }
        })
        return sums
      },
      requestTransactions() {
        if(isNullOrEmpty(this.acquirerName)){
          swal({
            title: 'Aviso!',
            text: 'Favor escolher uma Adquirente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/bo-card-terminal/request-payment-transactions",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            acquirerName: this.acquirerName
          },
          this.loadTransactions,
          this.failTransactions)
      },
      loadTransactions(response) {
        this.listTransactionsSumary = response.data
        this.showTransactions = true
      },
      failTransactions(error) {
        let text = 'Erro ao carregar dados das transações!'
        if(error.response.status == '412') {
          text = 'Erro de comunicação com a adquirente!'
        }
        swal({
          title: 'Erro',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'error'
        })
      },
      distributeValues() {
        if(isNullOrEmpty(this.acquirerName)){
          swal({
            title: 'Aviso!',
            text: 'Favor escolher uma Adquirente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/bo-card-terminal/distributed-values",
          "POST", null, true, {acquirerName: this.acquirerName}, null,
          this.loadDistributeValuess,
          this.failDistributeValues)
      },
      loadDistributeValuess(response) {
        this.distributeValuesSumary = response.data
        this.showDistributeValues = true
      },
      failDistributeValues(error) {
        let text = 'Erro ao distribuir valores!'
        swal({
          title: 'Erro',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'error'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }
  }
</style>
