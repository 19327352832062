<template>
  <div class="chekout-paymentlink">
    <app-header></app-header>
    <div class="page">
      <div class="content" style="background: #f4f3ef;">
        <div class="row" style="width: 100%;">
          <div class="col-sm-1"></div>
          <div class="col-sm-6" v-show="errorForm">
            <card style="margin-top: 38px;">
              <h5>Não foi possível abrir o link de pagamento!</h5>
              <p>{{error.message}}</p>
              <div class="row">
                <div class="col-12" style="text-align: center;">
                  <img src="/static/img/paymentlink/celular_link.png" width="350px"/>
                </div>
                <div class="col-12" style="text-align: center; padding-top: 40px; padding-bottom: 20px;">
                  <a href="https://suitpay.app" target="_blank" style="color: #0c082e"><b>Clique aqui e conheça mais sobre a SuitPay!</b></a>
                </div>
              </div>
            </card>
          </div>
          <div class="col-sm-6" v-show="!errorForm">

            <div class="row">
              <div class="col-md-4 col-4 topics-active"><span class="topics-number-active">1</span><br><b>Identifique-se</b></div>
              <div class="col-md-4 col-4 topics" :class="{'topics-active':addressForm == true || pixForm == true || boletoForm == true || cardForm == true || addressEditForm == true || paymentForm == true || cardFormSuccess == true}">
                <span class="topics-number" :class="{'topics-number-active':addressForm == true || pixForm == true || boletoForm == true || cardForm == true || addressEditForm == true || paymentForm == true || cardFormSuccess == true}">2</span><br><b>Endereço</b></div>
              <div class="col-md-4 col-4 topics" :class="{'topics-active':paymentForm == true || pixForm == true || boletoForm == true || cardForm == true || cardFormSuccess == true}">
                <span class="topics-number" :class="{'topics-number-active':paymentForm == true || pixForm == true || boletoForm == true || cardForm == true || cardFormSuccess == true}">3</span><br><b>Pagamento</b></div>
            </div>
            <br>
            <card v-show="clientForm == false && addressForm == false && addressEditForm == false && pixForm == false && boletoForm == false && cardForm == false && paymentForm == false && cardFormSuccess == false">
              <h5>Identifique-se</h5>
              <p>Para lhe mantermos atualizado sobre o status do pagamento, informe seu e-mail abaixo:</p>
              <fg-input v-model="client.email"
                        v-validate="validations.email" name="email"
                        :error="getError('email')"
                        label="E-mail"
                        data-vv-as="E-mail">
              </fg-input>
              <p-button class="button" style="margin-top: 25px"
                        @click="loadClientForm">
                Continuar
              </p-button>
            </card>
<!--            <p>{{requestPayment.deviceInfo.httpAcceptBrowserValue}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpAcceptContent}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserLanguage}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserJavaEnabled}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserJavaScriptEnabled}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserColorDepth}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserScreenHeight}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserScreenWidth}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.httpBrowserTimeDifference}}</p>-->
<!--            <p>{{requestPayment.deviceInfo.userAgentBrowserValue}}</p>-->
<!--            <p>{{requestPayment.urlSite3DS}}</p>-->
            <card v-show="clientForm == true && addressForm == false && paymentForm == false && addressEditForm == false">
              <p>Para realizar seu pagamento com segurança utilizando a SuitPay, preencha os campos abaixo:</p>
              <div class="row">
                <div class="col-md-12">
                  <fg-input v-model="client.email"
                            v-validate="validations.email" name="email"
                            :error="getError('email')"
                            label="E-mail"
                            placeholder="E-mail" data-vv-as="E-mail" disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Nome"
                            maxlength="70"
                            name="Nome"
                            v-validate="{required: clientForm}"
                            :error="getError('Nome')"
                            v-model="client.name">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="CPF/CNPJ"
                            type="tel"
                            v-mask="['###.###.###-##','##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-validate="{cpfCnpjValid: true, required: clientForm, min: 14}"
                            :error="getError('CPF/CNPJ')"
                            v-model="client.document">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="tel"
                            label="Telefone"
                            v-mask="['(##) #####-####']"
                            name="Telefone"
                            v-validate="{required: clientForm, min: 15}"
                            :error="getError('Telefone')"
                            v-model="client.phoneNumber"
                            key="telefone">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p-button class="back" style="margin-top: 25px"
                            @click="goBackEmail">
                    <i class="fa-solid fa-rotate-left"></i> Voltar
                  </p-button>
                </div>
                <div class="col-md-6">
                  <p-button class="button" style="margin-top: 25px"
                            @click="loadAddressForm">
                    Continuar
                  </p-button>
                </div>
              </div>
            </card>
            <card v-show="addressEditForm == true && paymentForm == false && pixForm == false && boletoForm == false && cardForm == false && cardFormSuccess == false">
              <h5>Endereço</h5>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            name="CEP"
                            v-validate="{required: addressEditForm, min: 9}"
                            :error="getError('CEP')"
                            v-on:blur="this.searchZipCode"
                            v-model="client.address.zipCode"
                            key="cep">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.logradouro  != '')"
                            label="Rua/Av."
                            name="Rua/Av."
                            v-validate="{required: addressEditForm}"
                            :error="getError('Rua/Av.')"
                            v-model="client.address.street"
                            key="rua">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            name="Número"
                            v-validate="{required: addressEditForm}"
                            :error="getError('Número')"
                            v-model="client.address.number"
                            key="numero">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="40"
                            label="Complemento"
                            v-model="client.address.complement"
                            key="complemento">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.bairro != '')"
                            label="Bairro"
                            name="Bairro"
                            v-validate="{required: addressEditForm}"
                            :error="getError('Bairro')"
                            v-model="client.address.neighborhood"
                            key="bairro">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            disabled="true"
                            v-model="client.address.city"
                            key="cidade">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            disabled="true"
                            placeholder=""
                            v-model="client.address.state"
                            key="estado">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p-button class="back" style="margin-top: 25px"
                            @click="goBackClient">
                    <i class="fa-solid fa-rotate-left"></i> Voltar
                  </p-button>
                </div>
                <div class="col-md-6">
                  <p-button class="button" style="margin-top: 25px"
                            @click="loadPaymentForm">
                    Continuar
                  </p-button>
                </div>
              </div>
            </card>
            <card v-show="addressForm == true && addressEditForm == false && paymentForm == false && pixForm == false && boletoForm == false && cardForm == false && cardFormSuccess == false">
              <h5>Endereço</h5>
              <p>Identificamos que você já possui um cadastro na SuitPay, verifique se seu endereço está correto para continuar.</p>
              <div class="row" style="margin: 10px;">
                <div class="col-md-12" style="border: solid 1px #0c072d36;border-radius: 10px;padding: 15px;background: #0c072d17;">
                  <p>{{requestPayment.client.zipCode}}</p>
                  <p>{{requestPayment.client.street}}, {{requestPayment.client.number}}</p>
                  <p>{{requestPayment.client.complement}}</p>
                  <p>{{requestPayment.client.neighborhood}}</p>
                  <p>{{requestPayment.client.city}}, {{requestPayment.client.state}}</p>
                  <p style="right: 0; top: 0; position: absolute; margin: 10px"><a href="#" @click="loadEditAddress">Editar</a></p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p-button class="back" style="margin-top: 25px"
                            @click="goBackEmail">
                    <i class="fa-solid fa-rotate-left"></i> Voltar
                  </p-button>
                </div>
                <div class="col-md-6">
                  <p-button class="button" style="margin-top: 25px"
                            @click="loadPaymentForm">
                    Continuar
                  </p-button>
                </div>
              </div>
            </card>
            <card v-show="paymentForm == true">
              <h5>Pagamento</h5>
              <p>Escolha a melhor forma para realizar o seu pagamento:</p>
              <div class="row">
                <div class="col-md-12">
                  <p-button class="button-pay"
                            @click="generateCard">
                    <i slot="label" class="fa-solid fa-credit-card  fa-3x" style="margin-right: 10px"></i>
                    <span>Cartão de Crédito <br>
                      <small>
                        (em até 12 vezes)
                      </small>
                    </span>
                  </p-button>
                </div>
                <div class="col-md-12">
                  <p-button class="button-pay"
                            @click="generatePix">
                    <div>
                      <i slot="label" class="fa-brands fa-pix fa-3x" style="margin-right: 10px"></i>
                    </div>
                    <span>Pix<br>
                      <small>
                        (pagamento aprovado na hora)
                      </small>
                    </span>
                  </p-button>
                </div>
                <div class="col-md-12">
                  <p-button class="button-pay"
                            @click="generateBoleto">
                    <div>
                      <i slot="label" class="fa-solid fa-barcode fa-3x" style="margin-right: 10px"></i>
                    </div>
                    <span>Boleto<br>
                      <small>
                        (pagamento em até 2 dias úteis)
                      </small>
                    </span>
                  </p-button>
                </div>
                <div class="col-md-12">
                  <p-button class="back" style="margin-top: 25px"
                            @click="goBackAddress">
                    <i class="fa-solid fa-rotate-left"></i> Voltar
                  </p-button>
                </div>
              </div>
            </card>
            <card v-show="pixForm == true">
              <h5 style="text-align: center">QR Code Pix gerado com sucesso!</h5>
              <p  style="text-align: center">Agora é só fazer o pagamento no aplicativo do seu banco.</p>
              <div class="row">
                <div class="col-md-12" style="text-align: center;">
                  <qrcode :value="pix.brCode" :options="{ width: 200, margin:0 }"></qrcode>
                  <p><br>Vencimento: <br><b>{{pix.dueDate}}</b></p>
                  <p-button class="button" style="margin-top: 25px"
                            @click="copyQrCode">
                    <i class="fa-solid fa-qrcode"></i> Copiar Código
                  </p-button>
                  <div class="col-md-12" style="text-align: justify; padding-top: 60px">
                    <p style="border: solid 1px #00d19a;border-radius: 10px;padding: 15px;background: #00d19a; color:#fff"><b>Atenção:</b> A razão social e CNPJ da SuitPay aparecerão como destinatário
                      desta transação, pois somos uma processadora de pagamentos autorizada e seguimos todas as regras de compliance do Banco Central.
                      Não comercializamos ou temos responsabilidade sobre a venda de nenhum tipo de produto físico ou serviço.</p>
                  </div>
                  <div class="col-md-12">
                    <p-button class="back" style="margin-top: 25px"
                              @click="goBackPayments">
                      <i class="fa-solid fa-rotate-left"></i> Voltar
                    </p-button>
                  </div>
                </div>
              </div>
            </card>
            <card v-show="boletoForm == true">
              <h5 style="text-align: center">Boleto gerado com sucesso!</h5>
              <p style="text-align: center">Agora é só copiar o código de barras e fazer o pagamento no aplicativo do seu banco.</p>
              <div class="row">
                <div class="col-md-12">
                  <p style="text-align: center; font-size: 16px">
                    <b>Valor:</b> R$ {{toMoney(boleto.value)}} | <b>Vencimento:</b> {{boleto.dueDate}}
                  </p>
                  <p style="text-align: center; margin: 0; font-size: 18px">
                    <b style="font-size: 16px">Código de Barras:</b><br>
                    {{boleto.digitableLine}}
                  </p>
<!--                  <barcode width="1" height="100" format="ITF" display-value="false"-->
<!--                           v-bind:value="boleto.barcode"></barcode>-->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-12" style="text-align: center; padding-bottom: 20px; padding-top: 30px">
                  <p-button @click="copyDigitableLine" class="button" style="margin: 0 auto">
                    <i class="fa-solid fa-barcode"></i> Copiar Código de Barras
                  </p-button>
                </div>
                <div class="col-md-12 col-12" style="text-align: center; padding-bottom: 20px">
                  <p-button @click="printBoleto" class="button" style="margin: 0 auto">
                    <i class="fas fa-print"/> Imprimir Boleto
                  </p-button>
                </div>
              </div>
              <div class="col-md-12" style="text-align: justify; padding-top: 60px">
                <p style="border: solid 1px #00d19a;border-radius: 10px;padding: 15px;background: #00d19a; color:#fff"><b>Atenção:</b> A razão social e CNPJ da SuitPay aparecerão como destinatário
                  desta transação, pois somos uma processadora de pagamentos autorizada e seguimos todas as regras de compliance do Banco Central.
                  Não comercializamos ou temos responsabilidade sobre a venda de nenhum tipo de produto físico ou serviço.<br><br>
                  O prazo de compensação do boleto é de até 2 dias úteis após o pagamento.</p>
              </div>
              <div class="col-md-12">
                <p-button class="back" style="margin-top: 25px"
                          @click="goBackPayments">
                  <i class="fa-solid fa-rotate-left"></i> Voltar
                </p-button>
              </div>
            </card>
            <card v-show="cardForm == true">
              <h5 style="text-align: center">Cartão de Crédito</h5>
              <p style="text-align: center">Insira os dados do cartão para finalizar o pagamento.</p>
              <div class="row">
                <div class="col-md-12" style="width: 100vw; display: flex;flex-direction: row;justify-content: center;align-items: center;">
                  <div class="container-card">
                    <img id="image" src="/static/img/creditcard/credit_card_checkout.png" width="400px"/>
                    <small style="position: absolute; font-size: 10px; left: 30px; top: 48%; color: #f0f0f0;">Nome:</small>
                    <small v-show="creditCard.name.length > 0" style="position: absolute; font-size: 13px; left: 30px; top: 53%; color: #f0f0f0;"><b>{{creditCard.name}}</b></small>
                    <small style="position: absolute; font-size: 10px; left: 30px; top: 63%; color: #f0f0f0;">Número:</small>
                    <small v-show="creditCard.number.length > 0" style="position: absolute; font-size: 13px; left: 30px; top: 68%; color: #f0f0f0;"><b>{{creditCard.number}}</b></small>
                    <small v-show="creditCard.number.length == 0" style="position: absolute; font-size: 13px; left: 30px; top: 68%; color: #f0f0f0;"><b>**** **** **** ****</b></small>
                    <small style="position: absolute; font-size: 10px; left: 30px; top: 78%; color: #f0f0f0;">Validade:</small>
                    <small style="position: absolute; font-size: 13px; left: 30px; top: 83%; color: #f0f0f0;"><b>{{creditCard.expirationMonth}}/{{creditCard.expirationYear}}</b></small>
                    <small style="position: absolute; font-size: 10px; left: 130px; top: 78%; color: #f0f0f0;">Código de Segurança:</small>
                    <small v-show="creditCard.cvv.length > 0" style="position: absolute; font-size: 13px; left: 130px; top: 83%; color: #f0f0f0;"><b>{{creditCard.cvv}}</b></small>
                    <small v-show="creditCard.cvv.length == 0" style="position: absolute; font-size: 13px; left: 130px; top: 83%; color: #f0f0f0;"><b>***</b></small>
                    <img style="position: absolute;width: 60px;left: 20px;top: 9%;"
                         :src="'/static/img/creditcard/' + cardType + '.png'"
                         v-if="cardType"
                         :key="cardType"
                    />
                  </div>
                </div>
                <div class="col-md-12" style="padding-top: 20px">
                  <fg-input type="text"
                            label="Nome"
                            maxlength="70"
                            name="Nome"
                            v-validate="{required: cardForm}"
                            :error="getError('Nome')"
                            v-model="creditCard.name"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="tel"
                            v-mask="[cardType=='amex'?'#### ###### #####':cardType=='dinersclub'?'#### ###### ####':'#### #### #### ####']"
                            label="Número do Cartão"
                            name="Número do Cartão"
                            v-validate="{required: cardForm, min: 16}"
                            :error="getError('Número do Cartão')"
                            v-model="creditCard.number"
                            key="numero">
                  </fg-input>
                </div>
                <div class="col-sm-3">
                  <fg-input label="Validade(Mês)">
                    <el-select
                      size="large"
                      v-model="creditCard.expirationMonth"
                      placeholder="Mês">
                      <el-option
                        class="select-default"
                        v-for="item in month"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-sm-3">
                  <fg-input label="Validade(Ano)">
                    <el-select
                      size="large"
                      v-model="creditCard.expirationYear"
                      placeholder="Ano">
                      <el-option
                        class="select-default"
                        v-for="item in year"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['####']"
                            label="Código de Segurança"
                            name="Código de Segurança"
                            v-validate="{required: cardForm, min: 3}"
                            :error="getError('Código de Segurança')"
                            v-model="creditCard.cvv"
                            key="cvv">
                  </fg-input>
                </div>
                <div class="col-sm-8">
                  <fg-input label="Parcelas">
                    <el-select
                      size="large"
                      v-model="creditCard.installments.installment"
                      placeholder="Parcelas">
                      <el-option
                        class="select-default"
                        v-for="item in installments"
                        :key="item.installment"
                        :label="item.installment==1?item.installment+'x de R$ '+toMoney(item.valueInstallment)+' (R$ '+toMoney(item.amount)+' sem juros)':
                        item.installment+'x de R$ '+toMoney(item.valueInstallment)+' (R$ '+toMoney(item.amount)+' com juros)'"
                        :value="item.installment">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <p-button class="back" style="margin-top: 25px"
                            @click="goBackPayments">
                    <i class="fa-solid fa-rotate-left"></i> Voltar
                  </p-button>
                </div>
                <div class="col-md-6">
                  <p-button class="button" style="margin-top: 25px"
                            @click="pagar" :disabled="buttonDisabled">
                    <i class="fa-solid fa-credit-card"></i> Pagar
                  </p-button>
                </div>
              </div>
            </card>
            <card v-show="cardFormSuccess == true">
              <p style="text-align: center; color: #00daa1"><i class="fa-solid fa-circle-check fa-5x"></i></p>
              <h3 style="text-align: center; color: #00daa1">Sucesso!</h3>
              <p style="text-align: center">O seu pagamento está em processamento!</p>
              <div class="row">
                <div class="col-md-12" style="text-align: center">
                  <p>Subtotal: <b>R$ {{toMoney(amount)}}</b></p>
                  <p>Pago em: <b>{{requestPayment.card.installment}}x de R$ {{toMoney(requestPayment.card.valueInstallment)}}</b></p>
                  <p>Total: <b>R$ {{toMoney(requestPayment.card.amount)}}</b></p>
                </div>
                <div class="col-md-12" style="width: 100vw; display: flex;flex-direction: row;justify-content: center;align-items: center;">
                  <p style="border: solid 1px #00d19a;border-radius: 10px;padding: 15px;background: #00d19a; color:#fff"><b>Atenção:</b> A razão social e CNPJ da SuitPay aparecerão como destinatário
                    desta transação, pois somos uma processadora de pagamentos autorizada e seguimos todas as regras de compliance do Banco Central.
                    Não comercializamos ou temos responsabilidade sobre a venda de nenhum tipo de produto físico ou serviço.</p>
                </div>
              </div>
            </card>
          </div>

          <div class="col-sm-4" style="background-color: #00d19a; border-radius: 25px; padding: 2%; margin: 1%">
            <p style="color: #fff;text-align: center">Solicitação de Pagamento Enviada por: <br><b>{{nameUser}}</b></p>
            <card>
              <h5 style="text-align: center">Resumo do Pedido</h5>
              <div class="col-sm-12 col-12">
                <el-table class="table-striped"
                          :data="products"
                          :summary-method="getSummaries"
                          show-summary
                          style="width: 100%">
                  <el-table-column :min-width="140" label="Descrição">
                    <template slot-scope="props">
                        <span>
                          <span>{{props.row.description.toUpperCase()}}</span>
                        </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="90" label="Valor">
                    <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.value)}} x {{props.row.quantity}}</span>
                        </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="70" label="Total">
                    <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.amount)}}</span>
                        </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </card>
            <div style="color: #fff; text-align: center">
              <br>
              <icon style="font-size: 30px" class="fa-solid fa-shield-halved"></icon>
              <p>Você está em um<br>
              <b>Ambiente Seguro</b><br>
              SSL Criptografia 128 bits.</p>

              <img id="image" src="/static/img/creditcard/card_flags.png" width="300px"/>

              <p>Transação processada no Brasil.</p>
            </div>
          </div>

          <div class="col-sm-1"></div>
        </div>
      </div>
    </div>
    <input type="hidden" id="qrcode-copy" :value="pix.brCode">
    <input type="hidden" id="digitableLine-copy" :value="boleto.digitableLine">
    <input type="hidden" id="ccnum" :value="creditCard.number.replace(/\s/g, '')">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {postWs, getWs, signin} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {Table, TableColumn} from "element-ui"
  import {toMoney, moneyToFloat, isNullOrEmpty, isValidCnpj, isValidCpf, headScript, bodyScript} from "src/util/core.utils"
  import {formatDate} from "src/util/date.utils"
  import AppHeader from '../Pages/Layout/AppHeader'
  import AppFooter from '../Pages/Layout/AppFooter'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import nprogress from "nprogress";

  Vue.use(Table)
  Vue.use(TableColumn)
  export default {
    components: {
      AppHeader,
      AppFooter,
      VueReCaptcha
    },
    data() {
      return {
        clientForm: false,
        addressForm: false,
        addressEditForm: false,
        paymentForm: false,
        pixForm: false,
        boletoForm: false,
        cardForm: false,
        errorForm: false,
        cardFormSuccess: false,
        key: '',
        products: [],
        amount: 0,
        nameUser: '',
        client: {
          email: '',
          name: '',
          document: '',
          phoneNumber: '',
          address: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          }
        },
        validations: {
          email: {
            required: true,
            min: 5,
            email: true,
            ok: false
          },
        },
        viacep: {
          logradouro: '',
          bairro: '',
          ibge: '',
          localidade: '',
          uf: '',
        },
        requestPayment: {
          idPaymentLink: '',
          client: {
            id: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            codIbge: '',
            name: '',
            socialNumber: '',
            phoneNumber: '',
            email: '',
            city: '',
            state: '',
          },
          card: {
            number: '',
            expirationMonth: '',
            expirationYear: '',
            cvv: '',
            installment: 0,
            valueInstallment: 0,
            amount: 0,
          },
          recaptchaToken: '',
          codeAntiFraud: '',
          urlSite3DS: '',
          code3DS: '',
          validateToken: '',
          deviceInfo: {
            httpAcceptBrowserValue: null,
            httpAcceptContent: null,
            httpBrowserLanguage: navigator.language,
            httpBrowserJavaEnabled: this.isJavaPluginAvailable(),
            httpBrowserJavaScriptEnabled: this.isJavaScriptEnabled(),
            httpBrowserColorDepth: screen.colorDepth,
            httpBrowserScreenHeight: screen.height,
            httpBrowserScreenWidth: screen.width,
            httpBrowserTimeDifference: 0,
            userAgentBrowserValue: navigator.userAgent,
          }
        },
        pix: {
          brCode: '',
          dueDate: '',
        },
        boleto: {
          id: '',
          value: '',
          digitableLine: '',
          barcode: '',
          dueDate: '',
          errorMessage: '',
          base64: null
        },
        creditCard: {
          number: '',
          expirationMonth: '01',
          expirationYear: '',
          cvv: '',
          name: '',
          installments: {
            installment: 1,
            amount: 0,
            valueInstallment: 0,
          },
        },
        installments: [{
          installment: '',
          amount: 0,
          valueInstallment: 0,
        }],
        month: ['12', '11', '10', '09', '08', '07', '06', '05', '04', '03', '02', '01'],
        year: [],
        error: {
          response: '',
          message: ''
        },
        urlAntiFraud: 'https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=adiq_br',
        threeDs: '',
        buttonDisabled: false,
        paymentAttempts: 0,
        maxPaymentAttempts: 2,
        cardNumberAttempt: '',
      }
    },

    mounted() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Edge/')) {
        this.requestPayment.deviceInfo.httpAcceptBrowserValue = 'application/json, text/plain, */*';
      } else if (userAgent.includes('Firefox/')) {
        this.requestPayment.deviceInfo.httpAcceptBrowserValue = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8';
      } else if (userAgent.includes('Chrome/')) {
        this.requestPayment.deviceInfo.httpAcceptBrowserValue = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3';
      } else {
        this.requestPayment.deviceInfo.httpAcceptBrowserValue = '*/*';
      }

      const mimeTypes = navigator.mimeTypes;
      const supportedTypes = [];
      for (let i = 0; i < mimeTypes.length; i++) {
        supportedTypes.push(mimeTypes[i].type);
      }
      this.requestPayment.deviceInfo.httpAcceptContent = supportedTypes.join(', ');

      const now = new Date();
      const gmtTime = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);
      const localTime = new Date();
      const diff = (gmtTime.getTime() - localTime.getTime()) / (1000 * 60);
      this.requestPayment.deviceInfo.httpBrowserTimeDifference = Math.abs(diff);

      this.requestPayment.urlSite3DS = window.location.href.replace(/^https?:\/\//,'');

      this.recaptcha()
    },
    created() {
      this.key = this.$route.params.key
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
      let currentYear = moneyToFloat(formatDate(new Date(), 'YYYY'))
      this.creditCard.expirationYear = currentYear
      for(let i=14; i>=0; i--) {
        this.year.push(currentYear+i)
      }
      this.loadData()
    },
    computed: {
      cardType () {
        let number = this.creditCard.number
        let re = new RegExp('^4')
        if (number.match(re) != null) return 'visa'

        re = new RegExp('^(34|37)')
        if (number.match(re) != null) return 'amex'

        re = new RegExp('^5[1-5]')
        if (number.match(re) != null) return 'mastercard'

        re = new RegExp('^6011')
        if (number.match(re) != null) return 'discover'

        re = new RegExp('^62')
        if (number.match(re) != null) return 'unionpay'

        re = new RegExp('^9792')
        if (number.match(re) != null) return 'troy'

        re = new RegExp('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}')
        if (number.match(re) != null) return 'dinersclub'

        re = new RegExp('^35(2[89]|[3-8])')
        if (number.match(re) != null) return 'jcb'

        re = new RegExp('^((509091)|(636368)|(636297)|(504175)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(50990[0-2])|(5099[7-9][0-9])|(50996[4-9])|(509[1-8][0-9][0-9])|(5090(0[0-2]|0[4-9]|1[2-9]|[24589][0-9]|3[1-9]|6[0-46-9]|7[0-24-9]))|(5067(0[0-24-8]|1[0-24-9]|2[014-9]|3[0-379]|4[0-9]|5[0-3]|6[0-5]|7[0-8]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(6504(8[5-9]|9[0-9])|6505(0[0-9]|1[0-9]|2[0-9]|3[0-8]))|(6505(4[1-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(65072[0-7])|(6509(0[1-9]|1[0-9]|20))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[0-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8])))')
        if (number.match(re) != null) return 'elo'

        re = new RegExp('^(606282\\d{10}(\\d{3})?)|(3841\\d{15})$')
        if (number.match(re) != null) return 'hiper'

        return '' // default type
      },
    },
    methods: {
      async pagar() {
        this.buttonDisabled = true;

        if(this.cardNumberAttempt == this.creditCard.number && this.paymentAttempts >= this.maxPaymentAttempts) {
          swal({
            title: 'Aviso!',
            text: 'Tentativas de pagamento com o mesmo cartão excedidas. Tente realizar o pagamento com outro cartão de crédito!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          this.buttonDisabled = false;
          return
        } else {
          this.cardNumberAttempt = this.creditCard.number
          this.paymentAttempts++

          this.threeDs = new Adiq3ds("ccnum", this.validateChallengeCallback);
          await this.threeDs.Authorization3ds();

          this.requestPayment.code3DS = this.threeDs.getThreeDsCode();
          this.requestCard();
        }
      },

      validateChallengeCallback(jwt, statusChallenge) {
        if (statusChallenge == 'Cancelled') {
          alert('O desafio foi cancelado pelo usuário.');
          return;
        }

        //recebe a resposta do desafio
        //levar o jwt para o backend para ser informado no api Validate do gateway
        this.requestPayment.validateToken = jwt
        postWs("/payment-link/validate",
          true, null, this.requestPayment, this.successCard, this.errorPaymentCard)
      },

      isJavaPluginAvailable() {
        for (let i = 0; i < navigator.plugins.length; i++) {
          if (navigator.plugins[i].name.indexOf('Java') !== -1) {
            return 'Y';
          }
        }
        return 'N';
      },
      isJavaScriptEnabled() {
        if (window) {
          return 'Y';
        }
        return 'N';
      },
      toMoney,
      loadData() {
        getWs('/payment-link/get-sale', false,
          {id: this.key}, this.loadSuccess, this.loadFail)
      },
      loadSuccess(response) {
        this.products = response.data.products
        this.nameUser = response.data.nameUser
        this.amount = response.data.amount
        this.installments = response.data.installments
        this.requestPayment.codeAntiFraud = response.data.codeAntiFraud
        this.urlAntiFraud = this.urlAntiFraud+response.data.codeAntiFraud
        headScript(this.urlAntiFraud)
        bodyScript(this.urlAntiFraud)
      },
      loadFail(error) {
        this.errorForm = true
        this.error.message = error.response.data.message
        this.error.response = error.response.data.response
      },
      getSummaries(param) {
        const {columns} = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total'
          } else if (index === 2) {
            sums[index] = 'R$ ' + toMoney(this.amount)
          } else {
            sums[index] = ''
          }
        })
        return sums
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      loadClientForm() {
        this.requestPayment.client.id = ''
        this.requestPayment.client.street = ''
        this.requestPayment.client.number = ''
        this.requestPayment.client.complement = ''
        this.requestPayment.client.zipCode = ''
        this.requestPayment.client.neighborhood = ''
        this.requestPayment.client.codIbge = ''
        this.requestPayment.client.name = ''
        this.requestPayment.client.socialNumber = ''
        this.requestPayment.client.phoneNumber = ''
        this.requestPayment.client.email = ''
        this.requestPayment.client.city = ''
        this.requestPayment.client.state = ''

        this.requestPayment.card.number = ''
        this.requestPayment.card.expirationMonth = ''
        this.requestPayment.card.expirationYear = ''
        this.requestPayment.card.cvv = ''
        this.requestPayment.card.installment = 0

        this.client.address.street = ''
        this.client.address.number = ''
        this.client.address.complement = ''
        this.client.address.zipCode = ''
        this.client.address.neighborhood = ''
        this.client.address.codIbge = ''
        this.client.name = ''
        this.client.document = ''
        this.client.phoneNumber = ''
        this.client.address.city = ''
        this.client.address.state = ''
        this.$validator.validate('email').then(isValid => {
          if (!isValid) {
            return
          }
          this.doRecaptcha('sp_lp_loadclient')
        })
      },
      onCaptchaVerifiedLoadClient(recaptchaToken) {
        getWs('/payment-link/get-client', false,
          {email: this.client.email, recaptchaToken: recaptchaToken}, this.loadSuccessClient, this.loadFailClient)
      },
      loadSuccessClient(response) {
        this.requestPayment.client.street = response.data.street
        this.requestPayment.client.number = response.data.number
        this.requestPayment.client.complement = response.data.complement
        this.requestPayment.client.zipCode = response.data.zipCode
        this.requestPayment.client.neighborhood = response.data.neighborhood
        this.requestPayment.client.city = response.data.city
        this.requestPayment.client.state = response.data.state
        this.requestPayment.client.id = response.data.id
        this.requestPayment.client.name = response.data.name
        this.addressForm = true
      },
      loadFailClient(error) {
        this.requestPayment.client.id = ''
        this.requestPayment.client.street = ''
        this.requestPayment.client.number = ''
        this.requestPayment.client.complement = ''
        this.requestPayment.client.zipCode = ''
        this.requestPayment.client.neighborhood = ''
        this.requestPayment.client.codIbge = ''
        this.requestPayment.client.name = ''
        this.requestPayment.client.socialNumber = ''
        this.requestPayment.client.phoneNumber = ''
        this.requestPayment.client.email = ''
        this.requestPayment.client.city = ''
        this.requestPayment.client.state = ''
        this.clientForm = true
      },
      loadAddressForm() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.addressEditForm = true
          this.$validator.reset()
        })
      },
      loadPaymentForm() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.paymentForm = true
        })
      },
      searchZipCode() {
        this.viacep = null
        this.client.address.codIbge = ""
        this.client.address.city = ""
        this.client.address.state = ""
        getWs("/cep/get-cep",
          true, {cep: this.client.address.zipCode},
          (response) => {
            this.viacep = response.data
            this.client.address.codIbge = this.viacep.ibge
            if (this.viacep.logradouro.length > 40) {
              this.client.address.street = this.viacep.logradouro.substr(0, 40)
            } else {
              this.client.address.street = this.viacep.logradouro
            }
            if(this.viacep.bairro.length > 40) {
              this.client.address.neighborhood = this.viacep.bairro.substr(0, 40)
            } else {
              this.client.address.neighborhood = this.viacep.bairro
            }
            this.client.address.city = this.viacep.localidade
            this.client.address.state = this.viacep.uf
          },
          (e) => {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
            return
          })
      },
      getRequestPayment(type) {
        this.requestPayment.idPaymentLink = this.key
        if (isNullOrEmpty(this.requestPayment.client.id)) {
          this.requestPayment.client.name = this.client.name
          this.requestPayment.client.socialNumber = this.client.document
          this.requestPayment.client.phoneNumber = this.client.phoneNumber
          this.requestPayment.client.email = this.client.email
        }
        if(!isNullOrEmpty(this.client.address.codIbge)) {
          this.requestPayment.client.street = this.client.address.street
          this.requestPayment.client.number = this.client.address.number
          this.requestPayment.client.complement = this.client.address.complement
          this.requestPayment.client.zipCode = this.client.address.zipCode
          this.requestPayment.client.neighborhood = this.client.address.neighborhood
          this.requestPayment.client.codIbge = this.client.address.codIbge
        }
        if(type == 'card') {
          this.requestPayment.card.number = this.creditCard.number
          this.requestPayment.card.expirationMonth = this.creditCard.expirationMonth
          this.requestPayment.card.expirationYear = this.creditCard.expirationYear
          this.requestPayment.card.cvv = this.creditCard.cvv
          this.requestPayment.card.installment = this.creditCard.installments.installment
          this.installments.forEach(installment => {
            if(installment.installment == this.creditCard.installments.installment) {
              this.requestPayment.card.amount = installment.amount
              this.requestPayment.card.valueInstallment = installment.valueInstallment
            }
          })
        }
      },
      loadEditAddress() {
        if(this.requestPayment.client.id != null) {
          this.requestPayment.client.street = ''
          this.requestPayment.client.number = ''
          this.requestPayment.client.complement = ''
          this.requestPayment.client.zipCode = ''
          this.requestPayment.client.neighborhood = ''
          this.requestPayment.client.codIbge = ''
          this.requestPayment.client.city = ''
          this.requestPayment.client.state = ''
          this.addressEditForm = true
        }
      },
      generatePix() {
        this.getRequestPayment('pix')
        this.doRecaptcha('sp_lp_pix')
      },
      onCaptchaVerifiedPix(recaptchaToken) {
        this.requestPayment.recaptchaToken = recaptchaToken
        postWs("/payment-link/pix",
          false, null, this.requestPayment, this.successPix, this.errorPayment)
      },
      successPix(response) {
        this.pix.brCode = response.data.brCode
        this.pix.dueDate = response.data.dueDate
        this.requestPayment.client.id = response.data.idClient
        this.clientForm = false
        this.addressForm = false
        this.paymentForm = false
        this.pixForm = true
      },
      copyQrCode() {
        let digitableLineCopy = document.querySelector('#qrcode-copy')
        digitableLineCopy.setAttribute('type', 'text')
        digitableLineCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              text: 'Código Pix copiada com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              text: 'Erro ao copiar Código Pix!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar Código Pix!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        digitableLineCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      generateBoleto() {
        this.getRequestPayment('boleto')
        this.doRecaptcha('sp_lp_boleto')
      },
      onCaptchaVerifiedBoleto(recaptchaToken) {
        this.requestPayment.recaptchaToken = recaptchaToken
        postWs("/payment-link/boleto",
          true, null, this.requestPayment, this.successBoleto, this.errorPayment)
      },
      successBoleto(response) {
        this.boleto.id = response.data.id
        this.boleto.value = response.data.value
        this.boleto.digitableLine = response.data.digitableLine
        this.boleto.barcode = response.data.barcode
        this.boleto.dueDate = response.data.dueDate
        this.requestPayment.client.id = response.data.idClient
        this.clientForm = false
        this.addressForm = false
        this.paymentForm = false
        this.boletoForm = true
      },
      requestCard() {
        this.getRequestPayment('card')
        this.doRecaptcha("sp_lp_card")
      },
      onCaptchaVerifiedCard(recaptchaToken) {
        this.requestPayment.recaptchaToken = recaptchaToken
        postWs("/payment-link/card",
          true, null, this.requestPayment, this.treatEnrollmentResponse, this.errorPaymentCard)
      },
      successCard() {
        this.cardFormSuccess = true
        this.cardForm = false
      },
      treatEnrollmentResponse(objEnrollment) {
        this.buttonDisabled = false;
        switch (objEnrollment.data.status) {
          case 'ERROR': //falha na operacao
            this.errorPaymentCard(objEnrollment)
            break;
          case 'OK': //pagamento efetuado
            this.successCard()
            break;
          default: //desafio
            swal({
              text: "Para finalizar o seu pagamento, é necessário realizar uma validação junto ao seu banco." +
                "Clique em Continuar e conclua o seu pagamento!",
              type: 'warning',
              buttonsStyling: true,
              confirmButtonClass: 'btn-success btn-fill',
              showCancelButton: true,
              confirmButtonColor: '#17a2b8',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Continuar',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result) {
                this.threeDs.InitChallenge(objEnrollment.data.acsUrl, objEnrollment.data.pareq,
                  objEnrollment.data.authenticationTransactionId);
              }
            })
            break;
        }
      },
      copyDigitableLine() {
        let digitableLineCopy = document.querySelector('#digitableLine-copy')
        digitableLineCopy.setAttribute('type', 'text')
        digitableLineCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              title: 'Sucesso!',
              text: 'Código de barras copiado com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              title: 'Erro!',
              text: 'Erro ao copiar código de barras!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            title: 'Erro!',
            text: 'Erro ao copiar código de barras!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        digitableLineCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      printBoleto() {
        this.doRecaptcha('sp_lp_printboleto')
      },
      async recaptcha() {
        await this.$recaptchaLoaded()
        this.$recaptchaInstance.showBadge()
      },
      async doRecaptcha(action){
        const token = await this.$recaptcha(action)

        if(action === 'sp_lp_printboleto'){
          this.onCaptchaVerifiedPrintBoleto(token)
        } else if (action === 'sp_lp_boleto'){
          this.onCaptchaVerifiedBoleto(token)
        } else if (action === 'sp_lp_loadclient') {
          this.onCaptchaVerifiedLoadClient(token)
        } else if (action === 'sp_lp_card'){
          this.onCaptchaVerifiedCard(token)
        } else if (action === 'sp_lp_pix'){
          this.onCaptchaVerifiedPix(token)
        } else {
          alert('Ação de recaptcha não reconhecida!')
        }
      },
      onCaptchaVerifiedPrintBoleto(recaptchaToken) {
        postWs("/payment-link/boleto-pdf",
          true, {id: this.boleto.id, recaptchaToken: recaptchaToken}, null, this.successPrintBoleto, this.failPrintBoleto)
      },
      successPrintBoleto(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Boleto</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.base64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do boleto, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      failPrintBoleto(error) {
        swal({
          text: 'Erro ao imprimir boleto!'
        })
      },
      generateCard() {
        this.getRequestPayment()
        this.clientForm = false
        this.addressForm = false
        this.paymentForm = false
        this.cardForm = true
        this.creditCard.name = this.requestPayment.client.name
        this.$validator.reset()
      },
      goBackPayments() {
        this.paymentForm = true
        this.cardForm = false
        this.boletoForm = false
        this.pixForm = false
      },
      goBackAddress() {
        this.addressForm = true
        this.paymentForm = false
      },
      goBackClient() {
        if(this.addressForm == true) {
          this.clientForm = false
        } else {
          this.clientForm = true
        }
        this.addressForm = false
        this.addressEditForm = false
        this.paymentForm = false
      },
      goBackEmail() {
        this.clientForm = false
        this.addressForm = false
        this.paymentForm = false
      },
      loadPaymentCard() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.requestCard()
        })
      },
      errorPayment(error) {
        swal({
          title: 'Erro!',
          text: error.response.data.message,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'warning'
        })
      },
      errorPaymentCard(error) {
        this.buttonDisabled = false;
        this.requestPayment.codeAntiFraud = error.response.data.codeAntiFraud
        this.urlAntiFraud = this.urlAntiFraud+error.response.data.codeAntiFraud
        headScript(this.urlAntiFraud)
        bodyScript(this.urlAntiFraud)
        swal({
          title: 'Erro!',
          text: error.response.data.message,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'warning'
        })
      },
    }
  }
</script>
<style lang="scss">
  .chekout-paymentlink {
    .el-table .cell {
      font-size: 12px;
      line-height: 10px;
      padding: 0px;
    }

    .page {
      padding-top: 6%;
      background: #f4f3ef;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .topics {
      padding: 15px 0 0 0;
      text-align: center;
      font-size: 17px;
      text-transform: uppercase;
      color: #000;
      border-bottom: 2px solid #fff !important;
    }

    .topics-active {
      padding: 15px 0 0 0;
      text-align: center;
      font-size: 17px;
      text-transform: uppercase;
      color: #00d19a;
      border-bottom: 2px solid #00d19a !important;
    }

    .topics-text {
      margin-top: 15px;
      margin-left: -27px;
    }

    .button, .back {
      margin: 0 auto;
      margin-top: 20px;
      display: table;
      font-size: 18px;
      background-color: #00d19a !important;
    }

    .back {
      background-color: #ef8157 !important;
    }

    .back:hover {
      background-color: #eb6532 !important;
    }

    .back:focus {
      background-color: #eb6532 !important;
    }

    .button:hover {
      background-color: #00d19a !important;
    }

    .button:focus {
      background-color: #00d19a !important;
    }

    .button-pay {
      margin-top: 20px;
      display: table;
      font-size: 12px;
      background-color: #00d19a !important;
      width: 100%;
      display: flex;
      text-align: left;
    }

    .button-pay::after {
      border: solid rgba(255, 255, 255, 0.4);
      border-width: 0 2px 2px 0;
      content: "";
      padding: 5px;
      position: absolute;
      right: 2rem;
      top: 56%;
      -webkit-transform: translateY(-50%) rotate(-45deg);
      transform: translateY(-50%) rotate(-45deg);
      transition: all .3s ease;
    }

    .button-pay:hover {
      background-color: #008764 !important;
    }

    .button-pay:focus {
      background-color: #00d19a !important;
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
    }

    .topics-number {
      background: #000;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 1.8em;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      line-height: 2.6em;
      margin-right: 5px;
      text-align: center;
      width: 2.6em;
    }

    .topics-number-active {
      background: #00d19a;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 1.8em;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      line-height: 2.6em;
      margin-right: 5px;
      text-align: center;
      width: 2.6em;
    }

    .vl {
      border-left: 2px solid rgb(221, 221, 221);
    }

    @media (max-width: 812px) {
      .box2 {
        width: 90%;
      }
      .vl {
        display: none;
      }
      .topics {
        padding: 15px 0 0 0;
        text-align: center;
      }
      .topics-text {
        margin-top: 15px;
        text-align: center;
        margin-left: 0px;
      }
      .topics {
        padding: 40px 0 0 0;
        text-align: center;
        font-size: 10px;
        text-transform: uppercase;
        color: #00d19a;
        border-bottom: 2px solid #00d19a !important;
      }
      .topics-active {
        padding: 40px 0 0 0;
        text-align: center;
        font-size: 10px;
        text-transform: uppercase;
        color: #00d19a;
        border-bottom: 2px solid #00d19a !important;
      }
      .row {
        margin-left: 0px;
        margin-right: 0px;
      }
      .col-12 {
        padding: 0px;
      }
    }

    .vue-barcode-element {
      width: auto;
      height: auto;
    }

    .container-card {
      position: relative;
    }

    #image {
      position: initial;
    }

    .texto {
      position: absolute;
      font-size: 18px;
      left: 145px;
      top: 150px;
      color: #0f0b30;
    }

    .el-input__inner {
      background-color: #fff !important;
    }

    .el-input__inner:hover {
      color: #66615B !important;
    }
  }
</style>
