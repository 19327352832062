<template>
  <div class="dzs-acc-edit">
    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-12">
            <account-info :inject-username=username ></account-info>
          </div>
        </div>
        <hr>
        <card>
          <div class="row">
            <div class="col-md-6">
              <div style="width: 50%; margin-left: auto">
                <label class="control-label">Faturamento Mensal</label>
                <money class="form-control input-lg" v-model="d_expectedTransactionAmount" v-bind="money"> </money>
              </div>
            </div>
            <div class="col-md-6">
              <div style="width: 50%; margin-right: auto">
                <fg-input label="Meios de Venda" v-model="d_salesPoint" type="input"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div style="width: 50%; margin: auto">
                <label class="control-label">Observações</label>
                <textarea rows="5" style="width: 100%" v-model="d_observations" maxlength="2000" />
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {postWs, getWs, failWs} from 'src/ws.service'
  import {Money} from 'v-money'
  import AccountInfo from "@/components/Dashboard/Views/BackOffice/Account/Edit";
  import {isNullOrEmpty} from "@/util/core.utils";

  export default {
    name: 'commercial-client-info',
    props: {
      username: null,
      expectedTransactionAmount: 0,
      salesPoint: '',
      observations: ''
    },
    components: {
      AccountInfo,
    },
    created() {
      this.$emit("saveInterface", {save: () => this.save()})
    },
    data() {
      return {
        d_expectedTransactionAmount: this.expectedTransactionAmount != null ? this.expectedTransactionAmount : 0,
        d_salesPoint: this.salesPoint,
        d_observations: this.observations,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
      }
    },
    methods: {
      save(){
        postWs("/bo/commercial/dashboard/saveClient", true, null,
          {username: this.username, expectedTransactionAmount: this.d_expectedTransactionAmount, salesPoint: this.d_salesPoint, observations: this.d_observations},
          response => {
            this.d_observations = response.data.observations
            this.d_salesPoint = response.data.salesPoint
            this.d_expectedTransactionAmount = response.data.expectedTransactionAmount
            this.$emit("saveFinished")
          }, failWs)
      }
    }
  }

</script>

<style lang="scss">
</style>
