<template>
  <div class="dzs-card row">
    <div class="col-lg-12 col-md-7" v-show="!formRequestNotFound && !formInactiveUserShow">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body" style="padding-bottom: 30px">
            <form>
              <div>
                <h4>Consulta do Plano de Máquinas de Cartão</h4>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <fg-input type="text"
                            label="Usuário"
                            disabled="true"
                            v-model="request.username">
                  </fg-input>
                </div>
                <div class="col-md-8">
                  <fg-input type="text"
                            :label="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E' ? 'Nome Completo':'Razão Social'"
                            v-model="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?request.name:request.companyName"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row" style="margin-top: 0px">
                <div class="col-md-4">
                  <fg-input label="Tipo de Pessoa"
                            type="text"
                            v-model="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'Pessoa Física':'Pessoa Jurídica'"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-8">
                  <fg-input :label="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'CPF':'CNPJ'"
                            type="tel"
                            v-mask="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?['###.###.###-##']:['##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-model="request.document"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div v-if="request.personType==='LEGAL_PERSON'">
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="text"
                              label="Razão Social"
                              v-model="request.companyName"
                              disabled="true">
                    </fg-input>
                  </div>
                </div>
              </div>
              <div style="margin-top: 30px">
                <h6>Dados da Entrega</h6>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            v-model="request.deliveryAddress.zipCode"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            label="Rua/Av."
                            v-model="request.deliveryAddress.street"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            v-model="request.deliveryAddress.number"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Complemento"
                            v-model="request.deliveryAddress.complement"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Bairro"
                            v-model="request.deliveryAddress.neighborhood"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            v-model="request.deliveryAddress.city"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            v-model="request.deliveryAddress.state"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Código de Rastreio"
                            v-model="request.shipment.dispatchShipmentService.name"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Código de Rastreio"
                            v-model="request.shipment.dispatchTrackingCode"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Status da Requisição"
                            v-model="request.requestStatus"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Status do Envio"
                            v-model="request.shipment.transitStatus"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <br/>
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Máquinas Cadastradas</h6>
                </div>
                <div class="col-sm-12">
                  <el-table class="table-striped"
                            :data="request.registeredTerminals"
                            style="width: 100%">
                    <el-table-column :min-width="80" label="Serial">
                      <template slot-scope="props">
                        <span>
                          <span><b>#{{props.row.physicalId}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Modelo">
                      <template slot-scope="props">
                        <span>
                          <span>{{props.row.model.name}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="50" label="Customizada">
                      <template slot-scope="props">
                        <span>
                          <span v-if="props.row.themeCustomized">SIM</span>
                          <span v-if="!props.row.themeCustomized">NÃO</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <br/>
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Taxas</h6>
                </div>
                <div class="col-sm-12">
                  <el-table class="table"
                            :data="request.activePlan"
                            style="width: 100%">
                    <el-table-column :min-width="90" label="Bandeira">
                      <template slot-scope="props">
                        <span>
                          <span><b>{{props.row.nameCardTerminalBanner}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Débito">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.debito)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="1x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="2x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito2x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="3x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito3x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="4x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito4x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="5x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito5x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="6x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito6x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="7x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito7x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="8x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito8x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="9x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito9x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="10x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito10x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="11x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito11x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="12x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito12x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="formInactiveUserShow">
      <div class="col-lg-9 col-md-7">
        <card>
          <div class="row" style="margin-bottom: 30px">
            <div class="col-md-4" style="text-align: right; font-size: 20px; color: #667eea; margin-top: 25px">
              <i class="fa fa-exclamation-triangle fa-10x"></i>
            </div>
            <div class="col-md-8" style="text-align: center">
              <h4 slot="header" class="card-title">Conta em Análise</h4>
              <p style="margin: 20px 60px 0px 60px;">A sua conta está em análise de documentos ou ainda não foi finalizado o seu cadastro, <a @click="$router.push('/user/profile')" href="#">clique
                aqui</a> para verificar os seus dados cadastrais ou entre em contato com o suporte através no nosso chat.<br><br>
                Após a aprovação da conta, será permitido a solicitação da sua máquina de cartão.</p><br>
              Obrigado por escolher o <b style="color: #000000 !important;">SuitPay</b></p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-3 col-md-5">
        <div style="position: fixed;margin-right: 1%;">
          <div class="image">
            <img src="/static/img/cardterminal/S920.png" alt="...">
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="formRequestNotFound">
      <div class="col-lg-9 col-md-7">
        <card>
          <div class="row" style="margin-bottom: 30px">
            <div class="col-md-4" style="text-align: right; font-size: 20px; color: #667eea; margin-top: 25px">
              <i class="fa fa-exclamation-triangle fa-10x"></i>
            </div>
            <div class="col-md-8" style="text-align: center">
              <h4 slot="header" class="card-title">Não Existem Solicitações de Máquinas</h4>
              <p style="margin: 20px 60px 0px 60px;">Você não realizou nenhuma solicitação de máquina de cartão <b style="color: #000000 !important;">SuitPay</b>.<br><br>
                Para realizar o seu pedido <a @click="$router.push('/card-terminal/request')" href="#">clique aqui</a>.<br><br>
                Obrigado por escolher a <b style="color: #000000 !important;">SuitPay</b></p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-3 col-md-5">
        <div style="position: fixed;margin-right: 1%;">
          <div class="image">
            <img src="/static/img/cardterminal/S920.png" alt="...">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {postWs, getWs, failWs, callWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty, toMoney} from "../../../../util/core.utils";
  import {Table, TableColumn} from "element-ui";

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {},
    data() {
      return {
        usernameDisplay: '',
        formRequestNotFound: false,
        formInactiveUserShow: false,
        request: {
          username: '',
          name: '',
          document: '',
          companyName: '',
          personType: '',
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          plan: {
            carTerminalPlan: '',
            cardTerminalPlanLabel: '',
          },
          terminal: {
            nameModel: '',
            amount: '',
          },
          billingRange: '',
          partner: {
            username: '',
            overprice: 0,
          },
          requestStatus: '',
          shipmentServices: [],
          shipment: {
            transitStatus: '',
            dispatchShipmentService: {
              name: '',
            },
            shipmentValue: 0,
            dispatchTrackingCode: ''
          },
          registeredTerminals: [],
          activePlan: [{
            debito: 0,
            credito: 0,
            credito2x: 0,
            credito3x: 0,
            credito4x: 0,
            credito5x: 0,
            credito6x: 0,
            credito7x: 0,
            credito8x: 0,
            credito9x: 0,
            credito10x: 0,
            credito11x: 0,
            credito12x: 0,
            nameCardTerminalBanner: '',
          }],
        },
        search: {
          physicalId: '',
          active: false,
        },
        tableData: [],
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        disableButton: false
      }
    },

    created() {
      this.usernameDisplay = localStorage.getItem("usernameDisplay")
    },

    mounted() {
      this.loadData()
    },

    methods: {
      toMoney,
      isNullOrEmpty,
      loadData() {
        getWs('/card-terminal/get-card-terminal-request-user', true, {username: this.usernameDisplay}, this.loadRequestData, this.failRequestData)
      },
      loadRequestData(response) {
        this.request.requestStatus = this.reqStatusToLabel(response.data.requestStatus)

        if(response.data.requestStatus == 'ACTIVE'){
          this.disableButton = true
        }
        this.request.username = '@'+response.data.username
        this.request.name = response.data.name
        this.request.email = response.data.email
        this.request.companyName = response.data.companyName
        this.request.personType = response.data.personType
        this.request.document = response.data.document
        this.request.deliveryAddress.codIbge = response.data.deliveryAddress.codIbge
        this.request.deliveryAddress.street = response.data.deliveryAddress.street
        this.request.deliveryAddress.number = response.data.deliveryAddress.number
        this.request.deliveryAddress.complement = response.data.deliveryAddress.complement
        this.request.deliveryAddress.zipCode = response.data.deliveryAddress.zipCode
        this.request.deliveryAddress.neighborhood = response.data.deliveryAddress.neighborhood
        this.request.deliveryAddress.city = response.data.deliveryAddress.city
        this.request.deliveryAddress.state = response.data.deliveryAddress.state

        if(response.data.shipment != null) {
          this.request.shipment.dispatchTrackingCode = response.data.shipment.dispatchTrackingCode
          this.request.shipment.shipmentValue = response.data.shipment.shipmentValue
          this.request.shipment.transitStatus = this.transitStatusToLabel(response.data.shipment.transitStatus)
          this.request.shipment.dispatchShipmentService.name = response.data.shipment.dispatchShipmentService.name
        } else {
          this.request.shipment.transitStatus = 'Não Enviado'
        }

        this.request.plan.cardTerminalPlanLabel = response.data.cardTerminalPlanLabel
        this.request.activePlan = response.data.plan

        this.request.registeredTerminals = response.data.registeredTerminals
      },
      failRequestData(error){
        if(error.response.status == '404') {
          this.formRequestNotFound = true
        } else if(error.response.status == '401') {
          this.formInactiveUserShow = true
        } else {
          swal('Erro', 'Erro ao carregar os dados da solicitação!', 'warning')
        }
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING_APPROVAL') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'APPROVED') {
          return 'Aprovado'
        } else if (reqStatus == 'PREPARING_SHIPMENT') {
          return 'Aguardando Envio'
        } else if (reqStatus == 'SHIPPED') {
          return 'Enviado'
        } else if (reqStatus == 'PARTIAL_SHIPPING') {
          return 'Envio Parcial'
        } else if (reqStatus == 'NOT_APPROVED') {
          return 'Não Aprovado'
        } else if (reqStatus == 'CANCELED_BY_CUSTOMER') {
          return 'Cancelado Pelo Cliente'
        } else if (reqStatus == 'CANCELED_BY_VENDOR') {
          return 'Cancelado Pelo Vendedor'
        } else if (reqStatus == 'ACTIVE') {
          return 'Ativo'
        }
        return '??'
      },
      transitStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING_SEND') {
          return 'Aguardando Envio'
        } else if (reqStatus == 'SENT') {
          return 'Enviado'
        } else if (reqStatus == 'DELIVERED') {
          return 'Entregue'
        } else if (reqStatus == 'DELIVER_FAIL') {
          return 'Entrega Falhou'
        } else if (reqStatus == 'WAITING_RETURN') {
          return 'Aguardando Devolução'
        } else if (reqStatus == 'RETURN_SENT') {
          return 'Devolução Enviada '
        } else if (reqStatus == 'RETURNED') {
          return 'Devolvido'
        } else if (reqStatus == 'RETURN_FAIL') {
          return 'Falha na Devolução'
        }
        return '??'
      },
    }
  }
</script>

<style lang="scss">
  .dzs-card {
    .card-title {
      margin-top: 0px;
    }

    .topics {
      padding: 15px 0 0 2%;
    }

    .topics-text {
      margin-top: 15px;
      margin-left: -27px;
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
    }

    @media (max-width: 768px) {
      .box2 {
        width: 90%;
      }
      .vl {
        display: none;
      }
      .topics {
        padding: 15px 0 0 0;
        text-align: center;
      }
      .topics-text {
        margin-top: 15px;
        text-align: center;
        margin-left: 0px;
      }
    }

    .el-table th > .cell {
      padding-left: 0px;
      font-size: 11px;
    }

    .el-table .cell {
      padding-left: 0px;
      font-size: 13px;
    }
  }

</style>
