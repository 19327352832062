<template>
  <div class="dzs-compliance-med-user">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Devoluções MED - @{{ username }}
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <fg-input label="Banco Emissor" class="has-danger">
                <el-select
                  class="select-default"
                  id="issuingBank"
                  name="issuingBank"
                  v-model="issuingBankId"
                >
                  <el-option class="select-default" value="" label="Todos"></el-option>
                  <el-option class="select-default" v-for="bank in issuingBanks" :key="bank.id" :value="bank.id"
                             :label="bank.name"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="searchMedRequests" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center text-success">
                  <i class="fa-solid fa-wallet"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category ">Saldo Disponível</p>
                  <p class="card-title ">R$ {{ toMoney(currentBalance) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center text-success">
                  <i class="fa-solid fa-money-bill-wave"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category ">Total Selecionado</p>
                  <p class="card-title ">R$ {{ toMoney(totalValueSelected) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="medRequests.length>0" style="margin: 0 auto">
        <card>
          <div class="card-header row">
            <div class="col-12">
              <p-button size="sm" type="warning" class="ml-2 float-right"
                        :disabled="describeNotReturnedRequestsDisabled" @click="showDescribeRequestsModal">
                <i class="fas fa-file-invoice"/>
                DEFINIR MOTIVO DE NEGAÇÃO
              </p-button>
              <p-button size="sm" type="success" class="float-right"
                        :disabled="reverseMultiplePixDisabled" @click="reverseMultiplePix">
                <i class="fas fa-rotate-left"/>
                ESTORNAR TRANSAÇÕES SELECIONADAS
              </p-button>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="medRequests"
                        style="width: 100%"
                        @selection-change="handleTableSelectionChange"
                        ref="tableRef"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column :min-width="120" label="End To End">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.endToEnd }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="65" label="Our Number">
                  <template slot-scope="item">
                    <span>{{ item.row.ourNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Valor">
                  <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.value) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Status">
                  <template slot-scope="item">
                    <span>
                      {{ statusText[item.row.status] }}
                    </span>
                    <span v-if="item.row.statusDescription"> - {{ item.row.statusDescription }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Banco">
                  <template slot-scope="item">
                    <span>
                      {{ item.row.bankName }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Data">
                  <template slot-scope="item">
                    <span>
                    <span>{{ formatDate(item.row.date, "DD/MM/YYYY") }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p-button v-if="item.row.status !== 'RETURNED'" type="success" size="sm" icon
                                  title="Solicitar Devolução"
                                  @click="reversePix(item.row)">
                          <i class="fas fa-rotate-left"/>
                        </p-button>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.searchMedRequests">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="currentActiveModal">
        <component
          :selectedRequests="multipleSelectionMedRequests"
          :is="currentActiveModal"
          @save="searchMedRequests"
        ></component>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs, postWs} from "src/ws.service"
import {daysBetween, formatDate} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import DescribeRequestsForm from "@/components/Dashboard/Views/BackOffice/Compliance/Forms/DescribeRequestsForm.vue";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination,
    DescribeRequestsForm
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      issuingBanks: [],
      issuingBankId: '',
      medRequests: [],
      multipleSelectionMedRequests: [],
      username: null,
      currentBalance: 0,
      currentActiveModal: undefined,
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      pagination: {
        perPage: 100,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  mounted() {
    this.checkIfHasPreviousDateFilter()
    this.checkIfHasPreviousBankFilter()
    this.getIssuingBanks()
    this.getUser()
    this.searchMedRequests()
  },
  computed: {
    totalValueSelected() {
      return this.multipleSelectionMedRequests.reduce((accumulator, currentRequest) => accumulator + currentRequest.value, 0)
    },
    statusText() {
      return {
        'REQUESTED': 'Solicitado',
        'NOT_REQUESTED': 'Não Solicitado',
        'RETURNED': 'Devolvido',
        'NOT_RETURNED': 'Não Devolvido'
      }
    },
    reverseMultiplePixDisabled() {
      return this.multipleSelectionMedRequests.length === 0 || this.multipleSelectionMedRequests.some(el => el.status === 'RETURNED')
    },
    describeNotReturnedRequestsDisabled() {
      return this.multipleSelectionMedRequests.length === 0 || this.multipleSelectionMedRequests.some(el => el.status === 'RETURNED')
    }
  },
  methods: {
    formatDate,
    toMoney,
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    searchMedRequests() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs('/bo/compliance/user-med-requests', 'POST', null, true, null,
        {
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
          startDate: formatDate(this.startDate, 'DD/MM/YYYY'),
          endDate: formatDate(this.endDate, 'DD/MM/YYYY'),
          username: this.username,
          issuingBankId: this.issuingBankId
        },
        this.successSearchMedRequests,
        this.failSearchMedRequests)
    },
    successSearchMedRequests(response) {
      this.medRequests = response.data.list
      this.currentBalance = response.data.currentBalance
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.medRequests.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    failSearchMedRequests(error) {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar as solicitações. Verifique os filtros e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    reversePix(medRequest) {
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo solicitar o estorno da transação (ourNumber: ${medRequest.ourNumber})?`,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        showCancelButton: true
      }).then(result => {
        if (result) {
          postWs('/bo/compliance/reverse-pix', true, null, {
              date: medRequest.date,
              bankName: medRequest.bankName,
              status: medRequest.status,
              ourNumber: medRequest.ourNumber,
              endToEnd: medRequest.endToEnd,
              value: medRequest.value
            },
            this.successReversePix,
            this.failReversePix
          )
        }
      }).catch(swal.noop)
    },
    reverseMultiplePix() {
      const qtySelected = this.multipleSelectionMedRequests.length
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo solicitar o estorno de ${qtySelected} ${qtySelected > 1 ? 'transações' : 'transção'} PIX?`,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        showCancelButton: true
      }).then(result => {
        if (result) {
          this.doReverseMultiplePix()
        }
      }).catch(swal.noop)
    },
    async doReverseMultiplePix() {
      const promises = this.multipleSelectionMedRequests.map(medRequest => {
        return new Promise((resolve, reject) => {
          callWs('/bo/compliance/reverse-pix', 'POST', null, true, null,
            {
              date: medRequest.date,
              bankName: medRequest.bankName,
              status: medRequest.status,
              ourNumber: medRequest.ourNumber,
              endToEnd: medRequest.endToEnd,
              value: medRequest.value
            },
            () => resolve(),
            () => reject()
          )
        });
      });

      Promise.all(promises)
        .then(this.successReversePix)
        .catch(this.failReversePix);
    },
    successReversePix() {
      swal({
        title: 'Sucesso!',
        text: 'O estorno foi solicitado com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)
      this.searchMedRequests()
    },
    failReversePix() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro o solicitar estorno das transações PIX solicitadas. Tente Novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    handleTableSelectionChange(selectedRows) {
      this.multipleSelectionMedRequests = selectedRows.filter(el => {
        if (el.status !== 'RETURNED') {
          return el
        }
        this.$refs.tableRef.toggleRowSelection(el, undefined)
      })

      if (this.multipleSelectionMedRequests.length === 0) {
        this.currentActiveModal = undefined
      }
    },
    checkIfHasPreviousDateFilter() {
      const {query} = this.$route
      this.startDate = query.startDate ? query.startDate : new Date()
      this.endDate = query.endDate ? query.endDate : new Date()
    },
    checkIfHasPreviousBankFilter() {
      const {query} = this.$route
      this.issuingBankId = query.issuingBankId ? Number(query.issuingBankId) : ''
    },
    getUser() {
      this.username = this.$route.params.username ? this.$route.params.username : ''
    },
    showDescribeRequestsModal() {
      this.currentActiveModal = 'describe-requests-form'
    },
    getIssuingBanks() {
      callWs('/bo/compliance/get-issuing-banks', null, null, true, null, null,
        this.successGetIssuingBanks,
        () => null
      )
    },
    successGetIssuingBanks(response) {
      this.issuingBanks = response.data
    }
  }
}
</script>
<style lang="scss">
.dzs-compliance-med-user {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .card-header {
    .btn {
      margin: 0;
    }
  }
}
</style>
