<template>
  <div class="card" v-if="files.length>0">
    <div class="card-header">
      <h4 class="title" style="margin-bottom: 0px">Enviar Documentos</h4>
      <p>(Tamanho máximo: 5MB por imagem)
      </p>
    </div>
    <div class="card-body">
      <form>
        <div class="row" v-for="item in files">
          <div class="col-md-12">
            <div class="row" style="margin-left: 0px">
              <label style="font-size: 13px; color: #11141f">{{item.title}}</label>
            </div>
            <div class="row" style="padding: 0 14px">
              <p-button :class="item.styleClass" style="margin-top: 0px; width: 100%"
                        @click.prevent="function (){if(isProfileOk()) {($refs[item.ref])[0].click()}}"
                        :disabled="item.sending">
                <i class="fa fa-upload"/> {{item.filename}}
              </p-button>
              <input type="file" :ref="item.ref" style="display: none" accept=".png, .jpg, .jpeg, .pdf"
                     @change="function (event){validateFile(event,item)}"/>
            </div>
          </div>
        </div>

        <br/>
        <div class="text-center">
          <p-button class="btn btn-info btn-fill btn-wd"
                    @click="uploadFiles" :disabled="button.waiting!=0">
            {{this.button.text}}
          </p-button>
          <p style="font-size: 13px">*Formatos aceitos: PNG, JPEG, JPG e PDF.
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import {callWs, postWs} from 'src/ws.service'
  import {isNullOrEmpty} from "src/util/core.utils"
  import swal from 'sweetalert2'
  import {sendEvent} from "@/googleAnalyticsApi";

  export default {
    components: {},
    data() {
      return {
        facebook:{
          fbc: this.getCookie("_fbc"),
          fbp: this.getCookie("_fbp"),
        },
        fileMaxSize: 5,
        button: {
          text: "Enviar Documentos",
          waiting: 0
        },
        userProfileKey: 0,
        files: [],
        typesNaturalPerson: [
          {
          type: 'PROOF_OF_RESIDENCE',
          title: 'Comprovante de endereço (Em nome do proprietário da conta ou familiares próximos (pai, mãe, cônjugue), e emitido há no máximo 3 meses)',
          ref: 'address',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
        }
      ],
      typesLegalPerson: [
        {
          type: 'CNPJ_CARD',
          title: '(Empresa) Cartão do CNPJ',
          ref: 'cnpj_card',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
        },
        {
          type: 'SOCIAL_CONTRACT',
          title: '(Empresa) Contrato social',
          ref: 'social_contract',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
        },
        {
          type: 'PROOF_OF_COMPANY_ADDRESS',
          title: '(Empresa) Comprovante de endereço (A titularidade deverá ser a mesma do CNPJ cadastrado ou sócios, e emitido há no máximo 3 meses)',
          ref: 'company_address',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
        },
        {
          type: 'PROOF_OF_RESIDENCE',
          title: '(Representante Legal) Comprovante de endereço (Em nome do proprietário da empresa ou familiares próximos (pai, mãe, cônjugue), e emitido há no máximo 3 meses)',
          ref: 'address',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
        }
      ],
      bankStatement: {
        type: 'BANK_STATEMENT',
        title: 'Extrato bancário do titular da conta com dados bancários (International Bank Account Number – IBAN, Instituição Financeira e Código SWIFT)',
        ref: 'bank_statement',
        filename: 'Selecionar imagem',
        styleClass: 'btn btn-danger',
        sending: false,
        show: true,
        file: null
      },
      crnm: {
          type: 'CRNM',
          title: 'Carteira de Registro Nacional Migratório',
          ref: 'crnm',
          filename: 'Selecionar imagem',
          styleClass: 'btn btn-danger',
          sending: false,
          show: true,
          file: null
      },
      password: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  mounted() {
    callWs("/document/status",
      "POST", null, true, null, null,
      this.loadUploadCard, function (error) {
      }
    )
  },
  watch: {
    files: function(val) {
      if(val==0){
        this.$router.push('/user/profile')
      }
    }
  },
  methods: {
    isNaturalPerson(personType) {
      return personType === 'NATURAL_PERSON' || personType === 'NATURAL_PERSON_E';
    },
    isLegalPerson(personType) {
      return personType === 'LEGAL_PERSON';
    },
    loadUploadCard(response) {
      let personType = localStorage.getItem('personType')
      let types = this.typesNaturalPerson
      if (this.isLegalPerson(personType)) {
        types = this.typesLegalPerson
      }
      if (this.parseBoolean(localStorage.getItem('isForeignerBankAccount'))) {
        this.typesLegalPerson.push(this.bankStatement)
        this.typesNaturalPerson.push(this.bankStatement)
      }
      if (personType === 'NATURAL_PERSON_E'){
        this.typesNaturalPerson.push(this.crnm)
      }
      this.files = []
      let errorCauseCount = 0
      let errorCauseMsg = ""
      for (let i in types) {
        let type = types[i]
        let add = true
        let descriptionError = ''
        for (let j in response.data) {
          let server = response.data[j]
          if (type.type == server.documentPhotoType) {
            if (server.documentPhotoStatus == 'OK'
              || server.documentPhotoStatus == 'WAITING_APPROVAL') {
              add = false
              break
            }else if(server.documentPhotoStatus == 'REJECTED'){
              descriptionError = server.descriptionError
            }
          }
        }
        if (add) {
          if (!isNullOrEmpty(descriptionError)) {
            ++errorCauseCount
            errorCauseMsg += errorCauseCount.toString() + " - " + descriptionError + " <br/>"
          }
          this.files.push(JSON.parse(JSON.stringify(type)))
        }
      }
      if (errorCauseCount > 0) {
        let errorExplanation = '- Para validarmos sua conta é necessário que os documentos sejam válidos, legíveis e coincidam com os informados no formulário!'
        errorExplanation += ' <br/>'
        errorExplanation += '- Abaixo possui os motivos da recusa dos documentos, favor analisar e reenviá-los.'
        errorExplanation += ' <br/><br/>'
        swal({
          title: 'Não foi possivel validar a conta!',
          html: errorExplanation + errorCauseMsg,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    parseBoolean(value) {
      return value === 'true' || value === 'TRUE' || value === 'True'
    },
    validateFile(event, upload) {
      if (event.target.files.length <= 0) {
        return
      } else if (event.target.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
        swal({
          title: 'Aviso!',
          text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      upload.file = event.target.files[0]
      upload.filename = upload.file.name
      upload.styleClass = "btn btn-primary"
    },
    uploadFiles() {
      this.sendPixelUpdateDocEvent()
      if(this.validateEventGoogle()){
        sendEvent('enviou_documento', localStorage.getItem('username'), localStorage.getItem('email'), '')
      }
      this.userProfileKey++
      if (!this.isProfileOk()) {
        return
      }
      this.button.text = "Favor aguardar envio"
      for (let file in this.files) {
        if (this.files[file].file != null) {
          ++this.button.waiting
          this.callUploadWs(this.files[file])
        }
      }
    },
    validateEventGoogle(){
      try {
        postWs('/facebook/pixel/sent-docs-google',
          false,
          null,
          {
            username: localStorage.getItem("username"),
          }, function (response){
            return response.status === 204;
          }, function (){
            return false
          })
      } catch (err) {
        console.error('Error:', err);
      }
    },
    isProfileOk() {
      let name = localStorage.getItem("name")
      if (name != null && name != 'null' && name != '') {
        return true
      }
      swal({
        title: 'Aviso!',
        text: 'Favor preencher os campos do formulário de perfil antes de enviar a documentação.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
      return false
    },
    callUploadWs(upload) {
      upload.filename = "Enviando..."
      upload.styleClass = "btn btn-warning"
      upload.sending = true
      let formData = new FormData()
      formData.append("file", upload.file)
      callWs("/document/upload",
        "POST", {'Content-Type': 'multipart/form-data'},
        true, {fileType: upload.type}, formData,
        response => {
          this.uploadSuccess(response, this.files, upload)
        },
        (error) => {
          upload.filename = "Falhou! Tentar novamente"
          upload.styleClass = "btn btn-danger"
          upload.sending = false
          this.button.text = "Enviar Documentos"
          --this.button.waiting
          let text = 'Falha ao salvar um ou mais documentos, por favor tente novamente!'
          if (!isNullOrEmpty(error.response.data.response)) {
            text = error.response.data.message
          }
          swal({
            title: 'Falha!',
            text: text,
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'error'
          })
        }
      )
    },
    uploadSuccess(response, files, upload) {
      upload.sending = true
      upload.styleClass = "btn btn-primary"
      upload.filename = "ENVIADO COM SUCESSO!"

      this.button.text = "Enviar Documentos"
      --this.button.waiting
      setTimeout(function () {
        for (let file in files) {
          if (files[file].type == upload.type) {
            files.splice(file, 1)
            break
          }
        }
      }, 2000)
    },
      async sendPixelUpdateDocEvent() {
        try {
          postWs('/facebook/pixel/upload-doc',
            false,
            null,
            {
              username: localStorage.getItem("username"),
              fbc: this.facebook.fbc,
              fbp: this.facebook.fbp,
            }, function (response){
            }, function (error){
              console.log(error)
            })
        } catch (err) {
          console.error('Error:', err);
        }
      },
      getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
  }
}

</script>
<style>

</style>
