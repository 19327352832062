<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12 card">
        <div class="row card">
          <div class="col-md-12" style="margin-bottom: 15px; text-align: left">
            <h4 slot="header" class="card-title">
              Relatório De Lucros
            </h4>
            <div class="row">
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Início</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsStart" @input="validatePeriod"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Fim</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsEnd"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <p-button @click.prevent="search" style="margin-top: 24px">
                  <i class="fas fa-search-dollar"></i> BUSCAR
                </p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row card" v-if="timelineItems.length>0">
          <div class="col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="card-body row">
              <div class="col-sm-12">
                <el-table class="table-striped"
                          :data="timelineItems"
                          style="width: 100%"
                          :row-class-name="tableRowClassTotal">
                  <el-table-column :min-width="130" label="Taxa">
                    <template slot-scope="item">
                    <span>
                      <span>{{item.row.description}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Valor">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.value)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Quantidade">
                    <template slot-scope="item">
                    <span>
                      <span>{{item.row.amount}}</span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {callWs} from "src/ws.service"
  import {formatDate} from "src/util/date.utils"
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'

  export default {
    components: {
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
      }
    },
    methods: {
      toMoney,
      search() {
        callWs("/bo/financial/profit-report",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            acquirerName: this.acquirerName
          },
          this.loadTimeline,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Dados não encontrados para o período selecionado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
          })
      },
      loadTimeline(response) {
        this.timelineItems = response.data
        if (this.timelineItems.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'Dados não encontrados para o período selecionado!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      tableRowClassTotal ({row, rowIndex}) {
        if (rowIndex + 1 === this.timelineItems.length) {
          return 'table-success'
        }
        return ''
      },
    }
  }
</script>
<style lang="scss">
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }
  }
</style>
