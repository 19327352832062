<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Usuário</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Usuário</label>
                <fg-input v-model="search.key">
                  <span slot="addonLeft">@</span>
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input label="Status">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="search.enabled">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value=true
                               class="select-default"
                               label="Habilitado"/>
                    <el-option value=false
                               class="select-default"
                               label="Desabilitado"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input label="Região">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             filterable
                             v-model="search.region">
                    <el-option
                      v-for="item in regions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-sm-1">
                <fg-input label="Exibir">
                  <el-select
                    class="select-default"
                    v-model="pagination.perPage"
                    placeholder="Per page">
                    <el-option
                      class="select-default"
                      v-for="item in [5,10,25,50]"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-3">
                <p-button type="primary" style="margin-top: 25px;" @click="find" v-if="!search.completeCommissionReport">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>
                <p-button style="margin-top: 25px;"
                          @click="searchUser" type="info">
                  <i slot="label" class="fas fa-plus"></i>
                  Adicionar
                </p-button>
              </div>
            </div>
            <div class="row" v-if="userToAdd.destinationName != null">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Nome Destino</label>
                    <p class="form-control-static">{{userToAdd.destinationName}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">CPF/CNPJ</label>
                    <p class="form-control-static">{{userToAdd.destinationSocialNumber}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">E-mail</label>
                    <p class="form-control-static">{{userToAdd.email}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 m-auto" style="text-align: center">
                    <fg-input label="Regiões Ativas">
                      <el-select size="large"
                                 placeholder="Selecionar"
                                 class="select-default"
                                 v-model="userToAdd.region">
                        <el-option
                          v-for="item in activeRegions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 m-auto" style="text-align: center">
                    <fg-input label="Nível">
                      <el-select size="large"
                                 placeholder="Selecionar"
                                 class="select-default"
                                 v-model="userToAdd.level">
                        <el-option
                          v-for="item in activeLevels"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <p-button type="success" style="margin-top: 25px;"
                              @click="saveSalePerson">
                      <i slot="label" class="far fa-floppy-disk"></i>
                      Salvar
                    </p-button>
                    <p-button type="danger" style="margin-top: 25px;"
                              @click="clearUserToAdd">
                      <i slot="label" class="nc-icon nc-simple-remove"></i>
                      Cancelar
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2 mt-auto mb-auto">
                <el-tooltip content="Carregar os dados de comissão pode impactar na performance de carregamento da lista!"
                            :open-delay="150"
                            placement="top">
                  <p-checkbox v-model="search.completeCommissionReport">Carregar dados de comissão?</p-checkbox>
                </el-tooltip>
              </div>
              <div class="col-sm-2" v-if="search.completeCommissionReport">
                <div class="row" style="margin-left: 0px">
                  <label>Mês dos Dados</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="search.monthAndYear" placeholder="Escolha um mês" type="month" format="MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-sm-4" v-if="search.completeCommissionReport">
                <p-button type="primary" style="margin-top: 25px;"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>

                <p-button type="success" style="margin-top: 25px;" @click="exportData">
                  <i slot="label" class="fas fa-download"></i>
                  Exportar XLS
                </p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%" v-show="!search.completeCommissionReport">
                  <el-table-column :min-width="60" label="Usuário">
                    <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="70" label="E-mail">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Nível">
                    <template slot-scope="props">
                    <span>
                      <span> {{props.row.level.levelName}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Região">
                    <template slot-scope="props">
                    <span>
                      <span> {{props.row.region}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Ativo">
                    <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                      <span> {{props.row.enabled?'Sim':'Não'}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="230" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="warning" size="sm" icon title="Editar"
                                @click="edit(props.row)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </p-button>
                      <span style="margin-left: 5px">
                        <p-button type="warning" size="sm" icon title="Ativar/Desativar"
                                  @click="enableOrDisable(props.row)">
                          <i class="fas fa-check-circle"></i>
                        </p-button>
                      </span>
                      <span style="margin-left: 5px">
                        <p-button type="primary" size="sm" icon title="Definir Metas" @click="getSalePersonGoals(props.row.username)">
                          <i class="fas fa-asterisk"></i>
                        </p-button>
                      </span>
                      <span style="margin-left: 5px">
                        <p-button type="primary" size="sm" icon title="Visualizar Dashboard do Vendedor" @click="openSalePersonDashboard(props.row.username)">
                          <i class="fa-regular fa-eye"></i>
                        </p-button>
                      </span>
                      <span style="margin-left: 5px">
                        <p-button type="info" size="sm" icon title="6 messes de Transacionado" @click="openSalePersonMonthsOperations(props.row.username)">
                          <i class="fas fa-chart-gantt"></i>
                        </p-button>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%" v-show="search.completeCommissionReport">
                  <el-table-column :min-width="60" label="Usuário">
                    <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="70" label="E-mail">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Região">
                    <template slot-scope="props">
                    <span>
                      <span> {{props.row.region}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="40" label="Prev. do mês">
                    <template slot-scope="props">
                    <span>
                      <span> R$ {{toMoney(props.row.expectedTrAmount)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="40" label="Tot. Trans.">
                    <template slot-scope="props">
                    <span>
                      <span> R$ {{toMoney(props.row.goals.firstCommissionTrTotal + props.row.goals.secondCommissionTrTotal + props.row.goals.fixedCommissionTrTotal)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Meta">
                    <template slot-scope="props">
                    <span>
                      <span> R$ {{toMoney(props.row.goals.monthGoal)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Ativo">
                    <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                      <span> {{props.row.enabled?'Sim':'Não'}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="270" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="warning" size="sm" icon title="Editar"
                                @click="edit(props.row)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </p-button>
                      <span style="margin-left: 5px">
                        <p-button type="warning" size="sm" icon title="Ativar/Desativar"
                                  @click="enableOrDisable(props.row)">
                          <i class="fas fa-check-circle"></i>
                        </p-button>
                      </span>
                      <span style="margin-left: 5px">
                        <p-button type="primary" size="sm" icon title="Definir Metas" @click="getSalePersonGoals(props.row.username)">
                          <i class="fas fa-asterisk"></i>
                        </p-button>
                      </span>
                      <span style="margin-left: 5px">
                        <p-button type="primary" size="sm" icon title="Visualizar Dashboard do Vendedor" @click="openSalePersonDashboard(props.row.username)">
                          <i class="fa-regular fa-eye"></i>
                        </p-button>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6 pagination-info">
                <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE {{pagination.totalNumber}} REGISTROS.</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                              :total="pagination.totalNumber" :click="this.loadData">
                </p-pagination>
              </div>
            </div>
            <modal :show.sync="modalGoals" headerClasses="justify-content-center">
              <div class="d-flex justify-content-center">
                <h4 class="title title-up">Editar Metas</h4>
              </div>
              <hr>
              <div class="row col-md-12 ">
                <span>
                  <span style="font-weight: bold">Vendedor: </span>{{editGoals.name}}
                  <br>
                  <span style="font-weight: bold">Usuário: </span> {{editGoals.username}}
                </span>
              </div>
              <div class="row card" >
                <div class="col-sm-12 mt-2 card-body">
                  <el-table class="table-striped"
                            :data="editGoals.goals"
                            style="width: 100%">
                    <el-table-column :min-width="10" label="Mês">
                      <template v-slot="props">
                        <span>
                          <span><b>{{props.row.month}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="15" label="Person.">
                      <template v-slot="props">
                        <span>
                          <span v-if="props.row.customized" class="text-danger"><b>Sim</b></span>
                          <span v-else class="text-info"><b>Não</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="30" label="Valor">
                      <template v-slot="props">
                        <span>
                          <money class="form-control input-lg" v-model="props.row.value" v-bind="money"> </money>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="25" label="Ações">
                      <template v-slot="props">
                        <span>
                          <p-button v-if="props.row.value !== originalGoals[props.row.month]" type="success" size="sm" icon title="Usar valor padrão" @click="saveSalePersonGoals(editGoals.username, props.row.month, props.row.value)">
                            <i class="fa-regular fa-floppy-disk"></i>
                          </p-button>
                          <p-button v-if="props.row.customized" type="danger" size="sm" icon title="Usar valor padrão" @click="saveSalePersonGoals(editGoals.username, props.row.month, null)">
                            <i class="fa-solid fa-xmark"></i>
                          </p-button>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {DatePicker, Option, Select, Table, TableColumn, Tooltip} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs, getWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {Modal, Checkbox} from 'src/components/UIComponents'
  import {toMoney} from "@/util/core.utils";
  import download from "downloadjs";
  import {formatDate} from "@/util/date.utils";


  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Modal,
      [DatePicker.name]: DatePicker,
      [Checkbox.name]: Checkbox,
      [Tooltip.name]: Tooltip
    },
    data() {
      return {
        search: {
          key: '',
          enabled: null,
          region: null,
          monthAndYear: new Date(),
          completeCommissionReport: false
        },
        modalGoals: false,
        editGoals:{
          name: null,
          username: null,
          goals:[]
        },
        originalGoals:[],
        regions: [],
        activeRegions: [],
        activeLevels: [],
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        tableData: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        userToAdd: {
          username: null,
          destinationName : null,
          destinationSocialNumber : null,
          email : null,
          region: null,
          level: null
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues',
      'search.completeCommissionReport': 'loadData'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      resetDefaultValues(){
        this.search.key = ''
        this.search.enabled = null,
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/commercial/salesperson/list",
          true, null,
          {
            username: this.search.key,
            enabled: this.search.enabled,
            region: this.search.region,
            monthAndYear: (this.search.monthAndYear.getMonth()+1)+"/"+this.search.monthAndYear.getFullYear(),
            completeCommissionReport: this.search.completeCommissionReport,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)

        postWs("/bo/commercial/salesperson/regions",
          true, null,
          {}, response => this.regions = this.loadRegions(response, true),
          failWs)

        postWs("/bo/commercial/salesperson/regions",
          true, null,
          {enabled:true}, response => this.activeRegions = this.loadRegions(response),
          failWs)

        getWs("/bo/commercial/salesperson/levels",
          true, {enabled: true}, response => this.activeLevels = this.loadLevels(response),
          failWs)

        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      loadRegions(response, addNull){
        let regionList = []
        if(addNull){
          regionList.push({value: null, label: "Todas"})
        }
        for(let i = 0; i < response.data.list.length; i++){
          regionList.push({value:response.data.list[i].name, label:response.data.list[i].name})
        }
        return regionList
      },
      loadLevels(response){
        let levelList = []
        for(let i = 0; i < response.data.list.length; i++){
          levelList.push({value:response.data.list[i].id, label:response.data.list[i].levelName})
        }
        return levelList
      },
      clearUserToAdd(){
        this.username = this.search.key
        this.userToAdd.destinationName = null
        this.userToAdd.destinationSocialNumber = null
        this.userToAdd.email = null
        this.userToAdd.region = null
      },
      searchUser() {
        this.clearUserToAdd()
        postWs("/acc/find",
          true, {username: this.search.key, self: true}, null,
          (response) => {
            this.username = this.search.key
            this.userToAdd.destinationName = response.data.destinationName
            this.userToAdd.destinationSocialNumber = response.data.destinationSocialNumber
            this.userToAdd.email = response.data.email
          }, (error) => {
            this.clearUserToAdd();
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
          })
      },
      saveSalePerson(){
        postWs("/bo/commercial/salesperson/save",
          true, null,
          {
            username: this.search.key,
            enabled: true,
            region: this.userToAdd.region,
            level: {
              id: this.userToAdd.level
            }
          }, response => {
            this.clearUserToAdd()
            this.loadData()
          },
          failWs)
        return this.pagination.currentPage
      },
      edit(row) {
        this.search.key = row.username;
        this.searchUser()
        this.userToAdd.region = row.region;
        this.userToAdd.level = row.level.id;
      },
      enableOrDisable(account) {
        swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar o vendedor?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ativar',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'Desativar',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callEnabledWs(account, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callEnabledWs(account, false)
          }
        })
      },
      callEnabledWs(account, status) {
        postWs("/bo/commercial/salesperson/enable",
          true, null,
          {username: account.username, enabled: status},
          function () {
            account.enabled = status
          }, failWs)
      },
      getSalePersonGoals(username) {
        // this.openWindow('/bo/commercial/salesperson/goals/' + username)
        postWs("/bo/commercial/salesperson/getGoals",
          true, {username: username},null,
          response => {
            this.originalGoals = []
            this.editGoals = response.data
            this.editGoals.goals.forEach(goal => {
              this.originalGoals[goal.month] = goal.value
            })
          }
          , failWs)
        this.modalGoals = true
      },
      saveSalePersonGoals(username, month, value) {
        postWs("/bo/commercial/salesperson/saveGoal",
          true, null,{username: username, month: month, value:value},
          response => {
            this.getSalePersonGoals(username)
          }
          , failWs)
        this.modalGoals = true
      },
      exportData(){
        postWs("/bo/commercial/salesperson/list",true, null,
          {
            username: this.search.key,
            enabled: this.search.enabled,
            region: this.search.region,
            monthAndYear: (this.search.monthAndYear.getMonth()+1)+"/"+this.search.monthAndYear.getFullYear(),
            completeCommissionReport: this.search.completeCommissionReport,
            reportType: 'XLS'
          },
          response =>{
            download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
              "relatorio_executivos_vendas_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
          }, error => {
            swal({
              title: 'Ops!',
              text: 'Ocorreu algum erro na sua requisição. Verifique os dados e tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'error'
            })
          })
      },
      openSalePersonDashboard(username){
        this.openWindow('/bo/commercial/dashboard/' + username)
      },
      openSalePersonMonthsOperations(username){
        this.openWindow('/bo/commercial/clients-last-months-transactions?salePerson=' + username)
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss" scoped>

</style>
