<template>
  <div class="dzs-tev">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Recarga Manual de PIX
          </h4>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div style="float: left; width: 84%">
                        <label class="control-label">Transferir para</label>
                        <label class="control-label" style="float: right">
                        </label>
                        <fg-input v-model="transfer.destination" style="width: 100%">
                          <template slot="addonLeft">@</template>
                        </fg-input>
                      </div>
                      <p-button class="search" style="margin-top: 25px; float: left" @click="search">
                        <i slot="label" class="fas fa-search"></i>
                      </p-button>
                    </div>
                    <div class="col-md-12">
                      <br>
                      <br>
                      <card v-if="!isNullOrEmpty(transfer.destinationName)">
                        <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationName)" style="text-align: center">
                          <div class="col-sm-12">
                            <label class="control-label">Nome Destino</label>
                            <p class="form-control-static">{{transfer.destinationName}}</p>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationSocialNumber)" style="text-align: center">
                          <div class="col-sm-12">
                            <label class="control-label">CPF/CNPJ</label>
                            <p class="form-control-static">{{transfer.destinationSocialNumber}}</p>
                            <input v-mask="['###.***.***-##', '##.###.###/####-##']" v-model="transfer.destinationSocialNumber"
                                   style="display: none"/>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="!isNullOrEmpty(transfer.email)" style="text-align: center">
                          <div class="col-sm-12">
                            <label class="control-label">E-mail Destinatário</label>
                            <p class="form-control-static">{{transfer.email}}</p>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="!isNullOrEmpty(transfer.destinationName)">
                  <label class="control-label">ID Depósito</label>
                  <fg-input v-model="charge.idDeposit" style="width: 100%">
                  </fg-input>
                </div>
              </div>
              <hr v-if="!isNullOrEmpty(transfer.destinationName)"/>
              <div class="row" v-if="!isNullOrEmpty(transfer.destinationName)">
                <div class="col-md-12">
                  <p-button type="info" @click="callTransaction"
                            style="margin: 0 auto; display: table; min-width: 260px; height: 80px">
                    <i slot="label" class="fas fa-exchange-alt"></i> Recarregar
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        transfer: {
          destination: '',
          destinationName: '',
          destinationSocialNumber: '',
          email: '',
        },
        charge: {
          idDeposit: '',
          destinationUser: '',
        }
      }
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      search() {
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        postWs("/acc/find",
          true, {username: this.transfer.destination}, null,
          (response) => {
            this.transfer.destinationName = response.data.destinationName
            this.transfer.destinationSocialNumber = response.data.destinationSocialNumber
            this.transfer.email = response.data.email
            this.charge.destinationUser = this.transfer.destination
          }, (error) => {
            this.transfer.destinationName = ''
            this.transfer.destinationSocialNumber = ''
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada para receber transações.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
          })
      },
      callTransaction(){
        postWs("/pix/pix-charge",
          true, null, this.charge,
          (response) => {
            swal({
              title: 'Sucesso!',
              text: 'Sucesso ao realizar recarga de PIX!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
          }, (error) => {
            let text = 'Erro ao realizar recarga.';
            if (error.response.status == 404) {
              text = 'ID do depósito não encontrado.'
            } else if (error.response.status == 428) {
              text = 'ID do depósito duplicado, já foi realizada a recarga com esse ID.'
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-tev {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .search {
      background-color: #17a2b8 !important;
    }

    .search:hover {
      background-color: #0a7282 !important;
    }
  }
</style>
