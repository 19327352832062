<template>
  <div class="dzs-credit-ted">
    <div class="row">
      <div class="col-md-12" v-show="showForm">
        <card>
          <h4 slot="header" class="card-title">
            Recarga via TED
          </h4>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <card>
                    <p style="color: #667eea">
                      Como Funciona a recarga via TED?
                    </p>
                    <p>
                      Para cada nova recarga via TED é gerado um termo, onde serão solicitados:
                    </p>
                    <ul>
                      <p><i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Valor da Recarga.<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> CPF ou CNPJ do depositante.<br></p>
                    </ul>
                    <p>
                      Apos o preenchimento desses dados será gerado um termo junto a SuitPay, que deverá ser validado através de sua senha de acesso.
                    </p>
                    <p>
                      Essas informações servirão para a identificação da sua transferência e disponibilização do saldo.
                    </p>
                    <p style="color: #667eea">
                      Atenção:
                    </p>
                    <ul>
                      <p><i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Serão aceitas recargas entre R$ 20,00 e R$ 99.000,00;<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Serão validadas apenas as recargas com termos gerados e assinados;<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> O saldo será creditado após a verificação de compensação em conta, podendo levar até 24 horas.</p>
                    </ul>
                    <p>
                      Antes de realizar o registro do termo de depósito, os valores devem ser enviados para a seguinte conta bancária:
                    </p>
                    <ul>
                      <p><i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Vivipay Serviços e Intermediações S/A<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> CNPJ: 28.856.012/0001-31<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Banco: Banco Bradesco<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> AG: 0548<br>
                        <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> C/C: 18348-2</p>
                    </ul>
                  </card>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="control-label">Valor</label>
                      <label class="control-label" style="float: right">
                        <a href="#" @click="loadHistory">
                          <i slot="label" class="fas fa-history"></i> Histórico de Recargas</a>
                      </label>
                      <fg-input placeholder="0,00" v-money="'money'" type="tel"
                                v-model="credit.value">
                        <template slot="addonLeft">R$</template>
                      </fg-input>
                      <label class="control-label" style="float: left; margin-top: -5px; margin-bottom: 10px">
                        Valor Mínimo: <b>R$ 20,00</b>
                      </label>
                    </div>
                  </div>
                  <fg-input label="CPF/CNPJ Depositante"
                            type="tel"
                            v-mask="['###.###.###-##','##.###.###/####-##']"
                            name="CPF/CNPJ Depositante"
                            v-validate="{cpfCnpjValid:true, required: true, min: 14}"
                            :error="getError('CPF/CNPJ Depositante')"
                            v-model="credit.sourceAccountOwnerSocialNumber">
                  </fg-input>
                  <br><br><br>
                  <div class="row">
                    <div class="col-md-12">
                      <p-button type="info" @click="getCreditTed"
                                style="margin: 0 auto; display: table; min-width: 260px; height: 80px">
                        <i slot="label" class="fa fa-retweet"></i> Solicitar
                      </p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-show="showList">
        <card>
          <h4 slot="header" class="card-title">
            Histórico de Recargas Via TED
          </h4>
          <div class="card-body row">
            <div class="col-md-3">
              <fg-input label="Status">
                <el-select size="large"
                           placeholder="Selecionar"
                           class="select-default"
                           v-model="search.status">
                  <el-option :value="null"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="WAITING_APPROVAL"
                             class="select-default"
                             label="Aguardando Aprovação"/>
                  <el-option value="PROCESSED"
                             class="select-default"
                             label="Processados"/>
                  <el-option value="REJECTED"
                             class="select-default"
                             label="Rejeitados"/>
                  <el-option value="FAIL"
                             class="select-default"
                             label="Erro"/>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [5,10,25,50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 25px;"
                        @click="findHistory">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Buscar
              </p-button>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="70" label="Valor">
                  <template slot-scope="props">
                      <span>
                        <span>R$ {{toMoney(props.row.value)}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Data Solicitação">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.requestDate}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="CPF/CNPJ Depositante">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.sourceAccountOwnerSocialNumber}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Status">
                  <template slot-scope="props">
                      <span>
                        <span :title="props.row.alert">{{statusToLabel(props.row.status)}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="60" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" icon title="Visualizar Termo"
                              @click="printTerm(props.row.id)">
                      <i class="fas fa-file-invoice"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                {{pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadDataHistory">
              </p-pagination>
            </div>
          </div>
          <p-button type="info" style="margin: 0 auto; margin-top: 40px; display: table"
                    @click="showList = false; showForm = true">
            <i slot="label" class="fas fa-undo"></i> Voltar
          </p-button>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {toMoney, moneyToFloat, isNullOrEmpty, notNull, isValidCnpj, isValidCpf} from "src/util/core.utils"
  import {failWs, postWs} from 'src/ws.service'
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        usernameDisplay: '',
        showForm: true,
        showList: false,
        serviceFee: '4,70',
        fileMaxSize: 5,
        file: '',
        fileUpload: false,
        buttonFileName: 'Anexar Comprovante do TED (Máx. 5MB)',
        credit: {
          sourceAccountOwnerName: '',
          sourceAccountOwnerSocialNumber: '',
          value: '',
          passwordTerm: '',
          tedDate: '',
        },
        search: {
          status: null
        },
        term: {
          name: '',
          cpfCnpj: '',
          address: '',
          currentDate: ''
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      getCreditTed(){
        if (moneyToFloat(this.credit.value) <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          postWs("/credit-ted/get-term-data",
            true, null, null,
            this.acceptTerm,
            function (error) {
              if (error.response.status == '404') {
                swal({
                  title: 'Erro!',
                  text: 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.',
                  buttonsStyling: true,
                  timer: 10000,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'error'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro ao solicitar recarga, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            })
        })
      },
      acceptTerm(response){
        this.term.name = response.data.name
        this.term.address = response.data.address
        this.term.cpfCnpj = response.data.cpfCnpj
        this.term.currentDate = response.data.currentDate
        swal({
          title: 'Termo de Responsabilidade',
          html: "<div style='text-align: left;'>Eu " + this.term.name + ", inscrito no CPF/CNPJ sob nº " + this.term.cpfCnpj + ", residente no endereço " + this.term.address + ", reconheço que a SuitPay é apenas uma facilitadora de pagamentos, regulamentada pela Lei 12.865/13.<br><br>" +
            "Posto isto, realizo uma recarga no valor de R$ " + this.credit.value + ", e entendo que a SuitPay não vende, oferece produtos e/ou investimentos, portanto não se responsabiliza por negócios realizados entre terceiros, por consequência não garante a qualidade ou entrega dos mesmos.<br><br>" +
            "Sendo assim, tendo total ciência dos termos de uso desta plataforma, realizarei uma(s) TED’s, para recaregar minha conta " + this.usernameDisplay + " na SuitPay.<br><br>" +
            "Cientifico que sou responsável pela legalidade da negociação realizada.<br><br>" +
            "Para validar este documento se faz necessário assinatura e documento pessoal digitalizado (o mesmo documento usado para validar sua conta na plataforma da SuitPay).<br><br></div>" +
            "Goiânia - GO, " + this.term.currentDate,
          //type: 'warning',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#667eea',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Assinar Termo',
          cancelButtonText: 'Cancelar',
          width: 800
        }).then((result) => {
          if (result) {
            this.signTerm()
          }
        })
      },
      signTerm(){
        swal({
          title: 'Assinar Termo',
          text: 'Confirme a sua senha para assinar o termo e finalizar a solicitação de recarga:',
          input: 'password',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#667eea',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          width: 400,
          preConfirm: (password) => {
            this.passwordTerm = password
            this.requestCreditTed()
          }
        })
      },
      requestCreditTed(){
        postWs("/credit-ted/request",
          true,
           {value: moneyToFloat(this.credit.value),
             sourceAccountOwnerSocialNumber: this.credit.sourceAccountOwnerSocialNumber,
             passwordTerm: this.passwordTerm}, null,
          this.requestSuccess,
          function (error) {
            if(error.response.status == '403'){
              swal({
                title: 'Erro!',
                text: 'Senha incorreta!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            if(error.response.status == '412'){
              swal({
                title: 'Erro!',
                text: 'O valor máximo para recarga via TED é R$ 99.000,00 e o valor mínimo é de R$ 20,00!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            if(error.response.status == '406'){
              swal({
                title: 'Erro!',
                text: 'Você atingiu o número máximo de solicitões diárias de recarga via TED, você só pode ter 10 solicitações em aberto!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            if(error.response.status == '411'){
              swal({
                title: 'Erro!',
                text: 'Confira o formato e o tamanho da imagem do comprovante de TED!',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            if(error.response.status == '401'){
              swal({
                title: 'Erro!',
                text: 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            if(error.response.status == '400'){
              swal({
                title: 'Erro!',
                text: 'Erro interno do sistema, confirme os dados e tente novamente.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
              return
            }
            swal({
              title: 'Erro!',
              text: 'Erro ao solicitar recarga, verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'error'
            })
          })
      },
      requestSuccess(){
        this.credit.value = 0
        this.credit.sourceAccountOwnerName = ''
        this.credit.sourceAccountOwnerSocialNumber = ''
        this.credit.tedDate = ''
        this.file = ''
        this.buttonFileName = 'Anexar Comprovante do TED (Máx. 5MB)'
        swal({
          title: 'Sucesso!',
          text: 'Requisição de recarga via TED efetuada com sucesso!',
          buttonsStyling: true,
          timer: 10000,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
      },
      chooseFiles() {
        document.getElementById("file").click()
      },
      handleFileUpload(){
        if (this.$refs.file.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
          swal({
            title: 'Aviso!',
            text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.file = this.$refs.file.files[0]
        this.buttonFileName = this.file.name
        this.fileUpload = true
      },
      loadHistory(){
        this.showList = true
        this.showForm = false
        this.loadDataHistory();
      },
      findHistory() {
        this.pagination.currentPage = 1
        this.loadDataHistory()
      },
      loadDataHistory() {
        postWs("/credit-ted/get-list-user",
          true, null,
          {
            status: this.search.status,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status) {
        if (status == 'WAITING_APPROVAL') {
          return 'AG. APROVAÇÃO'
        } else if (status == 'PROCESSED') {
          return 'PROCESSADO'
        } else if (status == 'REJECTED') {
          return 'REJEITADO'
        } else if (status == 'FAIL') {
          return 'ERRO'
        } else if (status == 'EXTERNAL_BANK_ERROR') {
          return 'ERRO INTERNO'
        }
        return '??'
      },
      printTerm(id) {
        postWs("/credit-ted/get-pdf-term",
          true,
          {idCreditTed: id}, null,
          this.successPrintTerm,
          function (error) {
            swal({
              title: 'Erro!',
              text: 'Erro ao emitir o Termo de Responsabilidade!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'error'
            })
          })
      },
      successPrintTerm(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Termo de Responsabilidade</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.pdfBase64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  .dzs-credit-ted {
    .autocomplete__icon {
      display: none;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .button-file {
      width: 100%;
    }

    .term {
      text-align: justify;
    }
  }
</style>
