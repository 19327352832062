<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Vendas de BTC</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.username"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.status">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="NOT_PROCESSED"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processados"/>
                <el-option value="WAITING_BTC"
                           class="select-default"
                           label="Aguardando Bitcoin"/>
                <el-option value="CANCELED"
                           class="select-default"
                           label="Cancelado"/>
                <el-option value="REJECTED"
                           class="select-default"
                           label="Rejeitados"/>
                <el-option value="ERROR"
                           class="select-default"
                           label="Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Cotação BTC Makes">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.btcBrlSell)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Cotação BTC Bank4u">
                <template slot-scope="props">
                      <span>
                        <span>R$ {{toMoney(props.row.btcBrlSellTotal)}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BTC">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.amountBtcSell}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BRL">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.amountBrlSell)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Wallet Cliente">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.bitcoinWalletUser}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Order_Id">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.orderId}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusToLabel(props.row.statusTransactionBtc)}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
  import {isNullOrEmpty, toMoney} from "../../../../../util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          username: '',
          status: null,
          type: 'SELL'
        },
        statusDefault: null,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      resetDefaultValues(){
        this.search.username = ''
        this.search.status = null
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bitcoin/get-list",
          true, null,
          {
            username: this.search.username,
            statusTransactionBtc: this.search.status,
            transactionBtcType: this.search.type,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status) {
        if (status == 'NOT_PROCESSED') {
          return 'AG. APROVAÇÃO'
        } else if (status == 'PROCESSED') {
          return 'PROCESSADO'
        } else if (status == 'REJECTED') {
          return 'REJEITADO'
        } else if (status == 'WAITING_BTC') {
          return 'AG. BITCOIN'
        } else if (status == 'CANCELED') {
          return 'CANCELADO'
        } else if (status == 'ERROR') {
          return 'ERRO'
        }
        return '??'
      },
      confirmTransaction(id, value, username) {
        swal({
          title: 'Aprovar Venda',
          text: "Você deseja realmente aprovar a venda de BTC no valor de R$ "+value+
            " na conta do usuário @"+username+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'NÃO',
          cancelButtonColor: '#ef8157'
        }).then((result) => {
          if(result) {
            postWs("/bitcoin/confirm-transaction-sell",
              true, null,
              {
                uuidTrBtc: id,
                statusTransactionBtc: 'PROCESSED'
              }, () => {
                swal({
                  title: 'Sucesso!',
                  text: 'Pagamento da venda realizado com sucesso!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  type: 'success'
                }), this.loadData()
              },
            function (error) {
              if(error.response.status == '412'){
                swal({
                  title: 'Saldo Insuficiente!',
                  text: 'Saldo da conta da Exchange insuficiente para pagamento da venda!',
                  buttonsStyling: true,
                  timer: 15000,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro ao realizar pagamento!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
            })
          }
        })
      },
      rejectTransaction(id, username) {
        swal({
          title: 'Rejeitar Venda',
          text: "Você deseja realmente rejeitar a venda de BTC " +
            " para o usuário "+username+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'NÃO',
          cancelButtonColor: '#ef8157'
        }).then((result) => {
          if(result) {
            postWs("/bitcoin/confirm-transaction-sell",
              true, null,
              {
                uuidTrBtc: id,
                statusTransactionBtc: 'REJECTED'
              }, swal({
                title: 'Sucesso!',
                text: 'Venda rejeitada com sucesso!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'success'
              }),
              this.loadData,
              failWs)
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
