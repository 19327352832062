<template>
  <div class="suit-journey-notification" style="background: url(/static/img/suitjourney/cimento.png);" @click="$emit('click', itemName)">
    <div>
      <img :src="`/static/img/suitjourney/icons/bordered/${notificationNameDictionary[itemName].icon}`" alt="">
    </div>
    <div>
      <h2>PARABÉNS!</h2>
      <h2>VOCÊ DESBLOQUEOU O <span>{{ notificationNameDictionary[itemName].name }}</span></h2>
      <p v-if="['GREEN GATE', 'BLACK SUIT'].includes(notificationNameDictionary[itemName].name)">Clique aqui e leia a carta para acessá-lo</p>
      <p v-else>Clique aqui, leia a carta e resgate sua recompensa</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuitJourneyNotification",
  props: ["itemName"],
  emits: ["click"],
  data() {
    return {
      notificationNameDictionary: {
        greenGateLetter: {
          name: 'GREEN GATE',
          icon: 'greenGate.png'
        },
        blackSuitLetter: {
          name: 'BLACK SUIT',
          icon: 'blackSuit.png'
        },
        theOracleLetter: {
          name: 'THE ORACLE',
          icon: 'theOracle.png'
        },
        theLabyrinthLetter: {
          name: 'THE LABYRINTH',
          icon: 'theLabyrinth.png'
        },
        theAbyssLetter: {
          name: 'THE ABYSS',
          icon: 'theAbyss.png'
        },
        theWayLetter: {
          name: 'THE WAY',
          icon: 'theWay.png'
        },
        blackOceanLetter: {
          name: 'BLACK OCEAN',
          icon: 'blackOcean.png'
        },
        suitSourceLetter: {
          name: 'SUIT SOURCE',
          icon: 'suitSource.png'
        },
        theLibertyLetter: {
          name: 'THE LIBERTY',
          icon: 'theLiberty.png'
        },
      }
    }
  }
}
</script>

<style lang="scss">
.suit-journey-notification {
  z-index: 998;
  position: fixed;
  top: 2rem;
  right: 0;
  width: fit-content;
  border-radius: 1rem 0 0 1rem;
  padding: .7rem 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: 2px solid #33CC33;
  cursor: pointer;
  box-shadow: 0 16px 32px rgba(51, 204, 51, .35);

  h2,p {
    color: #FFF;
    font-size: 1.25rem;
    font-family: ArticulatCF, sans-serif;
    font-weight: bold;
    margin: 0;

    span {
      color: #33CC33;
    }
  }

  p {
    font-weight: normal;
  }

  div {
    img {
      height: 100%;
      max-height: 5rem;
    }
  }
}
</style>
