<template>
  <div class="dzs-accesscheckout">
    <div class="row">
      <div class="col-md-5">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title" style="text-align: center">
            Gateway e Checkout <b style="color: #000000 !important;">SuitPay</b>
          </h4>
          <banner></banner>
          <br>
          <br>
          <p>Com o Gateway e Checkout <b style="color: #000000 !important;">SuitPay</b> você tem:</p>
          <p>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Tecnologia de ponta que processa milhares de transações por segundo.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Cartão de crédito, boleto e Pix para o seu cliente pagar como quiser e você não perder nenhuma venda.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Dashboard personalizada para acompanhar vendas e monitorar o fluxo financeiro da sua empresa.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Proteção contra fraudes e contestações, os dados dos clientes ficam seguros e criptografados.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento das vendas pelo CARTÂO em D+3.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento nas vendas por BOLETO em D+2.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento nas vendas por PIX na hora.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Integração com as maiores plataformas de e-commerce do mercado.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Conexão direta com a adquirente, que faz a intermediação com a bandeira do cartão.<br>
          </p>
        </card>
      </div>
      <div class="col-md-7" v-show="gatewayAccountUnderAnalysys.form">
        <card style="background-color: #00d19a; padding-bottom: 50px; text-align: center" >
          <h4 slot="header" class="card-title" style="color: #fff; text-align: center">Seus dados bancários estão em análise!</h4>
          <card style=" text-align: center; padding: 5%;">
            <div class="row">
              <div class="col-md-12">
                <p>Os dados abaixo foram submetidos à análise. Poderá levar até <b>72 horas</b> para análise da conta bancária e liberação do serviço.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Banco: {{gatewayAccountUnderAnalysys.bankCode}} - {{gatewayAccountUnderAnalysys.bankName}}</p>
                <p>Agência: {{gatewayAccountUnderAnalysys.agencyNumber}}</p>
                <p>Conta: {{gatewayAccountUnderAnalysys.accountNumber}} - {{gatewayAccountUnderAnalysys.accountNumberDv}}</p>
                <p>Beneficiário: {{gatewayAccountUnderAnalysys.ownerName}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Enquanto isso desfrute de todos os outro serviços que a <b style="color: #000000 !important;">SuitPay</b> fornece a você e a sua empresa, e logo esperamos que você possa utilizar o melhor e mais completo Gateway e Checkout do mercado.</p>
              </div>
            </div>
          </card>
          <br>
          <img style="max-width: 150px" src="/static/img/vue-logo.png" alt="..."  ><br>
          <span style="font-size: 15px; color: #FFF"><b>Anytime, Anywhere</b>!</span>
        </card>
      </div>
      <div class="col-md-7" v-show="noticeForm">
        <card>
          <h5>{{this.noticeTitle}}</h5>
          <p>{{this.noticeText}}</p>
          <div class="row">
            <div class="col-md-12">
              <p>Verifique com o suporte suas permissões e tente novamente.</p>
              <p>Logo você vai poder utilizar o melhor Gateway e Checkout.</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-7" v-show="bankDetailsForm">
        <card>
          <h5>{{this.noticeTitle}}</h5>
          <p><b>Seja à vista ou parcelado</b>, a <b style="color: #000000 !important;">SuitPay</b> tem as melhores taxas para sua loja vender mais.<br><br>
            Para iniciar a utilização do Gateway e Checkout, informe uma conta bancária para recebimento através de cartão de crédito.
            Os valores dessa modalidade serão depositados diretamente nesta conta informada.
            Lembrando que a conta deve ser de mesma titularidade dos dados cadastrados na abertura da conta <b style="color: #000000 !important;">SuitPay</b>.<br><br>
            Após a inserção dos dados pedimos até 24 horas para análise da conta bancária e liberação do serviço.
          </p>
          <p>Os recebimentos através de Boleto e PIX serão depositados normalmente em sua Conta <b style="color: #000000 !important;">SuitPay</b>.</p>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Banco</label>
              <autocomplete ref="bankAc"
                            v-validate="{required: bankDetailsForm}"
                            placeholder="Selecionar"
                            :source="banksCodes"
                            input-class="form-control"
                            results-value="code"
                            :results-display="displayBankName"
                            @selected="selectBank">
              </autocomplete>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-12">
              <label class="control-label">Tipo de Conta</label>
              <el-select size="large" placeholder="Selecionar" style="width: 100%"
                         v-model="bankDetails.accountType">
                <el-option class="select-success"
                           value="CC"
                           label="Corrente"/>
                <el-option class="select-info"
                           value="PP"
                           label="Poupança"/>
              </el-select>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-8">
              <label class="control-label">Agência</label>
              <fg-input v-model="bankDetails.agencyNumber" style="width: 100%"
                        v-validate="{required: bankDetailsForm, agencyBankValid: true}" type="tel" maxlength="5"
                        :error="getError('Agência Destino')"
                        name="Agência Destino" v-mask="['#####']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito da Agência</label>
              <fg-input v-model="bankDetails.agencyDv" style="width: 100%"
                        type="tel" maxlength="1"
                        name="Dígito" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <label class="control-label">Número da Conta</label>
              <fg-input v-model="bankDetails.accountNumber" style="width: 100%"
                        v-validate="{required: bankDetailsForm}" type="tel" maxlength="12"
                        :error="getError('Conta Destino')"
                        name="Conta Destino" v-mask="['############']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito da Conta</label>
              <fg-input v-model="bankDetails.accountNumberDv" style="width: 100%"
                        v-validate="{required: bankDetailsForm}"
                        type="tel" maxlength="1" :error="getError('Dígito')"
                        name="Dígito" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <div class="row" v-show="legalPersonOwnerForm">
            <div class="col-md-12">
              <p>Precisamos também dos dados do representante legal da empresa, conforme documentos enviados na abertura da conta.</p>
            </div>
            <div class="col-md-12">
              <fg-input type="text"
                        label="Nome Completo Representante Legal"
                        maxlength="100"
                        name="Nome Completo Representante Legal"
                        v-validate="{required: legalPersonOwnerForm}"
                        :error="getError('Nome Completo Representante Legal')"
                        v-model="bankDetails.ownerName">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="CPF Representante Legal"
                        type="tel"
                        v-mask="['###.###.###-##']"
                        name="CPF Representante Legal"
                        v-validate="{cpfCnpjValid:legalPersonOwnerForm, required: legalPersonOwnerForm, min: 14}"
                        :error="getError('CPF Representante Legal')"
                        v-model="bankDetails.ownerDocument">
              </fg-input>
            </div>
          </div>
          <div class="text-center" style="padding-bottom: 15px">
            <button type="info" @click="saveBankDetails" class="btn btn-info btn-fill btn-wd">
              Salvar Dados Complementares
            </button>
          </div>
        </card>
      </div>
      <div class="col-md-7" v-show="!bankDetailsForm && !noticeForm && !gatewayAccountUnderAnalysys.form">
        <div class="row">
          <div class="col-md-12" v-if="isNullOrEmpty(qrCode)">
            <card style="background-color: #00d19a; padding-bottom: 50px; text-align: center" >
              <h4 slot="header" class="card-title" style="color: #fff; text-align: center">Gateway e Checkout <b>SuitPay</b></h4>
              <card style=" text-align: center; padding: 5%;">
                <div class="row">
                  <div class="col-md-12">
                    <p>Para acessar o seu Checkout <b style="color: #000000 !important;">SuitPay</b> e realizar a integração com o seu e-commerce, clique no botão abaixo:</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="info" @click="externalLogin" class="btn btn-info btn-fill btn-wd">
                      Acessar Checkout
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="padding-top: 50px">
                    <p>Você também pode realizar a integração com o Gateway <b style="color: #000000 !important;">SuitPay</b>, para gerar as suas chaves de acesso ao Gateway, clique no botão abaixo:</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="info" @click="confirmNewKeyGeneration()" class="btn btn-info btn-fill btn-wd">
                      Gerar Chaves
                    </button>
                  </div>
                </div>
              </card>
              <br>
              <img style="max-width: 150px" src="/static/img/vue-logo.png" alt="..."  ><br>
              <span style="font-size: 15px; color: #FFF"><b>Anytime, Anywhere</b>!</span>
            </card>
          </div>
          <!-- Bloco Dispositivo Não habilitado -->
          <div class="col-md-12" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
            <card style="background-color: #00d19a; text-align: center">
              <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
              <card align="center" >
                <span>Para liberar suas transações siga os passos abaixo:</span>
                <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
                  <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
                </div>

                <div class="row">
                  <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                    <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                    <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                    <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                    <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                    <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                    </a>
                    <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                    </a>
                  </div>
                </div>
                <br>
                <div style="text-align: center">
                  <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                            class="btn btn-danger" >
                    <i class="fas fa-chevron-left"/> Voltar
                  </p-button>
                </div>
              </card>
            </card>
          </div>

          <!-- Bloco Dispositivo Habilitado -->
          <div class="col-md-12" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
            <card style="background-color: #00d19a; text-align: center; height: 100%">
              <h4 slot="header" class="card-title" style="color: #fff">Confirmar Geração de Chaves</h4>
              <card align="center" style="height: 100%" >

                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

                <div class="row">
                  <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                    <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                    <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                    <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                    <p><b>4</b> - Confirme a operação.</p>
                    <p><b>5</b> - Pronto!</p>
                  </div>
                </div>

                <span>Informe o <b>SuitID</b>: </span>
                <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

                <div class="row" v-if="!codeValid">
                  <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
                </div>

                <div style="text-align: center">
                  <p-button @click="()=>{qrCode = ''; clearInterval(validateIntervalLoop)}"
                            class="btn btn-danger" >
                    <i class="fas fa-chevron-left"/> Voltar
                  </p-button>
                  <p-button @click="()=>{validateCode()}"
                            class="btn btn-primary"
                            :disabled="totpConfirmBtnDisabled"
                  >
                    <i class="fas fa-chevron-right"/> Confirmar
                  </p-button>
                </div>
              </card>
            </card>
          </div>
        </div>
      </div>
    </div>
    <form action="https://checkout.suitpay.app/login/externalAuthentication" method="POST" id="checkout" target="_blank">
      <input type="text" name="token" hidden :value="tokenCheckout"/>
    </form>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import {callWs, CHECKOUT_URL, getWs, postWs} from "src/ws.service"
  import {toMoney, moneyToFloat, isNullOrEmpty, notNull, isValidCnpj, isValidCpf} from "src/util/core.utils"
  import swal from 'sweetalert2'
  import {DatePicker} from 'element-ui'
  import banksCodes from "../Transfer/banks-codes"
  import Autocomplete from 'vuejs-auto-complete'
  import Banner from "../../../UIComponents/Promotion/Banner";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      [DatePicker.name]: DatePicker,
      Autocomplete,
      Banner
    },
    data() {
      return {
        tokenCheckout: '',
        username: '',
        disableInfo: false,
        bankDetailsForm: false,
        legalPersonOwnerForm: false,
        noticeForm: true,
        gatewayAccountUnderAnalysys: {
          form: false,
          bankCode: '',
          bankName: '',
          accountType: '',
          agencyNumber: '',
          agencyDv: '',
          accountNumber: '',
          accountNumberDv: '',
          ownerName: '',
        },
        noticeText: '',
        noticeTitle: '',
        bankDetails: {
          username: '',
          bankCode: '',
          accountType: '',
          agencyNumber: '',
          agencyDv: '',
          accountNumber: '',
          accountNumberDv: '',
          ownerName: '',
          ownerDocument: '',
        },
        banksCodes: banksCodes,
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        authorizationId: null,
        codeValid: true,
        qrCode: '',
        totpQrCodeUri: '',
        totpManualCode: '',
        totpConfirmBtnDisabled: false
      }
    },
    created() {
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })

      this.$validator.extend('agencyBankValid', {
        getMessage: field => ('O ' + field + ' é inválido.'),
        validate: (value) => {
          if (isNullOrEmpty(value)) {
            return false
          }
          if(/^0+$/.test(value)) {
            return false
          }
          return true;
        }
      })

      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
    },
    mounted() {
      this.username = localStorage.getItem("usernameDisplay")
      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      loadData() {
        getWs("/payment-link/validate-user",
          true, {username: this.username},
          this.loadDataSuccess, this.loadDataError)
      },
      loadDataSuccess(response) {
        this.noticeForm = false
        this.tokenCheckout = response.data.tokenCheckout
      },
      loadDataError(error) {
        console.log("Erro: " + JSON.stringify(error.response.status))
        this.noticeForm = false
        this.bankDetailsForm = false
        this.legalPersonOwnerForm = false
        this.gatewayAccountUnderAnalysys.form = false
        if(error.response.status === 400) {
          if(error.response.data.response === 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT') {
            this.noticeForm = true
            this.noticeTitle = "Conta faltando documentação."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response === 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
            this.noticeForm = true
            this.noticeTitle = "Conta em análise."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response === 'GATEWAY_ACCOUNT_UNDER_ANALYSIS') {
            this.gatewayAccountUnderAnalysys.form = true
            this.gatewayAccountUnderAnalysys.bankCode = error.response.data.bankCode
            this.gatewayAccountUnderAnalysys.bankName = error.response.data.bankName
            this.gatewayAccountUnderAnalysys.agencyNumber = error.response.data.agencyNumber
            this.gatewayAccountUnderAnalysys.agencyDv = error.response.data.agencyDv
            this.gatewayAccountUnderAnalysys.accountNumber = error.response.data.accountNumber
            this.gatewayAccountUnderAnalysys.accountNumberDv = error.response.data.accountNumberDv
            this.gatewayAccountUnderAnalysys.accountType = error.response.data.accountType
            this.gatewayAccountUnderAnalysys.ownerName = error.response.data.ownerName
            this.noticeTitle = "Conta em análise."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response === 'USER_WITHOUT_REGISTERED_BANK_DATA_PF') {
            this.bankDetailsForm = true
            this.noticeTitle = "Envio de dados complementares"
            this.noticeText = "Precisamos de mais algumas informações complementares para a realização dos pagamentos de vendas realizadas" +
              " por cartão. Por isso precisamos que você informe uma referência bancária de outra instituição."
          } else if(error.response.data.response === 'USER_WITHOUT_REGISTERED_BANK_DATA_PJ') {
            this.bankDetailsForm = true
            this.legalPersonOwnerForm = true
            this.noticeTitle = "Envio de dados complementares"
            this.noticeText = "Precisamos de mais algumas informações complementares para a realização dos pagamentos de vendas realizadas" +
              " por cartão. Por isso precisamos que você informe uma referência bancária de outra instituição."
          } else if (error.response.data.response === 'NATURAL_PERSON_NOT_ENABLED') {
            this.noticeForm = true
            this.noticeTitle = "Aviso"
            this.noticeText = error.response.data.message
          } else if (error.response.data.response === 'BANKING_ACCOUNT_UNDER_ANALYSIS') {
            this.noticeForm = true
            this.noticeTitle = "Aviso"
            this.noticeText = error.response.data.message
          } else if (error.response.data.response === 'BANKING_ACCOUNT_REJECTED') {
            this.noticeForm = true
            this.noticeTitle = "Aviso"
            this.noticeText = error.response.data.message
          } else if (error.response.data.response === 'BANKING_ACCOUNT_UNDER_CREATION') {
            this.noticeForm = true
            this.noticeTitle = "Aviso"
            this.noticeText = error.response.data.message
          } else if (error.response.data.response === 'BANKING_ACCOUNT_ERROR_CREATION') {
            this.noticeForm = true
            this.noticeTitle = "Aviso"
            this.noticeText = error.response.data.message
          }
        } else {
          this.noticeForm = true
          this.noticeTitle = 'Erro ao carregar tela do Gateway e Checkout.'
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      openFormLink() {
        this.disableInfo = true
      },
      getBank(code) {
        for (let bank in this.banksCodes) {
          if (this.banksCodes[bank].code == code) {
            return this.banksCodes[bank]
          }
        }
        return null
      },
      selectBank(bank) {
        this.bankDetails.bankCode = bank.selectedObject.code
      },
      displayBankName(bank) {
        return bank.code + ' - ' + bank.name
      },
      saveBankDetails() {
        if (isNullOrEmpty(this.bankDetails.bankCode)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Banco é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        } else if (isNullOrEmpty(this.bankDetails.accountType)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Tipo de Conta é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.bankDetails.username = localStorage.getItem("usernameDisplay")
          postWs("/acc/save-bank-details", true, null, this.bankDetails,
            () => {
              this.loadData()
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
            }, (error) => {
              swal({
                title: 'Falha!',
                text: 'Erro ao salvar dados bancários, confira os dados e tente novamente.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      checkout() {
        if(isNullOrEmpty(this.tokenCheckout)) {
          swal({
            title: 'Aviso!',
            text: 'Checkout não está ativo para essa conta, entre em contato com o suporte para ativá-lo!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          document.getElementById('checkout').submit()
        }
      },
      externalLogin() {
        getWs("/checkout/external-login",
          true, null,
          this.externalLoginSuccessRedirect, this.loadDataError)
      },
      externalLoginSuccessRedirect(response) {
        this.noticeForm = false

        if (!isNullOrEmpty(response.data.document_number) && !isNullOrEmpty(response.data._token)) {
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = CHECKOUT_URL;

          for (const key in response.data) {
            if (response.data.hasOwnProperty(key)) {
              const hiddenField = document.createElement('input');
              hiddenField.type = 'hidden';
              hiddenField.name = key;
              hiddenField.value = response.data[key];

              form.appendChild(hiddenField);
            }
          }
          document.body.appendChild(form);
          form.submit();
        } else {
          swal({
            title: 'Aviso!',
            text: 'Houve um erro ao realizar a tentativa de login no Checkout, entre em contato com o suporte!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      confirmNewKeyGeneration(){
        swal({
          title: 'Aviso!',
          text: 'Gerar uma nova chave irá revogar o acesso da chave anterior. Deseja prosseguir?',
          buttonsStyling: true,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#17a2b8',
          cancelButtonText: 'CANCELAR'
        }).then(() => {
          this.generateAccess();
        })
      },
      generateAccess(){
        postWs("/api-access-manager/generate-access", true,
          null, {
            authorization: this.authorizationId
          },
          this.showAccessKeys,
          this.failAccessKeys)
      },
      failAccessKeys(error) {
        swal({
          title: 'Erro!',
          text: 'Erro ao gerar as chaves, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      showAccessKeys(response){
        if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
          this.qrCode = response.data.qrCode
          this.totpQrCodeUri = response.data.totpQrCodeUri
          this.totpManualCode = response.data.totpManualCode
          return
        } else {
          this.qrCode = null
          swal({
            title: 'Sucesso',
            html: 'Salve os dados de acesso em um local seguro! Não será possível obtê-los sem gerar uma nova chave.<br><br>' +
              '<div class="form-group">' +
              ' <b>Client Id:</b> <input id="input-field1" style="cursor: pointer" type="text" class="form-control copied-content" readonly value="' + response.data.clientId + '" /><br>' +
              ' <b>Client Secret:</b> <textarea rows="3" id="input-field2" style="cursor: pointer" type="text" class="form-control" readonly>' + response.data.clientSecret + '</textarea>' +
              '</div>',
            buttonsStyling: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonClass: 'btn btn-info btn-fill',
            type: 'info',
            onOpen: () => {
              const iptClientId = document.getElementById("input-field1")
              const iptClientSecret = document.getElementById("input-field2")
              iptClientId.onclick = (event) => this.copyToClipboard(event, 'Client Id')
              iptClientSecret.onclick = (event) => this.copyToClipboard(event, 'Client Secret')
            },
            onClose: () => {
              const iptClientId = document.getElementById("input-field1")
              const iptClientSecret = document.getElementById("input-field2")
              iptClientId.onclick = null
              iptClientSecret.onclick = null
            }
          })
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      confirmCode(response){
        this.authorizationId = response.data.verifCode
        this.totpConfirmBtnDisabled = false
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: this.authorizationId},
          this.generateAccess,
          this.failAccessKeys
        )
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      copyToClipboard(event, customText) {
        const element = event.target
        const stringValue = event.target.value
        navigator.clipboard.writeText(stringValue)
          .then(() => {
            const messages = document.querySelectorAll('.copy-message')
            messages.forEach(message => {message.parentNode.removeChild(message)})
            element.insertAdjacentHTML('afterend', `
                <div class="copy-message text-sm text-center pt-1 text-xs text-success">
                    <small>${customText} copiado</small>
                </div>
            `)
          })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-accesscheckout {
    .vue-barcode-element {
      height: 86px !important;
      display: table;
      margin: 0 auto !important;
    }

    .el-input__inner {
      background-color: #fff !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    @media (max-width: 800px) {
      .vue-barcode-element {
        height: 50px !important;
        width: 330px !important;
      }
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
      line-height: 2.0;
    }

    @media (max-width: 500px) {
      .box2 {
        width: 90%;
      }
    }

    .box3 {
      width: 20%;
      margin-left: 10%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.3%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.3%;
      }
    }
  }
</style>
