<template>
  <div class="row dzs-recommendation">
    <div class="col-md-7">
      <card>
        <h4 slot="header" class="card-title" style="text-align: center; color: #17a2b8">Compartilhe uma melhor forma de trabalhar!</h4>
        <div class="row" style="margin-bottom: 30px">
          <div class="col-md-12" style="text-align: center">
            <p>Agora que você se juntou a nós, ajude a divulgar a <b style="color: #000000 !important;">SuitPay</b>.</p>
            <h5>Como funciona?</h5>
            <p>É muito simples! Nós alinhamos todo o nosso processo de indicação para que seja fácil de usar,
              ao mesmo tempo em que é extremamente preciso e seguro. </p>
            <br>
            <div class="card box2" style="text-align: left">
              <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px; text-align: center"><b>O processo é o seguinte:</b></p>
              <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-top: -16px; margin-left: initial; margin-right: initial;">
              <div class="col-md-2 col-12 topics"><spam class="topics-number">1</spam></div>
              <div class="col-md-1 vl"></div>
              <div class="col-md-9 col-12 topics-text"><p>Seu contato clica em seu link exclusivo.</p></div>
              </div>
              <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                <div class="col-md-2 col-12 topics"><spam class="topics-number">2</spam></div>
                <div class="col-md-1 vl"></div>
                <div class="col-md-9 col-12 topics-text"><p>Ele faz a conta.</p></div>
              </div>
              <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                <div class="col-md-2 col-12 topics"><spam class="topics-number">3</spam></div>
                <div class="col-md-1 vl"></div>
                <div class="col-md-9 col-12 topics-text"><p>A conta dele fica vinculada a sua indicação.</p></div>
              </div>
              <div class="row" style="border-bottom: 1px solid rgb(221, 221, 221); margin-left: initial; margin-right: initial;">
                <div class="col-md-2 col-12 topics"><spam class="topics-number">4</spam></div>
                <div class="col-md-1 vl"></div>
                <div class="col-md-9 col-12 topics-text"><p>Você pode indicar quantas pessoas e empresas quiser!</p></div>
              </div>
            </div>
            <br>
            <h5>Pronto para começar?</h5>
            <p><b>Clique</b> no botão abaixo e copie o link de indicação para compartilhar.</p>
            <div class="row">
              <div class="col-md-6">
                <p-button type="info" class="button"
                          @click="getUrl('TEXT')">
                  <i slot="label" class="fas fa-share"></i> Compartilhar Link
                </p-button>
              </div>
              <div class="col-md-6">
                <p-button type="info" class="button"
                          @click="getUrl('URL')">
                  <i slot="label" class="fas fa-copy"></i> Copiar URL
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-5" >
      <card>
        <div class="card-body row">
          <div class="col-sm-12" align="center">
            <h4 slot="header" class="card-title" style="margin-top: 0px;">
              Usuários Indicados
            </h4>
            <hr>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="timelineItems"
                      style="width: 100%">
              <el-table-column :min-width="100" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span>@{{props.row.username}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Data Cadastro">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.registrationDate}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{paginationItems.fromNumber}} AO {{paginationItems.toNumber}}, DE
              {{paginationItems.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationItems.currentPage"
                          :per-page="paginationItems.perPage"
                          :total="paginationItems.totalNumber"
                          :click="this.loadHistoryRecommendation">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>
    <input type="hidden" id="url-code" :value="urlCode">
    <input type="hidden" id="text-to-share" :value="textToShare">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {postWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty} from "src/util/core.utils"
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Table, TableColumn} from 'element-ui'


  Vue.use(Table)
  Vue.use(TableColumn)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        urlCode: '',
        textToShare: '',
        usernameDisplay: '',
        typeCopy: '',
        timelineItems: [],
        paginationItems: {
          perPage: 10,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.loadHistoryRecommendation()
    },
    methods: {
      loadHistoryRecommendation() {
        postWs("/recommendation/get-list-recommendation",
          true, null,
          {
            pageNumber: (this.paginationItems.currentPage - 1),
            pageSize: this.paginationItems.perPage
          }, this.successLoadHistoryRecommendation, this.failLoadHistoryRecommendation)
      },
      successLoadHistoryRecommendation(response) {
        this.timelineItems = response.data.list
        this.paginationItems.totalNumber = response.data.totalNumber
        this.paginationItems.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationItems.totalNumber > 0) {
          this.paginationItems.fromNumber = ((this.paginationItems.perPage * (this.paginationItems.currentPage - 1)) + 1)
          this.paginationItems.toNumber = ((this.paginationItems.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.paginationItems.fromNumber = 0
          this.paginationItems.toNumber = 0
        }
      },
      failLoadHistoryRecommendation(error) {
        this.timelineItems = []
        return
      },
      getUrl(typeCopy) {
        this.typeCopy = typeCopy
        if (isNullOrEmpty(this.urlCode)) {
          postWs("/recommendation/get-url-recommendation",
            true, null, null, this.success,
            (error) => {
              swal({
                title: 'Aviso!',
                text: 'Erro ao gerar o código de indicação, favor entrar em contato com o suporte!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
          )
        } else {
          if (this.typeCopy == 'URL') {
            this.copyUrlCode()
          } else {
            this.copyTextToShare()
          }
        }
      },
      success(response) {
        this.urlCode = response.data
        this.textToShare = 'Olá, tudo bem? ' +
          'Gostaria de te apresentar a SuitPay. ' +
          'Uma plataforma online que gerencia todos os seus pagamentos e recebimentos de forma rápida e prática. ' +
          'Acesse o link e abra sua conta hoje mesmo! ' + this.urlCode

        if (this.typeCopy == 'URL') {
          let urlCode = document.querySelector('#url-code')
          urlCode.setAttribute('value', this.urlCode)
          this.copyUrlCode()
        } else {
          let textShare = document.querySelector('#text-to-share')
          textShare.setAttribute('value', this.textToShare)
          this.copyTextToShare()
        }
      },
      copyUrlCode() {
        let urlCode = document.querySelector('#url-code')
        urlCode.setAttribute('type', 'text')
        urlCode.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if (msg == 'successful') {
            swal({
              text: 'URL de indicação copiada com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          } else {
            swal({
              text: 'Erro ao copiar URL de indicação!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar URL de indicação!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        urlCode.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      copyTextToShare() {
        let textShare = document.querySelector('#text-to-share')
        textShare.setAttribute('type', 'text')
        textShare.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if (msg == 'successful') {
            swal({
              text: 'O link para compartilhamento foi copiado com sucesso.',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          } else {
            swal({
              text: 'Erro ao copiar Link de indicação!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar Link de indicação!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        textShare.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      }
    }
  }
</script>
<style lang="scss">
  .dzs-recommendation {
    .topics {
      padding: 15px 0 0 6%;
    }

    .topics-text {
      margin-top: 15px;
      margin-left: -27px;
    }

    .button {
      margin: 0 auto;
      margin-top: 20px;
      display: table;
      font-size: 15px;
      background-color: #17a2b8 !important;
    }

    .button:hover {
      background-color: #0a7282 !important;
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
    }

    .topics-number {
      background: #17a2b8;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6em;
      margin-right: 5px;
      text-align: center;
      width: 1.6em;
    }

    .vl {
      border-left: 2px solid rgb(221, 221, 221);
    }

    @media (max-width: 768px) {
      .box2 {
        width: 90%;
      }
      .vl {
        display: none;
      }
      .topics {
        padding: 15px 0 0 0;
        text-align: center;
      }
      .topics-text {
        margin-top: 15px;
        text-align: center;
        margin-left: 0px;
      }
    }
  }
</style>
