<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4 slot="header" class="card-title">
            Destinatários Bloqueados <span v-if="username">(<span style="font-weight: bolder"> @{{username}}</span> )</span>
          </h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Usuário</label>
                <fg-input v-model="search.username">
                  <span slot="addonLeft">@</span>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button style="margin-top: 25px;"
                          @click="getFilters" type="info">
                  Buscar
                </p-button>
              </div>
            </div>
            <div class="row">
              <card class="col-sm-12">
                <div class="card-header">
                  <h3>Bloqueios</h3>
                </div>
                <div class="card-body">
                  <div class="row col-md-12">
                    <el-table class="table-striped" :data="blockedList" style="margin: auto; width: 100%">
                      <el-table-column :min-width="90" label="Ativo" align="center">
                        <template v-slot="props">
                          <input type="checkbox" v-model="props.row.enabled" @change="updateFilter(props.row)">
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="90" label="Instituição Financeira">
                        <template v-slot="props">
                          <span>{{getBank(props.row.ispb)}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="80" label="Agência">
                        <template v-slot="props">
                          <span>{{props.row.agencyCode}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="Número da Conta">
                        <template v-slot="props">
                          <span>{{props.row.accountNumber}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="90" label="Data de Criação">
                        <template v-slot="props">
                          <span>{{props.row.createdDate}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :width="240" fixed="right" class-name="td-actions" label="Deletar">
                        <template slot-scope="props">
                          <p-button type="danger" size="sm" icon title="Deletar"
                                    @click="deleteFilter(props.row.filterId)">
                            <i class="fas fa-trash"></i>
                          </p-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {failWs, postWs, getWs} from "src/ws.service"
import swal from 'sweetalert2'
export default {
  data() {
    return {
      search: {
        username: ''
      },
      username: '',
      blockedList: [],
      bankIspb: []
    }
  },
  mounted() {
    this.getBanksIspb()
  },
  methods: {
    resetDefaultValues(){
      this.search.username = ''
    },
    getBanksIspb() {
      getWs("/transaction/get-bacen-institutions", true, null,
        (response) => {
          this.bankIspb = response.data
        },(error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao buscar códigos ISPB',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    getFilters() {
      this.search.salePersonUsername = localStorage.getItem("username")
      getWs("/pix-bank-blacklist/list",
        true,
        {username: this.search.username},
        (response) => {
          this.blockedList = response.data
          this.username = this.search.username
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao buscar registros de bloqueios',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    updateFilter(filter){
      postWs("/pix-bank-blacklist/update",
        true, null,
        {
          filterId: filter.filterId,
          enabled: filter.enabled
        },
        (response) => {
          this.getFilters()
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao atualizar filtro!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    deleteFilter(filterId){
      postWs("/pix-bank-blacklist/delete",
        true, null,
        {
          filterId: filterId
        },
        (response) => {
          this.getFilters()
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao deletar filtro!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    getBank(ispb){
      return this.bankIspb.find(objeto => objeto.ispbCode === ispb).ispbCode + " - " + this.bankIspb.find(objeto => objeto.ispbCode === ispb).name;
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
