<template>
  <div class="dzs-flagged-users">
    <div class="row">
      <div class="col-md-12" style="margin: 0 auto">
        <card>
          <h4 slot="header" class="card-title">
            Sinalizar Usuário
          </h4>
          <div class="row">
            <div class="col-md-4">
              <fg-input type="text"
                        label="Usuário"
                        v-model="insert.username">
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="flagUser" style="margin-top: 24px">
                INSERIR
              </p-button>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="margin: 0 auto">
        <card>
          <h4 slot="header" class="card-title">
            Usuários Sinalizados
          </h4>
          <div class="row">
            <div class="col-md-4">
              <fg-input type="text"
                        label="Usuário"
                        v-model="search.username">
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [50, 100, 250, 500]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="searchFlaggedUsers" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column label="Usuário">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.username }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="QTD. MEDs">
                  <template slot-scope="item">
                    <span>{{ item.row.qtyMeds }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Inserido em">
                  <template slot-scope="item">
                    <span>{{ item.row.createdDate ? formatDate(item.row.createdDate, 'DD/MM/YYYY') : '-' }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" fixed="right">
                  <template slot-scope="item">
                    <p-button type="warning" size="sm" icon title="Remover Sinalização"
                              @click="removeUserFlag(item.row.username)">
                      <i class="fas fa-x"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.searchFlaggedUsers">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {callWs} from "src/ws.service"
import {formatDate} from "@/util/date.utils";
import swal from "sweetalert2";
import PPagination from "@/components/UIComponents/Pagination.vue";

export default {
  components: {
    PPagination,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      insert: {
        username: ''
      },
      search: {
        username: ''
      },
      tableData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  mounted() {
    this.searchFlaggedUsers()
  },
  methods: {
    formatDate,
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    searchFlaggedUsers() {
      callWs('/bo/compliance/flagged-users', 'POST', null, true, null, {
          username: this.search.username,
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
        },
        this.successSearchFlaggedUsers,
        this.failSearchFlaggedUsers)
    },
    successSearchFlaggedUsers(response) {
      this.tableData = response.data.list
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    failSearchFlaggedUsers() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar por usuários sinalizados.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    flagUser() {
      callWs('/bo/compliance/flag-user', 'POST', null, true, null,
        {
          username: this.insert.username
        },
        this.successUpdateUserFlag,
        this.failUpdateUserFlag
      )
    },
    removeUserFlag(username) {
      callWs('/bo/compliance/remove-user-flag', 'POST', null, true, null,
        {
          username: username
        },
        this.successUpdateUserFlag,
        this.failUpdateUserFlag
      )
    },
    successUpdateUserFlag() {
      this.searchFlaggedUsers()
    },
    failUpdateUserFlag() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao atualizar sinalização deste usuário! Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
  }
}
</script>
<style lang="scss">
.dzs-flagged-users {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }
}
</style>
