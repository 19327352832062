<template>
  <div class="dzs-financial-report">
    <div class="row">
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {saleValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {netValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {paidValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {cardSaleValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Valor Líquido: R$ {{toMoney(cardSaleValue.footerText)}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {pixSaleValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Valor Líquido: R$ {{toMoney(pixSaleValue.footerText)}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {boletoSaleValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Valor Líquido: R$ {{toMoney(boletoSaleValue.footerText)}}
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {financialReserveValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {approvedPaymentValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {waitingApprovalValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {canceledValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-3 col-md-12'" v-for="stats in {chargebackValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>

      <div class="col-md-12 card">
        <div class="row card">
          <div class="card-header">
            <h4 style="margin-top: 0px">Relatório de Pagamentos Link de Pagamento</h4>
          </div>
          <div class="card-body row" style="padding-left: 25px;">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-sm-2">
              <fg-input label="Exibir">
                <el-select
                  size="large"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [100,500,1000,5000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 25px"
                        @click="find">
                <i slot="label" class="fas fa-search-dollar"></i>
                Buscar
              </p-button>
            </div>
<!--            <div class="col-md-2" v-if="this.requestExcel.enabled">-->
<!--              <p-button type="success" style="margin-top: 25px"-->
<!--                        @click="generateExcel">-->
<!--                <i slot="label" class="fas fa-file-invoice"></i>-->
<!--                Excel-->
<!--              </p-button>-->
<!--            </div>-->
          </div>
        </div>

        <div class="row card">
          <div class="row col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor de Vendas: R$ {{toMoney(survey.saleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Líquido: R$ {{toMoney(survey.netValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Pago: R$ {{toMoney(survey.paidValue)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no Cartão: R$ {{toMoney(survey.cardSaleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no PIX: R$ {{toMoney(survey.pixSaleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no Boleto: R$ {{toMoney(survey.boletoSaleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Reserva Financeira: R$ {{toMoney(survey.financialReserveValue)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pag. Aprovados: R$ {{toMoney(survey.approvedPaymentValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Ag. Aprovação: R$ {{toMoney(survey.waitingApprovalValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pag. Cancelados: R$ {{toMoney(survey.canceledValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Chargeback: R$ {{toMoney(survey.chargebackValue)}}</p>
            </div>
          </div>
        </div>

        <div class="row card">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="50" label="ID Venda">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.saleId}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Tipo Pagamento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.type}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Data Pagamento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.dateTime}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Venda">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.saleValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="40" label="Taxa">
                <template slot-scope="props">
                    <span>
                      <span>{{toMoney(props.row.fee)}} %</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Líquido">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.netValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.status}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Pago">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.paidValue)}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row" style="padding: 10px 10px 10px 10px">
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat} from "../../../../util/core.utils";
  import {DatePicker} from 'element-ui'
  import {daysBetween, formatDate} from "../../../../util/date.utils";
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import download from 'downloadjs'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      StatsCard,
      ChartCard,
      Modal,
    },
    data() {
      return {
        saleValue: {
          type: 'success',
          icon: 'fa-solid fa-money-bill-trend-up',
          title: 'Valor de Vendas',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        netValue: {
          type: 'success',
          icon: 'fa fa-money',
          title: 'Valor Líquido',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paidValue: {
          type: 'success',
          icon: 'fa-solid fa-hand-holding-dollar',
          title: 'Valor Pago',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        cardSaleValue: {
          type: 'success',
          icon: 'fa-regular fa-credit-card',
          title: 'Vendas no Cartão',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        pixSaleValue: {
          type: 'success',
          icon: 'fa-brands fa-pix',
          title: 'Vendas no Pix',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        boletoSaleValue: {
          type: 'success',
          icon: 'fa-solid fa-file-circle-check',
          title: 'Vendas no Boleto',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        cardNetValue: {
          type: 'success',
          icon: 'fa-solid fa-rotate-left',
          title: 'Valor Líquido Cartão',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        pixNetValue: {
          type: 'success',
          icon: 'fa-solid fa-calendar-xmark',
          title: 'Valor Líquido Pix',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        boletoNetValue: {
          type: 'success',
          icon: 'fa-solid fa-hand-holding-dollar',
          title: 'Valor Líquido Boleto',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        financialReserveValue: {
          type: 'success',
          icon: 'fa-solid fa-sack-dollar',
          title: 'Reserva Financeira',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        approvedPaymentValue: {
          type: 'success',
          icon: 'fa-solid fa-check',
          title: 'Pag. Aprovados Cartão',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        waitingApprovalValue: {
          type: 'success',
          icon: 'fa-solid fa-rotate',
          title: 'Ag. Aprovação Cartão',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        canceledValue: {
          type: 'success',
          icon: 'fa-solid fa-ban',
          title: 'Pag. Cancelados',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        chargebackValue: {
          type: 'success',
          icon: 'fa-solid fa-rotate-left',
          title: 'Chargeback',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        startDate: new Date(),
        endDate: new Date(),
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        search: {
          statusTransaction: null,
          bannerCard: null,
          transactionType: null,
          terminalNumber: null,
          nsu: null,
        },
        survey: {
          saleValue: 0,
          netValue: 0,
          paidValue: 0,
          cardSaleValue: 0,
          pixSaleValue: 0,
          boletoSaleValue: 0,
          cardNetValue: 0,
          pixNetValue: 0,
          boletoNetValue: 0,
          financialReserveValue: 0,
          approvedPaymentValue: 0,
          waitingApprovalValue: 0,
          canceledValue: 0,
          chargebackValue: 0,
        },
        tableData: [],
        statusList: [null],
        status: null,
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        formRequestNotFound: false,
        requestExcel: {
          listTr: [],
          startDate: '',
          endDate: '',
          enabled: false,
        },
        username: '',
      }
    },
    created() {
      this.username = localStorage.getItem("usernameDisplay")
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pagination.currentPage = 1
          this.loadData()
        }
      },
      loadData() {
        postWs("/payment-link/last-payments",
          true, null,
          {
            status: this.status,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        this.formRequestNotFound = true
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.requestExcel.listTr = response.data.list
        if(this.tableData != null && response.data.list.length > 0) {
          this.requestExcel.enabled = true
        } else {
          this.requestExcel.enabled = false
        }
        this.requestExcel.startDate = formatDate(this.startDate, "DD_MM_YYYY")
        this.requestExcel.endDate = formatDate(this.endDate, "DD_MM_YYYY")
        this.saleValue.value = response.data.totalPaymentsSummary.saleValue
        this.netValue.value = response.data.totalPaymentsSummary.netValue
        this.paidValue.value = response.data.totalPaymentsSummary.paidValue
        this.cardSaleValue.value = response.data.totalPaymentsSummary.cardSaleValue
        this.pixSaleValue.value = response.data.totalPaymentsSummary.pixSaleValue
        this.boletoSaleValue.value = response.data.totalPaymentsSummary.boletoSaleValue
        this.cardSaleValue.footerText = response.data.totalPaymentsSummary.cardNetValue
        this.pixSaleValue.footerText = response.data.totalPaymentsSummary.pixNetValue
        this.boletoSaleValue.footerText = response.data.totalPaymentsSummary.boletoNetValue
        this.financialReserveValue.value = response.data.totalPaymentsSummary.financialReserveValue
        this.approvedPaymentValue.value = response.data.totalPaymentsSummary.approvedPaymentValue
        this.waitingApprovalValue.value = response.data.totalPaymentsSummary.waitingApprovalValue
        this.canceledValue.value = response.data.totalPaymentsSummary.canceledValue
        this.chargebackValue.value = response.data.totalPaymentsSummary.chargebackValue

        this.survey.saleValue = response.data.surveyPaymentsSummary.saleValue
        this.survey.netValue = response.data.surveyPaymentsSummary.netValue
        this.survey.paidValue = response.data.surveyPaymentsSummary.paidValue
        this.survey.cardSaleValue = response.data.surveyPaymentsSummary.cardSaleValue
        this.survey.pixSaleValue = response.data.surveyPaymentsSummary.pixSaleValue
        this.survey.boletoSaleValue = response.data.surveyPaymentsSummary.boletoSaleValue
        this.survey.cardNetValue = response.data.surveyPaymentsSummary.cardNetValue
        this.survey.pixNetValue = response.data.surveyPaymentsSummary.pixNetValue
        this.survey.boletoNetValue = response.data.surveyPaymentsSummary.boletoNetValue
        this.survey.financialReserveValue = response.data.surveyPaymentsSummary.financialReserveValue
        this.survey.approvedPaymentValue = response.data.surveyPaymentsSummary.approvedPaymentValue
        this.survey.waitingApprovalValue = response.data.surveyPaymentsSummary.waitingApprovalValue
        this.survey.canceledValue = response.data.surveyPaymentsSummary.canceledValue
        this.survey.chargebackValue = response.data.surveyPaymentsSummary.chargebackValue

        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      statusTranslate(status){
        let retorno = 'Todos'
        switch (status) {
          case 'WAITING':
            retorno = 'Em Aberto';
            break;
          case 'CANCELED':
            retorno = 'Cancelado';
            break;
          case 'PAID_PIX':
            retorno = 'Pago PIX';
            break;
          case 'PAID_BOLETO':
            retorno = 'Pago Boleto';
            break;
          case 'PAID_CARD':
            retorno = 'Pago Cartão';
            break;
          case 'CHARGEDBACK':
            retorno = 'Chargeback';
            break;
          case 'EXPIRED':
            retorno = 'Vencido';
            break;
        }
        return retorno
      },
      statusToColor(status){
        let retorno = ''
        switch (status) {
          case 'WAITING':
            retorno = '#ffef00';
            break;
          case 'CANCELED':
            retorno = '#ff3c00';
            break;
          case 'PAID_PIX':
            retorno = '#52d019';
            break;
          case 'PAID_BOLETO':
            retorno = '#52d019';
            break;
          case 'PAID_CARD':
            retorno = '#52d019';
            break;
          case 'CHARGEDBACK':
            retorno = '#f40606';
            break;
          case 'EXPIRED':
            retorno = '#ff3c00';
            break;
        }
        return retorno
      },
      generateExcel() {
        callWs("/payment-link/sales-report-excel",
          "POST", null, true, null, this.requestExcel.listTr,
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "vendas_link_de_pagamento_"+this.username+"_"+this.requestExcel.startDate+"_a_"+this.requestExcel.endDate+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o relatório em Excel, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
    }
  }
</script>
<style lang="scss">
  .dzs-financial-report {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn.btn-primary{
      margin-right: 10px;
    }

    .el-input__inner {
      background-color: white !important;
    }
  }
</style>
