<template>
  <div class="dzs-card row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-4">
          <card style="height: 100%">
            <div>
              <div class="row">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <fg-input type="text"
                                v-model="formCreate.name"
                                maxlength="50"
                                label="Nome do Centro de Custo"
                                :error="getError('request.nome')"
                                name="nome"
                                v-validate="{required: true, max:50}"
                                data-vv-scope="request"
                                key="name">
                      </fg-input>
                    </div>
                    <div class="col-lg-6">
                      <p-button type="primary" class="mt-4" @click="create()">
                        Criar Novo Centro
                      </p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <card>
              <h3 slot="header" class="card-title text-center font-weight-bold">
                Centro de Custo
              </h3>
              <div class="row">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 align-items-center">
                      <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                        <div class="col-sm-auto">
                          <i class="fa-solid fa-check text-primary"></i>
                        </div>
                        <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                          Gerencie suas categorias de gastos criando centros de custos e associando aos seus cartões
                        </div>
                      </div>

                      <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                        <div class="col-sm-auto">
                          <i class="fa-solid fa-check text-primary"></i>
                        </div>
                        <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                          Veja o extrato e os cartões associados à cada centro custo.
                        </div>
                      </div>

                      <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                        <div class="col-sm-auto">
                          <i class="fa-solid fa-check text-primary"></i>
                        </div>
                        <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                          Veja os gastos e recargas mensais de cada centro de custo
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </card>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-sm-12">
              <card style="height: 100%">
                <div class="row">
                  <div class="col-sm-12">
                    <h5>
                      Centros de Custo
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <label class="control-label">Nome do Centro de Custo</label>
                    <fg-input v-model="searchFilter.name" style="width: 100%"/>
                  </div>
                  <div class="col-sm-3">
                    <label class="control-label">Status</label>
                    <br>
                    <el-select class="select-info"
                               placeholder="Todos"
                               v-model="searchFilter.enabled">
                      <el-option v-for="option in searchStatus"
                                 class="select-info"
                                 :value="option.id"
                                 :label="option.name"
                                 :key="option.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-3">
                    <p-button @click.prevent="loadTableData()" style="margin-top: 24px" type="info">
                      <i class="fas fa-search-dollar"></i> BUSCAR
                    </p-button>
                  </div>
                </div>
                <div>
                  <hr>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <el-table class="table-striped"
                              :data="tableData"
                              style="width: 100%;"
                              ref="costCenterTable"
                              row-key="costCenterId">
                      <el-table-column :min-width="100" label="Nome do Centro de Custo">
                        <template slot-scope="props">
                          <span style="margin-left: 10px">{{props.row.name}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="Status">
                        <template slot-scope="props">
                          <span><i :class="'fa-solid fa-circle ' + statusToColor(props.row.enabled)"></i> {{statusToText(props.row.enabled)}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="40" label="Ações">
                        <template slot-scope="props">
                          <el-tooltip content="Ativar Centro de Custo"
                                      v-if="!props.row.enabled"
                                      :open-delay="300"
                                      placement="top">
                            <span>
                              <p-button @click="updateStatus(props.row.id, true)" class="btn btn-success p-2">
                                <i class="fa-regular fa-circle-check" style="font-size: large"></i>
                              </p-button>
                            </span>
                          </el-tooltip>
                          <el-tooltip content="Desativar Centro de Custo"
                                      v-if="props.row.enabled"
                                      :open-delay="300"
                                      placement="top">
                            <span>
                              <p-button @click="updateStatus(props.row.id, false)" class="btn btn-danger p-2">
                                <i class="fa-regular fa-circle-xmark" style="font-size: large"></i>
                              </p-button>
                            </span>
                          </el-tooltip>


                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="col-sm-6 pagination-success">
                      <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                        {{pagination.totalNumber}} REGISTROS.</p>
                    </div>
                    <div class="col-sm-6">
                      <p-pagination class="pull-right"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total="pagination.totalNumber"
                                    :click="loadData">
                      </p-pagination>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import swal from 'sweetalert2'
  import {postWs, getWs, failWs} from 'src/ws.service'
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from 'src/util/core.utils'
  import {Progress} from 'src/components/UIComponents'
  import {Option, Select, Table, TableColumn, Tooltip} from "element-ui";
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import PButton from "../../../UIComponents/Button";

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {
      PButton,
      [Progress.name]: Progress,
      PPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip
    },
    data() {
      return {
        accountApproved: false,
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        tableData: [],
        account: [],
        usernameDisplay: '',
        username: '',
        formCreate: {
          name: null,
          enabled: null
        },
        searchFilter: {
          name: null,
          enabled: true,
          pageNumber: 0,
          pageSize: 0
        },
        searchStatus: [
          {id: null, name: 'Todos'},
          {id: true, name: 'Ativo'},
          {id: false, name: 'Desativado'}
        ]
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.account.name = localStorage.getItem("name")
      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      postWs("/acc/get-account", true, null, null,
         response => {
           this.loadAccount(response)
           this.loadData()
         }, function () {
      })
    },
    methods: {
      isNullOrEmpty,
      toMoney,
      statusToText(status) {
        switch (status) {
          case true:
            return 'Ativo'
          case false:
            return 'Desativado'
          default:
            return 'Não Definido'
        }
      },
      statusToColor(status) {
        switch (status) {
          case true:
            return 'text-success'
          case false:
            return 'text-danger'
          default:
            return 'text-warning'
        }
      },
      resetEditFields(){
        this.formCreate = {
          name: null
        }
        this.$validator.reset('request')
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      create(){
        this.$validator.validateAll('request').then(isValid => {
          if (!isValid) {
            return
          }
          postWs("/costcenter/new", true, null, {name: this.formCreate.name, enabled: true},
            response => {
              this.loadData()
            },
            error => {
              swal({
                title: 'Aviso!',
                text: error.response.data.message,
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'error'
              })
            })
        })
      },
      loadAccount(response) {
        this.account = response.data
        this.username = localStorage.getItem("usernameDisplay")
        localStorage.setItem('name', this.account.name)
        if (this.account.accountDocumentStatus !== 'APPROVED') {
          this.accountApproved = false
        } else {
          this.accountApproved = true
        }
      },
      loadData() {
        this.resetEditFields()
        this.loadTableData()
      },
      updateStatus(id,active){

        if(!active){
          swal({
            title: 'Atenção!',
            text: 'Deseja realmente desativar este Centro de Custo?',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          }).then(result => {
            if(result){
              this.doUpdate(id, active)
            }
          })
        }
        this.doUpdate(id, active)
      },
      doUpdate(id, active){
        postWs("/costcenter/update", true, null, {id: id, enabled: active},
          response => {
            this.tableData.some((value, index) => {
              if(value.id === id){
                value.enabled = active
                return true
              }
            })
          },
          error => {
            swal({
              title: 'Aviso!',
              text: 'Ops. Tivemos um problema com sua solicitação. Tente novamente mais tarde!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'error'
            })
          })
      },
      loadTableData() {
        this.searchFilter.pageNumber = this.pagination.currentPage - 1,
        this.searchFilter.pageSize = this.pagination.perPage
        postWs("/costcenter/list", true, null, this.searchFilter,
          response => {
            this.tableData = response.data.list
            this.pagination.totalNumber = response.data.totalNumber
            this.pagination.pageMaxNumber = response.data.pageMaxNumber
            if (this.pagination.totalNumber > 0) {
              this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
              this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
            } else {
              this.pagination.fromNumber = 0
              this.pagination.toNumber = 0
            }
          },
          function () {
          })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-card {
    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }
    ::placeholder {
      color: #000000;
    }
  }
  .search {
    background-color: #17a2b8 !important;
  }

  .search:hover {
    background-color: #0a7282 !important;
  }
</style>
