<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right" style="margin-right: 70px">
        &copy; 2020, <b style="color: #000000 !important;">SuitPay</b>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
