<template>
  <form @submit.prevent="onFormSubmit" class="card edit-card">
    <div class="card-header">
      <div class="row">
        <div class="col-12">
          <h4>Envio de Placa - @{{ plateRequest.username }}</h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <form class="row">
        <div class="col-3">
          <fg-input label="CEP" :value="plateRequest.deliveryAddress.zipCode" v-mask="['#####-###']" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-6">
          <fg-input label="Cidade" :value="plateRequest.deliveryAddress.city" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-3">
          <fg-input label="Estado" :value="plateRequest.deliveryAddress.state" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-5">
          <fg-input label="Bairro" :value="plateRequest.deliveryAddress.neighborhood" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-5">
          <fg-input label="Rua" :value="plateRequest.deliveryAddress.city" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-2">
          <fg-input label="Número" :value="plateRequest.deliveryAddress.number" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-6">
          <fg-input label="Complemento" :value="plateRequest.deliveryAddress.complement" :disabled="!canEditAddress"></fg-input>
        </div>
        <div class="col-6">
          <fg-input label="Whatsapp" :value="plateRequest.phoneNumber" :disabled="!canEditAddress"></fg-input>
        </div>
      </form>
      <div class="row">
        <div class="col-12">
          <fg-input input-classes="uppercase"
                    label="Código de rastreio"
                    name="trackingCode"
                    data-vv-as="Código de Rastreio"
                    v-validate="{required: true}"
                    v-model="trackingCode"
                    :error="getError('trackingCode')"
          ></fg-input>
        </div>
        <div class="col-12">
          <fg-input label="Transportadora">
            <el-select input-classes="uppercase"
                       label="Transportadora"
                       name="shipmentServiceId"
                       data-vv-as="Transportadora"
                       v-validate="{required: true}"
                       v-model="shipmentServiceId"
                       :error="getError('shipmentServiceId')"
            >
              <el-option v-for="item in shipmentOpts" :label="item.name" :key="item.id" :value="item.id"></el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p-button type="success" class="float-right" native-type="submit">Inserir</p-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import swal from "sweetalert2";
import {postWs, callWs} from "@/ws.service";

export default {
  name: 'ship-plate-form',
  props: ['plateRequest', 'canEditAddress'],
  emits: ['save'],
  data() {
    return {
      trackingCode: '',
      shipmentServiceId: '',
      shipmentOpts: []
    }
  },
  created() {
    this.getCarrierOpts()
  },
  methods: {
    getCarrierOpts() {
      callWs('/bo/suitjourney/get-shipment-services', 'GET', null, true, null, null,
      this.successGetCarrierOpts, this.failGetCarrierOpts)
    },
    successGetCarrierOpts(response) {
      this.shipmentOpts = response.data
    },
    failGetCarrierOpts() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar transportadoras. Atualize a página e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onFormSubmit() {
      this.$validator.validateAll().then(isValid => {
        if(isValid) {
          this.submit()
        }
      })
    },
    submit() {
      postWs('/bo/suitjourney/ship-plate', true, null,
        {
          plateRequestId: this.plateRequest.id,
          trackingCode: this.trackingCode.toUpperCase(),
          shipmentServiceId: this.shipmentServiceId
        },
        this.successSubmit,
        this.failSubmit)
    },
    successSubmit() {
      swal({
        title: 'Sucesso!',
        text: 'Envio da placa registrado com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.$emit('save')
    },
    failSubmit() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao registrar envio da placa. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    }
  }
}
</script>
<style lang="scss">
.edit-card {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  .el-select .el-input input {
    background-color: #FFFFFF !important;
  }
}
</style>
